import React, { useRef, MouseEvent } from 'react'
import { ContentBox, ModalBackground, ModalChildren, ModalCloseButton, ModalHeader } from './style'

export function ModalDialog(props: {
  showModal: Boolean
  setShowModal: (setTo: Boolean) => void
  hasCancelButton: Boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
  wide?: Boolean
}) {
  const modalRef = useRef()

  function onClickOutside(event: MouseEvent) {
    if (modalRef.current === event.target) {
      closeModal(event)
    }
  }

  function closeModal(event: MouseEvent) {
    props.setShowModal(false)
  }

  return (
    <>
      {props.showModal ? (
        <ModalBackground ref={modalRef} onMouseDown={onClickOutside}>
          <ContentBox wide={props.wide}>
            <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton>
            <ModalHeader>{props.header}</ModalHeader>
            <hr />
            <ModalChildren>{props.children}</ModalChildren>
            <footer>
              <>{props.hasCancelButton ? <button onClick={closeModal}>Abbrechen</button> : null}</>
              <>{props.footer}</>
            </footer>
          </ContentBox>
        </ModalBackground>
      ) : null}
    </>
  )
}
