export const Learning = () => {
  return (
    <>
      <iframe
        title="Cego-Online Kurs"
        src="/eLearning/chapter0.html"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      ></iframe>
    </>
  )
}
