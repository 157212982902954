import { useState, useCallback } from 'react'
import {
  CegoClient,
  ChatData,
  GameMode,
  MessageStatus,
  PlayerData,
  PlayerDataMap,
  TableData,
  WinData,
} from '@hs-furtwangen/socket-lib'
import '../../CSS/TableLobby.css'
import BackgroundImage from '../../Assets/lobby_background.jpg'
import { PointsTable } from '../PointsTable'
import { KickButton, TableAvatar } from '../TableList/style'
import { CornerWindow } from '../CornerWindow/CornerWindow'
import { StyledStaticHeader } from '../StaticPage/style'
import {
  StyledPointsCalculation,
  StyledTableHeader,
  StyledTableLobby,
  StyledTableName,
  StyledTablePlayer,
  StyledTablePlayers,
  StyledWinnerAnnouncement,
} from './style'
import { GameModeHelper } from '../../Util/GameModeHelper'
import { getPointsCalculation, getWinnerAnnouncement } from '../../lib/MatchPoints'
import { ModalDialog } from '../Modal/ModalDialog'

export default function TableLobby(props: {
  cegoClient: CegoClient
  ownPlayerId: string
  lobbyPlayers: PlayerDataMap
  currentTable: TableData
  readyPlayerIds: string[]
  isHosting: boolean
  winData: WinData[]
  logEntries: string[]
  chatMessages: ChatData[]
  playerId: string
  tableId: string
  tablePlayerIds: string[]
  gameMode: GameMode
  currentTab: number
  lastChallengerId: string
  tmpGameMode: GameMode
  showAlert: Boolean
  alertText: string
  messageCache: string
  currentWinData: [string, number][]
  firstPlayerId: string
  setMessageCache: React.Dispatch<React.SetStateAction<string>>
  setShowAlert: React.Dispatch<React.SetStateAction<Boolean>>
  leaveTable: () => void
  setCurrentTab: (number) => void
}) {
  const cegoClient = props.cegoClient
  const currentTable = props.currentTable
  const playerIds = currentTable.playerIds
  const readyPlayerIds = props.readyPlayerIds

  const [playerReady, setPlayerReady] = useState(props.isHosting)

  const setReady = useCallback(
    (ready) => {
      cegoClient.playerSetReady(props.ownPlayerId, currentTable.id, ready, (response) => {
        if (response.status === MessageStatus.OK) {
          setPlayerReady(ready)
        }
      })
    },
    [cegoClient, currentTable.id, props.ownPlayerId],
  )

  function startGame() {
    cegoClient.startGame(currentTable.id, () => {})
  }

  function leaveTable() {
    props.leaveTable()
  }

  const isReady = (playerId): Boolean => {
    return (
      readyPlayerIds.includes(playerId) ||
      (playerId === props.ownPlayerId && props.isHosting) ||
      props.lobbyPlayers[playerId]?.isAi
    )
  }

  const kickPlayer = (id: string) => {
    props.cegoClient.kickPlayer(id, props.tableId)
  }

  const playerItems = playerIds
    .map((playerId): PlayerData => props.lobbyPlayers[playerId])
    .sort((dataA, dataB) => currentTable.seatMap[dataA.id] - currentTable.seatMap[dataB.id])
    .filter((playerData) => playerData != null)
    .map((playerData) => (
      <StyledTablePlayer key={playerData.id}>
        <TableAvatar className="icon-img button-icon-leave" src={playerData.iconPath} alt="" />
        {playerData.id === props.ownPlayerId ? <span>{playerData.name}</span> : playerData.name}
        {playerData.isAi === false && playerData.id !== props.ownPlayerId && props.isHosting && (
          <KickButton
            className="icon-img button-icon-leave"
            style={{ marginLeft: '10px' }}
            title="Spieler rauswerfen"
            onClick={() => kickPlayer(playerData.id)}
          ></KickButton>
        )}

        {isReady(playerData.id) ? <>&nbsp;&#10003;</> : <>&nbsp;&#10060;</>}
      </StyledTablePlayer>
    ))

  return (
    <StyledTableLobby background={BackgroundImage}>
      <StyledStaticHeader
        style={{ backgroundColor: 'rgb(84 72 56 / 95%)', height: '5%', display: 'flex', alignItems: 'center' }}
      >
        <StyledTableHeader>
          <h1>CEGO</h1>
        </StyledTableHeader>
      </StyledStaticHeader>
      <div className="table-lobby-content">
        <StyledTableName>{currentTable.name}</StyledTableName>
        <StyledTablePlayers>{playerItems}</StyledTablePlayers>
        {props.winData?.length > 0 && (
          <StyledWinnerAnnouncement>
            {getWinnerAnnouncement(
              props.winData[props.winData.length - 1],
              GameModeHelper.gameModeToString(props.gameMode),
              props.lobbyPlayers,
              props.tablePlayerIds,
            )}
          </StyledWinnerAnnouncement>
        )}
        {props.winData?.filter((data) => data.matchNumber !== -1).length > 0 && (
          <StyledPointsCalculation>
            {getPointsCalculation(
              props.winData[props.winData.length - 1],
              props.gameMode,
              props.lastChallengerId,
              props.tablePlayerIds,
            )}
          </StyledPointsCalculation>
        )}
        <PointsTable
          winData={props.winData}
          lobbyPlayers={props.lobbyPlayers}
          tablePlayerIds={props.tablePlayerIds}
          ownPlayerId={props.ownPlayerId}
        />

        <div className="table-lobby-buttons">
          <button className="button-wide" onClick={leaveTable}>
            Tisch verlassen
          </button>
          <div className="modal-content">
            {props.isHosting && (
              <button
                className="button-wide"
                onClick={startGame}
                disabled={!playerIds.every((playerId) => isReady(playerId))}
              >
                Spiel starten
              </button>
            )}
            {!props.isHosting && (
              <button className="button-wide" onClick={() => setReady(!playerReady)}>
                {!playerReady ? 'Bereit' : 'Nicht bereit'}
              </button>
            )}
          </div>
        </div>
      </div>
      <CornerWindow
        cegoClient={props.cegoClient}
        logEntries={props.logEntries}
        playerId={props.ownPlayerId}
        lobbyPlayers={props.lobbyPlayers}
        tablePlayerIds={props.currentTable.playerIds}
        tableId={props.currentTable.id}
        chatMessages={props.chatMessages}
        gameMode={props.gameMode}
        currentTab={props.currentTab}
        tmpGameMode={props.tmpGameMode}
        setCurrentTab={props.setCurrentTab}
        messageCache={props.messageCache}
        setMessageCache={props.setMessageCache}
        currentWinData={props.currentWinData}
        firstPlayerId={props.firstPlayerId}
      ></CornerWindow>
      <ModalDialog
        hasCancelButton={false}
        showModal={props.showAlert}
        setShowModal={props.setShowAlert}
        header={<>Benachrichtigung</>}
      >
        <p>{props.alertText}</p>
      </ModalDialog>
    </StyledTableLobby>
  )
}
