import { useRef, useState, useEffect, useMemo } from 'react'
import { Card as CardData } from '@hs-furtwangen/socket-lib'
import CSS from 'csstype'
import '../CSS/Cards.css'
import '../CSS/Card.css'

enum CARDANIMATION {
  idle = 'card-idle',
  hover = 'card-hover',
  hoverCancel = 'card-hover-cancel',
  drag = 'card-drag',
  dragPlayable = 'card-drag-playable',
  dragWobble = 'card-wobble',
  dragCancel = 'card-drag-cancel',
  push = 'card-push',
  play = 'card-play',
}

enum CARD_CSS_VARIABLES {
  cursor = '--card-cursor',
  index = '--card-index',
  handLength = '--card-hand-length',
  initialYOffsetFactor = '--card-initial-y-offset-factor',
  dragLeft = '--card-drag-x',
  dragTop = '--card-drag-y',
}

const hoverAnimationDuration = 100
const animations: { [key: string]: { to: Keyframe[]; options: KeyframeAnimationOptions } } = {
  [CARDANIMATION.hover]: {
    to: [{ transform: 'var(--card-hover-transform)' }],
    options: {
      duration: hoverAnimationDuration,
      easing: 'ease-out',
    },
  },

  [CARDANIMATION.hoverCancel]: {
    to: [{ transform: 'var(--card-initial-transform)' }],
    options: {
      duration: hoverAnimationDuration,
      easing: 'ease-out',
    },
  },

  [CARDANIMATION.drag]: {
    to: [
      {
        left: 'var(--card-drag-x)',
        top: 'var(--card-drag-y)',
        transform: 'rotateY(0)',
      },
    ],
    options: {
      duration: hoverAnimationDuration,
      easing: 'ease-out',
    },
  },

  [CARDANIMATION.dragWobble]: {
    to: [
      {
        transform: 'rotateX(-1deg) rotateY(1deg) rotateZ(-1deg)',
      },
      {
        transform: 'rotateX(1deg) rotateY(-1deg) rotateZ(1deg)',
      },
    ],
    options: {
      iterations: Infinity,
      direction: 'alternate',
      duration: 1500,
      composite: 'accumulate',
    },
  },

  [CARDANIMATION.dragCancel]: {
    to: [{ transform: 'var(--card-initial-transform)' }],
    options: {
      duration: 300,
      easing: 'ease-out',
    },
  },

  [CARDANIMATION.push]: {
    to: [
      {
        left: 'var(--card-stack-player-x)',
        top: 'var(--card-stack-player-y)',
        boxShadow: 'none',
        transform: 'var(--card-stack-player-transform)',
      },
    ],
    options: {
      duration: 400,
      easing: 'ease-out',
    },
  },

  [CARDANIMATION.play]: {
    to: [
      {
        left: 'var(--card-table-x)',
        top: 'var(--card-table-y)',
        boxShadow: 'none',
        transform: 'var(--card-table-0-transform)',
      },
    ],
    options: {
      duration: 400,
      easing: 'ease-out',
    },
  },
}

export function CardPlayer(props: {
  playerId: string
  card: CardData
  index: number
  handLength: number
  isValid: boolean
  hasActiveCard: boolean
  setHasActiveCard: React.Dispatch<React.SetStateAction<boolean>>

  isChangeMode: boolean
  isGift: boolean
  isSelected: boolean
  changeModeRow: number
  clickToPlay: boolean
}) {
  const [animation, setAnimation] = useState<CARDANIMATION>(CARDANIMATION.idle)
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 })
  const [active, setActive] = useState(false)

  const self = useRef<HTMLDivElement>(null)
  const placeholder = useRef<HTMLDivElement>(null)
  const currentAnimation = useRef<Animation>(null)

  const style = useMemo<CSS.Properties>(
    () =>
      ({
        [CARD_CSS_VARIABLES.index]: props.index,
        [CARD_CSS_VARIABLES.handLength]: props.handLength,
        [CARD_CSS_VARIABLES.initialYOffsetFactor]: Math.pow(Math.abs(props.handLength / 2 - props.index), 2) * 0.01,
        [CARD_CSS_VARIABLES.dragLeft]: `${dragPosition.x}px`,
        [CARD_CSS_VARIABLES.dragTop]: `${dragPosition.y}px`,
        [CARD_CSS_VARIABLES.cursor]: props.isChangeMode
          ? 'pointer'
          : active
          ? props.clickToPlay
            ? 'pointer'
            : 'grabbing'
          : props.clickToPlay
          ? 'pointer'
          : 'grab',
      } as CSS.Properties),
    [props.isChangeMode, props.clickToPlay, props.index, props.handLength, dragPosition, active],
  )

  useEffect(() => {
    document.addEventListener('pushcard', onPushCard)
    document.addEventListener('playcard', onPlayCard)
    if (active && !props.isChangeMode) {
      document.onpointermove = onPointerMove
      document.onpointerup = onPointerUp
      document.onvisibilitychange = onVisibilityChange
    }

    return () => {
      document.removeEventListener('pushcard', onPushCard)
      document.removeEventListener('playcard', onPlayCard)
      if (active && !props.isChangeMode) {
        document.onpointermove = undefined
        document.onpointerup = undefined
        document.onvisibilitychange = undefined
      }
    }
  })

  function onPointerDown(event: React.PointerEvent) {
    if (!isInteractable(event.isPrimary)) return
    event.preventDefault()
    if (props.isChangeMode) {
      if (props.isValid || props.isSelected) {
        document.dispatchEvent(
          new CustomEvent<CardData>('selectcardtochange', {
            detail: props.card,
          }),
        )
      }
    } else {
      if (props.isValid && props.clickToPlay) {
        document.dispatchEvent(
          new CustomEvent<CardData>('cardselected', {
            detail: props.card,
          }),
        )
        return
      } else {
        setActive(true)
        props.setHasActiveCard(true)
      }
    }
  }

  function onPointerMove(event: PointerEvent) {
    if (!event.isPrimary) return
    event.preventDefault()

    setDragPosition({
      x: event.clientX - placeholder.current.clientWidth / 2,
      y: event.clientY - placeholder.current.clientHeight / 2,
    })

    let isOverPlaceholder = document.elementsFromPoint(event.clientX, event.clientY).includes(placeholder.current)
    let isDragging = animation === CARDANIMATION.drag || animation === CARDANIMATION.dragPlayable
    let isTouch = event.pointerType === 'touch'
    let isValid = props.isValid

    // reattach card to hand in mobile
    if (isDragging && isTouch && isOverPlaceholder && animation !== CARDANIMATION.hover) {
      animate(CARDANIMATION.hover)
      setAnimation(CARDANIMATION.hover)
      return
    }

    // change between playable and not playable dragging
    if (isDragging) {
      if (isPlayable(event.clientX, event.clientY) && animation === CARDANIMATION.drag) {
        setAnimation(CARDANIMATION.dragPlayable)
      }
      if (!isPlayable(event.clientX, event.clientY) && animation === CARDANIMATION.dragPlayable) {
        setAnimation(CARDANIMATION.drag)
      }
      return
    }

    // start dragging
    if (!isOverPlaceholder && isValid && animation !== CARDANIMATION.drag && !props.clickToPlay) {
      animate(CARDANIMATION.drag).onfinish = () => animate(CARDANIMATION.dragWobble)
      setAnimation(CARDANIMATION.drag)
      return
    }

    // move invalid card back when trying to play
    if (!isOverPlaceholder && !isValid) {
      //TODO: play "cant play sound"
      animate(CARDANIMATION.hoverCancel)
      setAnimation(CARDANIMATION.idle)
      setActive(false)
      props.setHasActiveCard(false)
      return
    }
  }

  function onPointerUp(event: PointerEvent) {
    if (!event.isPrimary) return
    event.preventDefault()

    setActive(false)
    props.setHasActiveCard(false)

    if (isPlayable(event.clientX, event.clientY)) {
      document.dispatchEvent(
        new CustomEvent<CardData>('cardselected', {
          detail: props.card,
        }),
      )
      return
    }

    if (animation === CARDANIMATION.drag) {
      animate(CARDANIMATION.dragCancel)
      setAnimation(CARDANIMATION.idle)
    }
  }

  function onPointerEnter(event: React.PointerEvent) {
    if (!isInteractable(event.isPrimary) || props.isChangeMode) return
    animate(CARDANIMATION.hover)
    setAnimation(CARDANIMATION.hover)
  }

  function onPointerLeave(event: React.PointerEvent) {
    if (!isInteractable(event.isPrimary) || props.isChangeMode || animation === CARDANIMATION.idle) return
    animate(CARDANIMATION.hoverCancel)
    setAnimation(CARDANIMATION.idle)
  }

  function onPushCard(event: CustomEvent<CardData>) {
    if (event.detail !== props.card) return
    animate(CARDANIMATION.push).onfinish = () => {
      document.dispatchEvent(new CustomEvent<CardData>('cardpushed', { detail: props.card }))
    }
    setAnimation(CARDANIMATION.push)
  }

  function onPlayCard(event: CustomEvent<CardData>) {
    if (event.detail !== props.card) return

    animate(CARDANIMATION.play).onfinish = () =>
      document.dispatchEvent(
        new CustomEvent<{ card: CardData; playerId: string }>('cardplayed', {
          detail: { card: props.card, playerId: props.playerId },
        }),
      )
    setAnimation(CARDANIMATION.play)
  }

  function onVisibilityChange() {
    if (!document.hidden) return
    animate(CARDANIMATION.dragCancel)
    setAnimation(CARDANIMATION.idle)
    setActive(false)
    props.setHasActiveCard(false)
  }

  function isPlayable(pointerClientX: number, pointerClientY: number) {
    return (
      pointerClientY < placeholder.current.getBoundingClientRect().y &&
      !document
        .elementsFromPoint(pointerClientX, pointerClientY)
        .some((element) => element.className.includes('placeholder'))
    )
  }

  function isInteractable(pointerIsPrimary: boolean) {
    return !active && animation !== CARDANIMATION.play && pointerIsPrimary && !props.hasActiveCard
  }

  function animate(cardanimation: CARDANIMATION) {
    currentAnimation.current?.pause()
    let computedStyle = getComputedStyle(self.current)
    let from: Keyframe = {
      left: computedStyle.left,
      top: computedStyle.top,
      zIndex: computedStyle.zIndex,
      transform: computedStyle.transform,
    }
    if (cardanimation === CARDANIMATION.play) from.boxShadow = computedStyle.boxShadow
    if (cardanimation === CARDANIMATION.dragWobble) from = null
    if (cardanimation === CARDANIMATION.drag) delete from.zIndex
    let animation = animations[cardanimation]
    currentAnimation.current?.cancel()
    currentAnimation.current = self.current.animate([from, ...animation.to], animation.options)

    return currentAnimation.current
  }

  let changeModeClass = props.isChangeMode
    ? `card-change-mode card-change-mode-row-${props.changeModeRow}`
    : 'card-player-0'

  let giftClass = props.isGift ? ' card-gift' : ''
  return (
    <>
      <div
        ref={placeholder}
        className={`card ${
          active ? 'card-placeholder-active' : 'card-placeholder'
        } card-player ${changeModeClass}${giftClass}`}
        style={style}
        onPointerDown={onPointerDown}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
      ></div>
      <div
        ref={self}
        className={`card ${props.card.name} card-player ${changeModeClass}${
          props.isSelected
            ? ' card-selected'
            : props.isValid
            ? ' card-valid'
            : props.isChangeMode && props.isGift === false
            ? ' card-invalid'
            : ''
        }${giftClass} ${animation}`}
        style={style}
      ></div>
    </>
  )
}

export function CardOpponent(props: {
  playerId: string
  card: CardData
  cardIndex: number
  numberOfCards: number
  playerIndex: number
}) {
  return (
    <div
      className={`card ${props.card ? props.card.name : 'rueckseite'} card-opponent card-player-${props.playerIndex} ${
        props.card != null ? 'card-opponent-play' : ''
      }`}
      style={
        {
          [CARD_CSS_VARIABLES.index]: props.cardIndex,
          [CARD_CSS_VARIABLES.handLength]: props.numberOfCards,
          [CARD_CSS_VARIABLES.initialYOffsetFactor]:
            Math.pow(Math.abs(props.numberOfCards / 2 - props.cardIndex), 2) * 0.01,
        } as CSS.Properties
      }
      onAnimationEnd={() => {
        document.dispatchEvent(
          new CustomEvent<{ card: CardData; playerId: string }>('cardplayed', {
            detail: { card: props.card, playerId: props.playerId },
          }),
        )
      }}
    ></div>
  )
}

export function CardTable(props: { card: CardData; cardIndex: number; playerWon: boolean }) {
  const [hover, setHover] = useState(false)

  useEffect(() => {
    if (!hover) return
    document.onpointerup = onPointerUp
    return () => {
      document.onpointerup = undefined
    }
  })

  function onPointerDown() {
    setHover(true)
  }

  function onPointerUp() {
    setHover(false)
  }

  const className =
    props.playerWon != null
      ? props.playerWon
        ? 'card-table-player-win'
        : 'card-table-opponents-win'
      : hover
      ? 'card-table-hover'
      : ''

  return (
    <div
      key={props.card.name}
      className={`card ${props.card.name} card-table card-table-${props.cardIndex} ${className}`}
      onPointerDown={onPointerDown}
    ></div>
  )
}
