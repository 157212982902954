/* eslint-disable react-hooks/exhaustive-deps */

import { CegoClient, PlayerOptions } from '@hs-furtwangen/socket-lib'
import React from 'react'
import { StyledCheckbox, StyledOptionLabel, StyledOption, StyledOptionContent, StyledSelect } from './style'
import { getBoolOption, getStringOption } from '../../lib/OptionHelper'

export const Options = (props: { playerId: string; cegoClient: CegoClient; options: Array<any> }) => {
  const [changeTriggered, setChangeTriggered] = React.useState<boolean>(false)
  const [clickToPlay, setClickToPlay] = React.useState<boolean>(getBoolOption(PlayerOptions.clickToPlayCard))
  const [defaultTab, setDefaultTab] = React.useState<string>(getStringOption(PlayerOptions.defaultTab))
  const [showTablePlayers, setShowTablePlayers] = React.useState<string>(
    getStringOption(PlayerOptions.showTablePlayers),
  )

  const getTabOptionFromIndex = (idx): string => {
    switch (idx) {
      case 0:
        return 'chat'
      case 1:
        return 'points'
      case 2:
        return 'log'
    }
  }

  const getShowPlayersOptionFromIndex = (idx): string => {
    switch (idx) {
      case 0:
        return 'all'
      case 1:
        return 'lobby'
    }
  }

  React.useEffect(() => {
    if (changeTriggered) {
      setChangeTriggered(false)
      setTimeout(() => {
        const options = [
          {
            key: PlayerOptions.defaultTab,
            value: defaultTab,
          },
          {
            key: PlayerOptions.clickToPlayCard,
            value: clickToPlay,
          },
          {
            key: PlayerOptions.showTablePlayers,
            value: showTablePlayers,
          },
        ]
        props.cegoClient.saveOptions(props.playerId, options)
      }, 500)
    }
    localStorage.setItem(PlayerOptions.defaultTab, defaultTab)
    localStorage.setItem(PlayerOptions.clickToPlayCard, `${clickToPlay}`)
    localStorage.setItem(PlayerOptions.showTablePlayers, `${showTablePlayers}`)

    window.dispatchEvent(new Event('options'))
  }, [clickToPlay, defaultTab, showTablePlayers])

  return (
    <StyledOptionContent>
      <h2 className="component-heading">Optionen</h2>
      <StyledOption>
        <StyledOptionLabel htmlFor="corner-tab">Standard Tab am Tisch:</StyledOptionLabel>
        <StyledSelect
          name="corner-tab"
          onChange={(evt) => {
            const tabOption = getTabOptionFromIndex(evt.target.options.selectedIndex)
            if (tabOption !== defaultTab) {
              setChangeTriggered(true)
              setDefaultTab(tabOption)
            }
          }}
        >
          <option selected={defaultTab === 'chat'}>Chat</option>
          <option selected={defaultTab === 'points'}>Punkte</option>
          <option selected={defaultTab === 'log'}>Logbuch</option>
        </StyledSelect>
      </StyledOption>
      <StyledOption>
        <StyledOptionLabel htmlFor={PlayerOptions.showTablePlayers}>Spieler anzeigen:</StyledOptionLabel>
        <StyledSelect
          name="showPlayersSelection"
          onChange={(evt) => {
            const showPlayerOption = getShowPlayersOptionFromIndex(evt.target.options.selectedIndex)
            if (showPlayerOption !== showTablePlayers) {
              setChangeTriggered(true)
              setShowTablePlayers(showPlayerOption)
            }
          }}
        >
          <option selected={showTablePlayers === 'all'}>Alle</option>
          <option selected={showTablePlayers === 'lobby'}>Nur im Stüble</option>
        </StyledSelect>
      </StyledOption>
      <StyledOption>
        <StyledOptionLabel htmlFor={PlayerOptions.clickToPlayCard}>Karten mit Klick ausspielen:</StyledOptionLabel>
        <StyledCheckbox
          type="checkbox"
          id={PlayerOptions.clickToPlayCard}
          checked={clickToPlay}
          onChange={(evt) => {
            setChangeTriggered(true)
            setClickToPlay(evt.target.checked)
          }}
          isVisible={true}
        />
      </StyledOption>
    </StyledOptionContent>
  )
}
