/* eslint-disable react-hooks/exhaustive-deps */
import { CegoClient, ChatData, GameMode, PlayerDataMap } from '@hs-furtwangen/socket-lib'
import { useEffect, useState } from 'react'
import Chat from '../Chat'
import { Log } from '../Log/Log'
import { StyledCornerWindow, TabBox, TabContainer, TabItemCenter, TabItemLeft, TabItemRight, TopSpace } from './style'
import { PointsInGame } from '../PointsInGame/PointsInGame'

const pulseStyle = {
  backgroundColor: 'rgba(255, 255, 0, 0.5)',
  animationDuration: '2.0s',
  animationName: 'yellowBackgroundPulse',
  animationTimingFunction: 'ease-out',
  animationDirection: 'alternate',
  animationIterationCount: 'infinite',
}

export const getTabFromString = (tab: string) => {
  switch (tab) {
    case 'chat':
      return 0
    case 'points':
      return 1
    case 'log':
      return 2
  }
}

export const cornerWindowTabs = {
  chat: 0,
  points: 1,
  log: 2,
}

export const CornerWindow = (props: {
  cegoClient: CegoClient
  logEntries: string[]
  playerId: string
  lobbyPlayers: PlayerDataMap
  tableId: string
  tablePlayerIds: string[]
  gameMode: GameMode
  currentTab: number
  tmpGameMode: GameMode
  messageCache: string
  firstPlayerId?: string
  currentWinData: [string, number][]
  chatMessages: ChatData[]
  setMessageCache: React.Dispatch<React.SetStateAction<string>>
  setCurrentTab: (number) => void
  onTableChatMessage?: (senderId: string, message: string) => void
}) => {
  const getTableMessages = (): ChatData[] => props.chatMessages.filter((msg) => msg.tableId === props.tableId)

  const [newMessage, setNewMessage] = useState(false)
  const [lastChatCount, setLastChatCount] = useState(getTableMessages().length)

  useEffect(() => {
    if (lastChatCount !== getTableMessages().length) {
      const msg = props.chatMessages[props.chatMessages.length - 1]
      if (props.onTableChatMessage) {
        props.onTableChatMessage(msg.senderId, msg.message)
      }
      if (props.currentTab !== cornerWindowTabs.chat) {
        setNewMessage(true)
      }
      setLastChatCount(getTableMessages().length)
    }
  }, [props.chatMessages])

  return (
    <StyledCornerWindow>
      <TabContainer>
        <TabBox>
          <TabItemLeft
            tabSelected={props.currentTab === cornerWindowTabs.chat}
            onClick={() => {
              props.setCurrentTab(cornerWindowTabs.chat)
              setNewMessage(false)
            }}
            style={newMessage ? pulseStyle : {}}
          >
            Chat
          </TabItemLeft>
          <TabItemCenter
            tabSelected={props.currentTab === cornerWindowTabs.points}
            onClick={() => props.setCurrentTab(cornerWindowTabs.points)}
          >
            Punkte
          </TabItemCenter>
          <TabItemRight
            tabSelected={props.currentTab === cornerWindowTabs.log}
            onClick={() => props.setCurrentTab(cornerWindowTabs.log)}
          >
            Logbuch
          </TabItemRight>
        </TabBox>
      </TabContainer>
      <TopSpace />
      {props.currentTab === cornerWindowTabs.chat && (
        <Chat
          key={4}
          cegoClient={props.cegoClient}
          playerId={props.playerId}
          playerName={props.lobbyPlayers[props.playerId].name}
          displayHeading={false}
          tableId={props.tableId}
          gameMode={props.gameMode}
          firstPlayerName={props.lobbyPlayers[props.firstPlayerId]?.name}
          chatMessages={props.chatMessages}
          messageCache={props.messageCache}
          setMessageCache={props.setMessageCache}
        ></Chat>
      )}
      {props.currentTab === cornerWindowTabs.log && (
        <Log
          logEntries={props.logEntries}
          gameMode={props.gameMode}
          tmpGameMode={props.tmpGameMode}
          firstPlayerName={props.lobbyPlayers[props.firstPlayerId]?.name}
        />
      )}
      {props.currentTab === cornerWindowTabs.points && (
        <PointsInGame
          lobbyPlayers={props.lobbyPlayers}
          tablePlayerIds={props.tablePlayerIds}
          currentWinData={props.currentWinData}
          ownPlayerId={props.playerId}
          gameMode={props.gameMode}
          tmpGameMode={props.tmpGameMode}
          firstPlayerName={props.lobbyPlayers[props.firstPlayerId]?.name}
          challengerId={props.firstPlayerId}
        />
      )}
    </StyledCornerWindow>
  )
}
