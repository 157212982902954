import React, { useState } from 'react'

export default function useForm<Type>(callback: () => void, initialInputState: Type) {
  const [inputs, setInputs] = useState(initialInputState)

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
    const target = event.target
    const name = event.target.name
    const value = target.type === 'checkbox' ? (target as HTMLInputElement)?.checked : target.value

    setInputs((values) => ({ ...values, [name]: value }))
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    callback()
  }

  return {
    handleInputChange,
    handleSubmit,
    inputs,
  }
}
