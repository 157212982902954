import React from 'react'
import { PlayerDataMap, TableData, TableDataMap, CegoClient } from '@hs-furtwangen/socket-lib'
import '../../CSS/TableList.css'
import iconPadlock from '../../Assets/padlock.png'
import iconNoAvatar from '../../Assets/no_avatar.png'
import {
  FilterButton,
  PadlockIcon,
  StyledTableListItem,
  TableAvatar,
  TableButton,
  TableButtonArea,
  TableListName,
  TableListPlayer,
  TablePlayers,
  TableState,
} from './style'
import { STROAGE_KEYS } from '../../lib/AppConfig'
import { GameModeHelper } from '../../Util/GameModeHelper'

export default function TableList(props: {
  tables: TableDataMap
  players: PlayerDataMap
  playerId: string
  isPortrait: boolean
  startJoiningTable: (tableId: string) => void
  setShowCreateTableModal: (show: Boolean) => void
  searchText: string
  cegoClient: CegoClient
}) {
  const [kickedTableIds, setKickedTableIds] = React.useState([])
  const [filter, setFilter] = React.useState('Sortieren')

  const getNextFilter = (): string => {
    switch (filter) {
      case 'Sortieren':
        return 'Name absteigend'
      case 'Name absteigend':
        return 'Name aufsteigend'
      case 'Name aufsteigend':
        return 'Sortieren'
      default:
        return 'Sortieren'
    }
  }

  const getFilterSymbol = () => {
    switch (filter) {
      case 'Name absteigend':
        return <span style={{ color: '#6b380a' }}>&#9660;</span>
      case 'Name aufsteigend':
        return <span style={{ color: '#6b382a' }}>&#9650;</span>
      default:
        return null
    }
  }

  React.useEffect(() => {
    const tables = JSON.parse(localStorage.getItem(STROAGE_KEYS.kickedTables))
    if (tables) {
      setKickedTableIds(tables)
    }
  }, [])

  let tableItems = Object.values(props.tables).map((table) => {
    if (props.searchText === '' || table.name.toLocaleLowerCase().includes(props.searchText.toLowerCase())) {
      return (
        <TableListItem
          key={table.id}
          table={table}
          players={props.players}
          playerId={props.playerId}
          joinTable={props.startJoiningTable}
          cegoClient={props.cegoClient}
          wasKicked={kickedTableIds.some((id) => id === table.id)}
        ></TableListItem>
      )
    }
    return <></>
  })
  const heading = <h2 className="table-list-heading">Stüble</h2>
  const filterButton = (
    <FilterButton className="button-wide" onClick={() => setFilter(getNextFilter())}>
      {filter} {getFilterSymbol()}
    </FilterButton>
  )
  const createButton = (
    <button className="button-wide" onClick={() => props.setShowCreateTableModal(true)}>
      + Neuer Tisch
    </button>
  )

  return (
    <>
      <div className="table-list">
        {props.isPortrait ? (
          heading
        ) : (
          <div className="table-list-header">
            {filterButton}
            {heading}
            {createButton}
          </div>
        )}
        <div className="table-list-content">
          {filter === 'Sortieren' && tableItems}
          {filter === 'Name absteigend' &&
            tableItems.sort((a, b) => props.tables[a.key].name.localeCompare(props.tables[b.key].name))}
          {filter === 'Name aufsteigend' &&
            tableItems.sort((a, b) => props.tables[b.key].name.localeCompare(props.tables[a.key].name))}
          {filter === 'Offen' &&
            tableItems.sort((a, b) =>
              props.tables[a.key].gameStarted === false && props.tables[a.key].hasPassword === false
                ? 0
                : props.tables[b.key].gameStarted || props.tables[b.key].hasPassword
                ? -1
                : 1,
            )}
        </div>
        {props.isPortrait ? (
          <div className="table-list-footer">
            {filterButton}
            {createButton}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

function TableListItem(props: {
  table: TableData
  players: PlayerDataMap
  playerId: string
  joinTable: (tableId: string) => void
  cegoClient: CegoClient
  wasKicked: boolean
}) {
  const [knocked, setKnocked] = React.useState(false)
  // const [gameStarted, setGameStarted] = React.useState(false)
  const realPlayers = []
  const aiPlayers = []

  React.useEffect(() => {
    if (knocked) {
      setTimeout(() => {
        setKnocked(false)
      }, 30000)
    }
  }, [knocked])

  for (const playerId of props.table.playerIds) {
    if (props.players[playerId]?.isAi) {
      aiPlayers.push(props.players[playerId])
    } else {
      realPlayers.push(props.players[playerId])
    }
  }
  const players = realPlayers.concat(aiPlayers)
  let playerItems = players.map((player) => {
    if (player) {
      return player.isAi === false ? (
        <TableListPlayer key={player.id}>
          <TableAvatar src={player.iconPath} />
          {`${player.name}`}
        </TableListPlayer>
      ) : props.table.gameStarted === false ? (
        <TableListPlayer key={player.id} italic>
          <TableAvatar src={iconNoAvatar} />
          Freier Platz
        </TableListPlayer>
      ) : (
        <TableListPlayer key={player.id} italic>
          <TableAvatar src={player.iconPath} />
          {`${player.name}`}
        </TableListPlayer>
      )
    }
    return <></>
  })

  const playerCountWithoutAi = () => {
    let playerCount = 0
    for (const playerId of props.table.playerIds) {
      if (props.players[playerId]?.isAi === false) {
        playerCount++
      }
    }
    return playerCount
  }

  const getGameStatus = () => {
    if (props.table.challengerId && props.table.challengerId !== '' && props.table.gameStarted) {
      const firstPlayer = props.players[props.table.challengerId]
      if (props.table.gameMode && firstPlayer) {
        return `${firstPlayer.name} spielt ${GameModeHelper.gameModeToString(props.table.gameMode)}`
      }
    }
    return props.table.gameStarted ? 'Spiel läuft...' : <div style={{ width: '300px' }}></div>
  }

  return (
    <>
      <StyledTableListItem className="table-list-item">
        {props.table.hasPassword && <PadlockIcon src={iconPadlock} />}
        <TableListName>
          {`${props.table.name}`}
          <TableState visible={true}>{getGameStatus()}</TableState>
        </TableListName>
        <TablePlayers>{playerItems}</TablePlayers>
        <TableButtonArea>
          <TableButton
            className="button-wide"
            onClick={() => {
              props.cegoClient.knockTable(props.playerId, props.table.id)
              setKnocked(true)
            }}
            disabled={knocked || !props.table.gameStarted}
          >
            {knocked ? 'Angeklopft' : 'Anklopfen'}
          </TableButton>
          <TableButton
            className="button-wide"
            onClick={() => props.joinTable(props.table.id)}
            disabled={props.table.gameStarted || playerCountWithoutAi() === 4 || props.wasKicked}
          >
            Beitreten
          </TableButton>
        </TableButtonArea>
      </StyledTableListItem>
    </>
  )
}
