import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { io } from 'socket.io-client'
import { CegoClient } from '@hs-furtwangen/socket-lib'
import { getBackendEndpoint, ROUTES } from './lib/AppConfig'
import { App } from './App'

const socket = io(getBackendEndpoint())
socket.connect()
const cegoClient = new CegoClient(socket)

socket.on('connect_error', () => {
  socket.disconnect()
  if (window.location.pathname !== ROUTES.error) {
    window.location.href = ROUTES.error
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App cegoClient={cegoClient} />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
