import { ModalCardDeckContent } from '../Components/Modal/style'
import { Card } from '@hs-furtwangen/socket-lib'

export const CardDeckDialogContent = (props: { lastStick?: Card[]; leger?: Card[] }) => {
  console.log(props.leger)
  return (
    <>
      {props.lastStick?.length > 0 && (
        <>
          <p>Letzter Stich:</p>
          <ModalCardDeckContent half>
            {props.lastStick
              ?.sort((a, b) => a.value - b.value)
              .map((card) => {
                return (
                  <div className={`card ${card.name}`} style={{ boxShadow: '2px 5px 16px 3px rgba(0,0,0,0.41)' }}></div>
                )
              })}
          </ModalCardDeckContent>
        </>
      )}
      {props.leger?.length > 0 && (
        <>
          <p style={{ marginTop: '10px' }}>Mein Leger:</p>
          <ModalCardDeckContent>
            {props.leger
              ?.sort((a, b) => a.value - b.value)
              .map((card) => {
                return (
                  <div className={`card ${card.name}`} style={{ boxShadow: '2px 5px 16px 3px rgba(0,0,0,0.41)' }}></div>
                )
              })}
          </ModalCardDeckContent>
        </>
      )}
      {(props.leger === undefined || props.leger?.length === 0) &&
        (props.lastStick === undefined || props.lastStick?.length === 0) && (
          <>
            <p>Hier gibt es noch nichts zu sehen...</p>
            <br />
            <p> Ein abgelegtes Blatt oder der letzte Stich werden hier angezeigt.</p>
          </>
        )}
    </>
  )
}
