import { GameMode } from '@hs-furtwangen/socket-lib'
import { useEffect } from 'react'
import { GameModeHelper } from '../../Util/GameModeHelper'
import { StyledLog, StyledLogEntry, StyledLogHeader, StyledLogHeaderGameMode } from './style'

export const Log = (props: {
  logEntries: string[]
  gameMode: GameMode
  tmpGameMode: GameMode
  firstPlayerName?: string
}) => {
  useEffect(() => {
    var element = document.getElementById('game-log')
    element.scrollTop = element.scrollHeight
  }, [props.logEntries])
  const logs = () => {
    return (
      <>
        <ul>
          {props.logEntries.map((logEntry, index) => {
            return (
              <StyledLogEntry key={index}>
                <p>{logEntry}</p>
              </StyledLogEntry>
            )
          })}
        </ul>
      </>
    )
  }
  return (
    <>
      {props.firstPlayerName && (
        <StyledLogHeader>
          {`${props.firstPlayerName} spielt: `}
          <StyledLogHeaderGameMode>{GameModeHelper.gameModeToString(props.gameMode)}</StyledLogHeaderGameMode>
        </StyledLogHeader>
      )}
      <StyledLog id="game-log">{logs()}</StyledLog>
    </>
  )
}
