import styled from 'styled-components'

export const LegacyMainContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-image: url('/res/bg1_winter.jpg');
  background-repeat: round;
  display: flex;
  justify-content: center;
`

export const LegacyContentContainer = styled.div`
  width: 1000px;
  height: ${(props) => {
    return props.height ? props.height : '100%'
  }};
  background-image: url('/res/bg.jpg');
  box-shadow: 0px 0px 15px 0px #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
`

export const LegacySchindeln = styled.div`
  z-index: 99;
  height: 160px;
  width: 1169px;
  background-image: url('/res/schindeln.png');
  position: absolute;
`

export const LegacyButton = styled.button`
  height: 27px;
  background-image: url('/res/button.png');
  background-repeat: no-repeat;
  border: 0px;
  font-size: 16px;
  font-weight: normal;
  color: #3e2a16;
  cursor: pointer;
  padding-right: 10px;
  padding-left: 10px;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: inset 0px 0px 2px 0px #000000;
`

export const LegacyContentTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const LegacyContentTableRow = styled.div`
  display: flex;
  gap: 20px;
  min-height: 40px;
`

export const LegacyContentTableCol = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Verdana';
  font-size: small;
`

export const LegacyNavContainer = styled.div`
  margin-top: 180px;
  width: 890px;
  height: 72px;
  background-image: url('/res/navi.png');
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 40px;
  gap: 25px;
`

export const LegacyNavItem = styled.button`
  height: 60%;
  padding-left: 10px;
  padding-right: 10px;
  color: #866952;
  text-shadow: 0 2px 1px #000, -1px -1px 1px #ebe2c0;
  font-size: x-large;

  background: ${(props) => {
    return props.selected ? '#44535b' : ''
  }};
  box-shadow: ${(props) => {
    return props.selected ? 'inset 0 0 3px 1px #000;' : ''
  }};
  border-radius: ${(props) => {
    return props.selected ? '3px' : ''
  }};

  &:hover {
    background: #44535b;
    box-shadow: inset 0 0 3px 1px #000;
    border-radius: 3px;
  }
`

export const LegacySiteContentContainer = styled.div`
  margin-top: 40px;
  width: 82%;
  height: 100%;
  display: flex;
  gap: 20px;
`

export const LegacyLargePaperContainer = styled.div`
  width: 68%;
  height: 100px;
`

export const LegacyFullPaperContainer = styled.div`
  width: 100%;
  height: 100px;
`

export const LegacyHalfPaperContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;
`

export const LegacyHalfPaperStrip = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const LegacySmallPaperContainer = styled.div`
  width: 32%;
  height: 100px;
`

export const LegacyPinContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: ${(props) => {
    return props.single ? 'center' : 'space-between'
  }};
`

export const LegacyPin = styled.div`
  width: 25px;
  height: 25px;
  background-image: url('/res/reiszwecke.png');
  background-repeat: no-repeat;
`

export const LegacyFooterContainer = styled.div`
  width: 600px;
  height: 45px;
  background-image: url('/res/nav_bg_static.png');
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-bottom: 50px;
`

export const LegacyFooterItem = styled.div`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 2px 1px #000, -1px -1px 1px #ebe2c0;
  font-size: x-large;
  text-shadow: 0 2px 1px #000, -1px -1px 1px #ebe2c0;
  cursor: pointer;
`
