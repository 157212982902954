/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import {
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyFullPaperContainer,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySchindeln,
  LegacySiteContentContainer,
} from '../legacyStyle'
import { ContentPaper } from '../ContentPaper/ContentPaper'
import { ROUTES } from '../../../lib/AppConfig'
import { LegaceDatenschutzContent } from './style'

export const Datenschutz = () => {
  return (
    <>
      <LegacyMainContainer>
        <LegacySchindeln />
        <LegacyContentContainer height="2340px">
          <LegacyNavContainer>
            <Link to={ROUTES.home}>
              <LegacyNavItem>Cego</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lobby}>
              <LegacyNavItem>spielen</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lernen}>
              <LegacyNavItem>lernen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.wissen}>
              <LegacyNavItem>wissen</LegacyNavItem>
            </Link>
          </LegacyNavContainer>
          <LegacySiteContentContainer>
            <LegacyFullPaperContainer>
              <ContentPaper
                title="Datenschutzerklärung"
                children={
                  <LegaceDatenschutzContent>
                    <h2>Allgemeine Hinweise</h2>
                    <p>
                      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
                      Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen
                      Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz
                      entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                    </p>
                    <h2>Datenerfassung auf dieser Website</h2>
                    <h3>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h3>
                    <p>
                      {' '}
                      Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                      können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
                      entnehmen.
                    </p>{' '}
                    <h3>Wie erfassen wir Ihre Daten?</h3>{' '}
                    <p>
                      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.
                      B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch oder
                      nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                      allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                      Erfassung dieser Daten erfolgt nur, wenn Sie sich zum Spielen registriert und angemeldet haben.
                    </p>{' '}
                    <h3>Wofür nutzen wir Ihre Daten?</h3>
                    <p>
                      {' '}
                      Die Daten werden ausschließlich erhoben, um eine fehlerfreie Bereitstellung der Website zu
                      gewährleisten.
                    </p>{' '}
                    <h3>Welche Rechte haben Sie bezüglich Ihrer Daten?</h3>{' '}
                    <p>
                      Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                      gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung
                      oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt
                      haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das
                      Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                      zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
                      zu. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
                    </p>
                    <br />
                    <p>Quelle: https://www.e-recht24.de</p>
                  </LegaceDatenschutzContent>
                }
              ></ContentPaper>
              <ContentPaper
                title="Allgemeine Hinweise und Pflichtinformationen"
                children={
                  <LegaceDatenschutzContent>
                    <h2>Datenschutz</h2>
                    <p>
                      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
                      Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
                      sowie dieser Datenschutzerklärung.
                    </p>
                    <p>
                      Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                      Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
                      vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
                      erläutert auch, wie und zu welchem Zweck das geschieht.
                    </p>
                    <p>
                      Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per
                      E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                      Dritte ist nicht möglich.
                    </p>
                    <h2>Hinweis zur verantwortlichen Stelle</h2>
                    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                    <p>Spiellabor der Hochschule Furtwangen University Vertreten durch:</p>{' '}
                    <p>Prof. Dipl.-Ing. Jirka R. Dell'Oro-Friedl</p> <p>Robert-Gerwig-Platz 1 78120 Furtwangen</p>{' '}
                    <p>E-Mail: spiellabor[at]hs-furtwangen.de</p>{' '}
                    <p>
                      Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Prof. Dipl.-Ing. Jirka R. Dell'Oro-Friedl
                    </p>
                    <h2>Verwendung von Cookies</h2>
                    <p>
                      Mit der Registrierung und der Einwahl ins Spiel werden in Ihrem Browser Cookies gespeichert,
                      welche benötigt werden um einen reibungslosen Spielablauf zu gewährleisten. Die Cookies enthalten
                      keine personenbezogenen Daten und werden nicht an Dritte weitergegeben. Sie dienen rein
                      technischen Zwecken.
                    </p>
                    <br />
                    <p>
                      <p>Quelle: https://www.e-recht24.de</p>
                    </p>
                  </LegaceDatenschutzContent>
                }
              ></ContentPaper>
            </LegacyFullPaperContainer>
          </LegacySiteContentContainer>
          <LegacyFooterContainer>
            <Link to={ROUTES.impressum}>
              <LegacyFooterItem>Impressum</LegacyFooterItem>
            </Link>
          </LegacyFooterContainer>
        </LegacyContentContainer>
      </LegacyMainContainer>
    </>
  )
}
