import { GameMode, PlayerDataMap, WinData } from '@hs-furtwangen/socket-lib'
import { GameModeHelper } from '../Util/GameModeHelper'
import { gameModeIsSondergame, getPointsMultiplier } from '../Util/CalculationHelper'

export const PointsTable = (props: {
  winData: WinData[]
  lobbyPlayers: PlayerDataMap
  tablePlayerIds: string[]
  ownPlayerId: string
}) => {
  const getTablePlayers = () => {
    const players = [props.lobbyPlayers[props.ownPlayerId]]
    for (const id of props.tablePlayerIds
      .filter((id) => id !== props.ownPlayerId && props.lobbyPlayers[id]?.isAi === false)
      .sort()) {
      players.push(props.lobbyPlayers[id])
    }
    for (const id of props.tablePlayerIds.filter((id) => props.lobbyPlayers[id]?.isAi === true).sort()) {
      players.push(props.lobbyPlayers[id])
    }
    return players
  }

  const getPointList = () => {
    let winInfos: WinData[] = []
    for (let i = 1; i < props.winData.length; i++) {
      winInfos.push(props.winData[props.winData.length - i - 1])
    }
    return (
      <div>
        {winInfos
          .filter((info) => info.matchNumber !== -1)
          .map((winInfo, winInfoIdx) => {
            return (
              <div className={'table-lobby-table-points-row'} key={`table-lobby-table-points-row-${winInfoIdx}`}>
                <div className="table-lobby-table-points-item" style={{ textAlign: 'left' }}>
                  {GameModeHelper.gameModeToString(winInfo?.gameMode)}
                </div>
                {getTablePlayers().map((player) => {
                  const idPoints = winInfo.points[player.id]
                  const replacementIdPoints = winInfo.points[player.replacementId]
                  return (
                    <>
                      <div key={`win-info-points-${player.id}-${winInfoIdx}`} className="table-lobby-table-points-item">
                        {idPoints ?? replacementIdPoints}
                      </div>
                    </>
                  )
                })}
              </div>
            )
          })}
      </div>
    )
  }

  const getFullPoints = () => {
    let sums: number[][] = []
    for (let i = 0; i < 4; i++) {
      sums[i] = []
    }
    for (const data of props.winData) {
      for (let i = 0; i < 4; i++) {
        const player = getTablePlayers()[i]
        const idPoints = data.points[player?.id]
        const replacementIdPoints = data.points[player?.replacementId]
        sums[i].push(idPoints ? idPoints : replacementIdPoints ? replacementIdPoints : 0)
      }
    }
    return (
      <>
        {sums.map((sum, idx) => {
          return (
            <div key={`win-info-full-points-${idx}`} className="table-lobby-table-points-item">
              {sum.reduce((partialSum, sum) => partialSum + sum, 0)}
            </div>
          )
        })}
      </>
    )
  }

  const gameModeHasMultiplier = (gameMode: GameMode): boolean => {
    return (
      gameMode !== GameMode.Piccolo &&
      gameMode !== GameMode.Bettel &&
      gameMode !== GameMode.Ultimo &&
      gameMode !== GameMode.Raeuber
    )
  }

  const currentWinIdx = props.winData.length - 1
  const hasWinData = () => props.winData?.filter((data) => data.matchNumber !== -1).length > 0

  return (
    <>
      {hasWinData() === false && <div style={{ height: '100%' }}></div>}
      {hasWinData() && (
        <div className={'table-lobby-table-points'}>
          <div className={'table-lobby-table-points-row'}>
            <div
              className="table-lobby-table-points-item"
              style={{ textAlign: 'left', fontSize: '20px', color: 'red' }}
            ></div>
            {getTablePlayers().map((player, index) => {
              return (
                <div
                  key={`win-info-player-name-${index}`}
                  style={{ color: player.id === props.ownPlayerId ? '#015bff' : 'black' }}
                  className="table-lobby-table-points-item"
                >
                  {props.lobbyPlayers[player.id].name}
                </div>
              )
            })}
          </div>
          <hr style={{ marginTop: '3px' }} />
          {gameModeIsSondergame(props.winData[currentWinIdx]?.gameMode) === false && (
            <div className={'table-lobby-table-points-row'}>
              <div className="table-lobby-table-points-item" style={{ textAlign: 'left' }}>
                Zählwerte
              </div>
              {getTablePlayers().map((player, index) => {
                const idValues = props.winData[currentWinIdx]?.cardValues[player.id]
                const replacementIdValues = props.winData[currentWinIdx]?.cardValues[player.replacementId]
                return (
                  <div key={`win-info-points-${index}`} className="table-lobby-table-points-item">
                    {idValues ? idValues : replacementIdValues ? replacementIdValues : '-'}
                  </div>
                )
              })}
            </div>
          )}
          {gameModeIsSondergame(props.winData[currentWinIdx]?.gameMode) && (
            <div className={'table-lobby-table-points-row'}>
              <div className="table-lobby-table-points-item" style={{ textAlign: 'left' }}>
                Stiche
              </div>
              {getTablePlayers().map((player, index) => {
                return (
                  <div key={`win-info-points-${index}`} className="table-lobby-table-points-item">
                    {props.winData[currentWinIdx]?.stitches[player.id]}
                  </div>
                )
              })}
            </div>
          )}
          <div className={'table-lobby-table-points-row'}>
            <div className="table-lobby-table-points-item" style={{ textAlign: 'left' }}>
              Punkte
            </div>
            {getTablePlayers().map((player, index) => {
              const idPoints = props.winData[currentWinIdx]?.points[player.id]
              const replacementIdPoints = props.winData[currentWinIdx]?.points[player.replacementId]
              return (
                <div key={`win-info-points-${index}`} className="table-lobby-table-points-item">
                  {idPoints ? idPoints : replacementIdPoints ? replacementIdPoints : '-'}
                </div>
              )
            })}
          </div>
          {gameModeHasMultiplier(props.winData[currentWinIdx]?.gameMode) && (
            <div className={'table-lobby-table-points-row'}>
              <div className="table-lobby-table-points-item" style={{ textAlign: 'left' }}>
                Bewertung
              </div>
              {getTablePlayers().map((player, index) => {
                const idValues = props.winData[currentWinIdx]?.cardValues[player.id]
                const replacementIdValues = props.winData[currentWinIdx]?.cardValues[player.replacementId]
                return (
                  <div key={`win-info-points-${index}`} className="table-lobby-table-points-item">
                    {idValues
                      ? getPointsMultiplier(idValues)[0]
                      : replacementIdValues
                      ? getPointsMultiplier(replacementIdValues)[0]
                      : '-'}
                  </div>
                )
              })}
            </div>
          )}
          <hr style={{ marginBottom: '3px', marginTop: '4px' }} />
          <hr />
          <div className={'table-lobby-table-points-row'}>
            <div className="table-lobby-table-points-item" style={{ textAlign: 'left' }}>
              Gesamtpunktzahl
            </div>
            {getFullPoints()}
          </div>
          <hr style={{ marginBottom: '3px', marginTop: '3px' }} />
          <hr style={{ marginBottom: '3px', marginTop: '3px' }} />

          <div className={'table-lobby-table-points-row'}>
            <div className="table-lobby-table-points-item" style={{ textAlign: 'left', color: 'red' }}>
              {GameModeHelper.gameModeToString(props.winData[currentWinIdx]?.gameMode)}
            </div>
            {getTablePlayers().map((player, index) => {
              const idPoints = props.winData[currentWinIdx]?.points[player.id]
              const replacementIdPoints = props.winData[currentWinIdx]?.points[player.replacementId]
              return (
                <div key={`win-info-points-${index}`} className="table-lobby-table-points-item">
                  {idPoints ? idPoints : replacementIdPoints ? replacementIdPoints : '-'}
                </div>
              )
            })}
          </div>

          {getPointList()}
        </div>
      )}
    </>
  )
}
