import styled from 'styled-components'

export const StyledTableListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #e0d19d;
  margin-top: 15px;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
`

export const TableState = styled.p`
  font-size: 14px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: center;
`

export const TableListName = styled.h3`
  min-width: 200px;
  text-align: center !important;
`
export const TablePlayers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 10px;
  align-items: center;
  gap: 15px;
`

export const TableButtonArea = styled.div`
  @media (min-width: 586px) {
    margin-top: 10px;
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 586px) {
    margin-top: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
`

export const FilterButton = styled.button`
  @media (max-width: 586px) {
    display: none;
  }
`

export const TableButton = styled.button`
  text-align: center;
  @media (min-width: 1000px) {
    width: auto;
  }
`

export const ImageFrame = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 25px;
  border: 3px solid gray;
  margin-right: 5px;
  vertical-align: middle;
`

export const TableAvatar = styled.img`
  width: ${(props) => (props.width ? props.width : '35px')};
  height: ${(props) => (props.height ? props.height : '35px')};
  border-radius: 25px;
  border: 3px solid #027007;
  margin-right: 5px;
  vertical-align: middle;

  @media (max-height: 850px) {
    display: none;
  }
`

export const KickButton = styled.i`
  width: ${(props) => (props.width ? props.width : '35px')};
  height: ${(props) => (props.height ? props.height : '35px')};
  border-radius: 25px;
  margin-right: 5px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`

export const TableListPlayer = styled.div`
  color: var(--primary-color-light);
  font-size: 14px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  ${(props) => (props.italic ? 'font-style: italic' : '')}
`

export const PadlockIcon = styled.img`
  width: 20px;
  color: white;
`
