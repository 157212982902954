import styled from 'styled-components'

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`

export const ContentBox = styled.div`
  background-color: white;
  background-image: url('../Assets/paper_texture.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  margin: 15% auto;
  margin-top: 20vh;
  padding: 20px;
  border: 1px solid #888;
  width: ${(props) => (props.wide ? '80%' : '30%')};
  max-height: 70vh;
  overflow-y: auto;
  p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
  }

  @media (max-width: 800px) {
    width: 80%;
  }
`

export const ModalCloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 32px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`
export const ModalHeader = styled.div`
  font-size: 2rem;
  font-weight: lighter;
  margin-bottom: 20px;
`

export const ModalChildren = styled.div`
  margin-top: 20px;
`

export const ModalVersioningContent = styled.div`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  ul {
    list-style: square;
    margin-left: 20px;
  }
  li {
    font-size: 1.2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    padding: 3px;
  }
  p {
    margin-top: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    font-weight: bold;
  }
  span {
    color: red;
    font-size: 1.6rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
  }
`

export const ModalErrorReportContent = styled.div`
  p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
  }

  .report-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  textarea {
    resize: none;
    height: 200px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    padding: 5px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    word-break: break-word;
  }
`

export const ModalCardDeckContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: ${(props) => (props.half ? '50%' : '100%')};
`
