const BACKEND_ENDPOINT_HTTP = `http://${window.location.hostname}:3030`
const BACKEND_ENDPOINT_HTTPS = `https://${window.location.hostname}:3030`

export const getBackendEndpoint = () => {
  if (process.env.NODE_ENV === 'development') {
    return BACKEND_ENDPOINT_HTTP
  } else {
    return BACKEND_ENDPOINT_HTTPS
  }
}

export const ROUTES = {
  home: '/',
  lobby: '/lobby',
  lernen: '/lernen',
  eLearning: '/eLearning',
  wissen: '/wissen',
  impressum: '/impressum',
  datenschutz: '/datenschutz',
  login: '/login',
  register: '/register',
  passwordReset: 'passwordReset',
  passwordChange: 'passwordChange',
  deleteAccount: 'deleteAccount',
  activate: '/activate',
  activated: '/activate/success',
  error: '/error',
  kicked: '/error/kicked',
}

export const STROAGE_KEYS = {
  token: 'cego-token',
  version: 'cego-version',
  greet: 'greet',
  kickedTables: 'kickedTables',
}
