import React, { useState } from 'react'
import { ROUTES } from '../../lib/AppConfig'
import { useAuth } from '../../lib/AuthProvider'
import useForm from '../UseForm'
import {
  StyledLoginContent,
  StyledLoginError,
  StyledLoginForm,
  StyledLoginLabel,
  StyledLoginProblemHint,
  StyledRegisterHint,
} from './style'
import {
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyFullPaperContainer,
  LegacyHalfPaperContainer,
  LegacyHalfPaperStrip,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySchindeln,
  LegacySiteContentContainer,
} from '../LegacyPages/legacyStyle'
import { Link } from 'react-router-dom'
import { ContentPaper } from '../LegacyPages/ContentPaper/ContentPaper'
import { UAParser } from 'ua-parser-js'

const ua = navigator.userAgent
const browserInfo = new UAParser(ua).getResult()

export const Login = () => {
  const [loginError, setLoginError] = React.useState(false)
  const { onLogin } = useAuth()
  const passwordRef = React.useRef(null)

  const initialInputState = { accountName: '', password: '', stayLoggedIn: false }
  const { handleInputChange, handleSubmit, inputs } = useForm(loginCallback, initialInputState)

  const [browserName] = useState(browserInfo.browser.name)
  const [browserVersion] = useState(browserInfo.browser.major)

  const browserIsOldOrNotSupported = (): boolean => {
    switch (browserName) {
      case 'Chrome':
        return browserVersion < 104
      case 'Edge':
        return browserVersion < 104
      case 'Firefox':
        return browserVersion < 104
      case 'Safari':
      case 'Mobile Safari':
        return browserVersion < 16
      case 'Opera':
        return browserVersion < 90
      case 'Chromium':
        return browserVersion < 104
      default:
        return true
    }
  }

  function loginCallback() {
    setLoginError(false)
    onLogin(inputs.accountName, inputs.password, inputs.stayLoggedIn, () => {
      passwordRef.current.value = ''
      setLoginError(true)
    })
  }

  return (
    <>
      <LegacyMainContainer>
        <LegacySchindeln />
        <LegacyContentContainer height="1650px">
          <LegacyNavContainer>
            <Link to={ROUTES.home}>
              <LegacyNavItem>Cego</LegacyNavItem>
            </Link>
            <Link to={ROUTES.lobby}>
              <LegacyNavItem selected>spielen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.lernen}>
              <LegacyNavItem>lernen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.wissen}>
              <LegacyNavItem>wissen</LegacyNavItem>
            </Link>
          </LegacyNavContainer>
          <LegacySiteContentContainer>
            <LegacyFullPaperContainer>
              <ContentPaper
                center
                title="Im Stüble anmelden"
                children={
                  <>
                    {browserIsOldOrNotSupported() && (
                      <StyledLoginProblemHint>
                        <p>
                          Der von dir genutzte Internet Browser ({browserName} {browserVersion}) wird leider nicht
                          unterstützt.
                        </p>
                        <br />
                        <p>
                          Bitte lade dir die neuste Version des Browsers deiner Wahl herunter um dich anmelden zu
                          können.
                        </p>
                        <br />
                        <p>
                          Unterstützung findest du hier:{' '}
                          <a href="https://browser-update.org/update-browser.html#3">Browser aktualisieren</a>
                        </p>
                      </StyledLoginProblemHint>
                    )}
                    {!browserIsOldOrNotSupported() && (
                      <StyledLoginContent>
                        <StyledLoginForm className="login-form" onSubmit={handleSubmit}>
                          <input
                            type="text"
                            name="accountName"
                            placeholder="E-Mail / Benutzername"
                            onChange={handleInputChange}
                          ></input>
                          <input
                            ref={passwordRef}
                            type="password"
                            name="password"
                            placeholder="Passwort"
                            onChange={handleInputChange}
                          ></input>
                          {loginError && (
                            <>
                              <StyledLoginError>Benutzername / Passwort falsch</StyledLoginError>
                              <br />
                            </>
                          )}
                          <StyledLoginLabel>
                            Angemeldet bleiben
                            <input
                              type="checkbox"
                              name="stayLoggedIn"
                              onChange={handleInputChange}
                              style={{ marginLeft: '5px' }}
                            />
                          </StyledLoginLabel>
                          <button className="button-wide" type="submit">
                            Im Stüble anmelden
                          </button>
                        </StyledLoginForm>
                      </StyledLoginContent>
                    )}
                  </>
                }
              ></ContentPaper>
              <ContentPaper
                title="Schwierigkeiten beim Anmelden?"
                children={
                  <>
                    <StyledLoginProblemHint>
                      <p>
                        Solltest du dein Passwort vergessen haben, nutze bitte die Funktion "Passwort vergessen?" auf
                        dieser Seite.
                      </p>
                      <br />
                      <p>
                        Wenn du dich mit einem falschen Benutzernamen registriert hast, nutze bitte die Funktion
                        "Benutzerkonto löschen" auf dieser Seite und erstelle dir nach dem Löschen ein neues Konto.
                      </p>
                      <br />
                      <p>
                        Wenn du ein anderes Anliegen hast, kannst du eine E-Mail an{' '}
                        <a href="mailto:info@cego-reloaded.de">info@cego-reloaded.de</a> schicken.
                      </p>
                    </StyledLoginProblemHint>
                  </>
                }
              ></ContentPaper>
            </LegacyFullPaperContainer>
            <LegacyHalfPaperContainer>
              <LegacyHalfPaperStrip>
                <ContentPaper
                  title="Noch kein Mitglied?"
                  children={
                    <>
                      <StyledRegisterHint>
                        <a href={ROUTES.register}>Hier</a> kannst du dich registrieren!
                      </StyledRegisterHint>
                    </>
                  }
                ></ContentPaper>
                <ContentPaper
                  title="Passwort vergessen?"
                  children={
                    <>
                      <StyledRegisterHint>
                        <a href={ROUTES.passwordReset}>Hier</a> kannst du dein Passwort zurücksetzen, indem du deine
                        E-Mail Adresse bestätigst.
                      </StyledRegisterHint>
                    </>
                  }
                ></ContentPaper>
                <ContentPaper
                  title="Benutzerkonto löschen"
                  children={
                    <>
                      <StyledLoginProblemHint>
                        Falls du dein Benutzerkonto löschen möchtest, kannst du das{' '}
                        <a href={ROUTES.deleteAccount}>hier</a> tun.
                      </StyledLoginProblemHint>
                    </>
                  }
                ></ContentPaper>
              </LegacyHalfPaperStrip>
            </LegacyHalfPaperContainer>
          </LegacySiteContentContainer>
          <LegacyFooterContainer>
            <Link to={ROUTES.impressum}>
              <LegacyFooterItem>Impressum</LegacyFooterItem>
            </Link>
          </LegacyFooterContainer>
        </LegacyContentContainer>
      </LegacyMainContainer>
    </>
  )
}
