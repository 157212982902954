import { CegoClient, ChatData, PlayerDataMap } from '@hs-furtwangen/socket-lib'
import { useEffect, useState } from 'react'
import '../CSS/Chat.css'
import Avatar from '../Assets/default_avatar.png'
import { isNullOrEmpty } from '../Util/IsNullOrEmpty'
import { GameMode } from '@hs-furtwangen/socket-lib'
import { StyledLogHeader, StyledLogHeaderGameMode } from './Log/style'
import { GameModeHelper } from '../Util/GameModeHelper'

export default function Chat(props: {
  cegoClient: CegoClient
  playerId: string
  playerName: string
  displayHeading: Boolean
  chatMessages: ChatData[]
  messageCache: string
  lobbyPlayers?: PlayerDataMap
  gameMode?: GameMode
  firstPlayerName?: string
  tableId?: string
  chatStyle?: object
  chatContentStyle?: object
  setMessageCache: React.Dispatch<React.SetStateAction<string>>
}) {
  const [textMessage, setTextMessage] = useState(props.messageCache)
  const [serverMessages, setServerMessages] = useState<ChatData[]>([])

  const keyUpHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      sendChatMessage()
    }
  }

  const getIconPath = (playerId: string) => {
    const path = props.lobbyPlayers?.[playerId]?.iconPath
    return isNullOrEmpty(path) ? Avatar : path
  }

  useEffect(() => {
    // scroll chat down
    var element = document.getElementById('game-chat')
    element.scrollTop = element.scrollHeight
  }, [props.chatMessages])

  const messages = () => {
    return (
      <ul>
        {props.chatMessages
          .concat(serverMessages)
          ?.filter((msg) => msg.tableId === props.tableId)
          .map((chatData: ChatData, index) => {
            const date = new Date(chatData.time)
            const timeString = `${date.getHours().toLocaleString().padStart(2, '0')}:${date
              .getMinutes()
              .toLocaleString()
              .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
            return (
              <div className="chat-message" key={index}>
                <h4>
                  <img className="chat-avatar" src={chatData.senderAvatar ?? getIconPath(chatData.senderId)} alt="" />
                  {chatData.senderName}
                </h4>
                <p style={{ fontSize: '12px' }}>{timeString}</p>
                <p>{`${chatData.message}`}</p>
              </div>
            )
          })}
      </ul>
    )
  }

  const handleChange = (event) => {
    setTextMessage(event.target.value)
    props.setMessageCache(event.target.value)
  }

  const sendChatMessage = () => {
    if (isNullOrEmpty(textMessage) === false) {
      props.setMessageCache('')

      const isAlert = props.playerName === 'mabakaba' && textMessage.startsWith('alert:')
      const isCmd = props.playerName === 'mabakaba' && textMessage.startsWith('cmd:')
      if (isAlert) {
        let text = textMessage.split('alert:')[1]
        if (text === 'update') {
          text = 'Der Cego-Server wird in Kürze neu gestartet damit ein neues Update installiert werden kann!'
        }
        props.cegoClient.sendAlert(text)
      } else if (isCmd) {
        const cmd = textMessage.split('cmd:')[1]
        props.cegoClient.sendServerCommand(cmd, (res) => {
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              const newMessage = new ChatData(
                props.playerId,
                'Server',
                'avatars/defaultAvatar.png',
                new Date(),
                res.data[i],
              )
              setServerMessages([...serverMessages, newMessage])
            }
          }
        })
      } else {
        if (props.tableId) {
          props.cegoClient.sendTableChatMessage(props.playerId, props.playerName, textMessage, props.tableId)
        } else {
          props.cegoClient.sendGlobalChatMessage(props.playerId, props.playerName, textMessage)
        }
      }

      setTextMessage('')
    }
  }

  return (
    <div className="chat" style={props.chatStyle}>
      {props.displayHeading && <h2 className="component-heading">Chat</h2>}
      {props.firstPlayerName && (
        <StyledLogHeader>
          {`${props.firstPlayerName} spielt: `}
          <StyledLogHeaderGameMode>{GameModeHelper.gameModeToString(props.gameMode)}</StyledLogHeaderGameMode>
        </StyledLogHeader>
      )}

      <div id="game-chat" className="chat-content" style={props.chatContentStyle}>
        {messages()}
      </div>
      <div className="chat-input">
        <div className="chat-input-text-placeholder">
          <input
            className="chat-input-text"
            type="text"
            placeholder="Nachricht eingeben ..."
            value={textMessage}
            onChange={handleChange}
            onKeyUp={keyUpHandler}
          />
        </div>

        <button className="chat-input-button" onClick={() => sendChatMessage()}>
          Senden
        </button>
      </div>
    </div>
  )
}
