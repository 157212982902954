import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getBackendEndpoint, ROUTES, STROAGE_KEYS } from './AppConfig'
import { PlayerData, PlayerOptions } from '@hs-furtwangen/socket-lib'

class PlayerDataWrapper implements PlayerData {
  id: string
  name: string
  mail: string
  isAi: boolean
  iconPath: string
  sessionId: string
  replacementId: string
  options: any
  tableId: string

  constructor(
    id: string,
    mail: string,
    name: string,
    isAi: boolean,
    iconPath: string,
    sessionId: string,
    options: any,
    tableId: string,
  ) {
    this.id = id
    this.name = name
    this.mail = mail
    this.isAi = isAi
    this.iconPath = iconPath
    this.sessionId = sessionId
    this.options = options
    this.tableId = tableId
  }
}

interface AuthState {
  token: string
  playerData: PlayerData
  verifyToken: () => Promise<Boolean>
  onLogin: (username?: string, password?: string, stayLoggedIn?: boolean, onError?: () => void) => void
  onLogout: (route?: string) => void
  onKick: () => void
  setAvatar: (iconPath: string) => void
}

export const AuthContext = React.createContext<AuthState>({
  token: '',
  playerData: null,
  verifyToken: null,
  onLogin: null,
  onLogout: null,
  onKick: null,
  setAvatar: null,
})

export const AuthProvider = ({ children }) => {
  const [token, setToken] = React.useState<string>('')
  const [playerData, setPlayerData] = React.useState<PlayerData>(null)
  const navigate = useNavigate()

  const requestOptions = (jsonBody) => {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody),
    }
  }

  /**
   * Login with persisted token or provided username and password
   * @param username Username (optional)
   * @param password Password (optional)
   */
  const handleLogin = async (username?: string, password?: string, stayLoggedIn?: boolean, onError?: () => void) => {
    const cegoToken = localStorage.getItem(STROAGE_KEYS.token)
    if (username && password) {
      const response = await fetch(
        `${getBackendEndpoint()}/auth`,
        requestOptions({
          user: username,
          password: password,
          expiration: stayLoggedIn ? '30d' : '1d',
          userAgent: navigator.userAgent,
        }),
      )
      if (response.ok) {
        const data = await response.json()
        if (data.token && data.userName && data.userId) {
          if (data.greet) {
            localStorage.setItem(STROAGE_KEYS.greet, 'true')
          }
          localStorage.setItem(STROAGE_KEYS.token, data.token)
          setPlayerData(
            new PlayerDataWrapper(
              data.userId,
              data.userMail,
              data.userName,
              false,
              data.avatar,
              data.sessionId,
              data.options,
              null,
            ),
          )
          setOptions(data.options)
          setToken(data.token)
          navigate(ROUTES.lobby)
        }
      } else {
        onError()
      }
    } else if (cegoToken) {
      const response = await fetch(
        `${getBackendEndpoint()}/auth`,
        requestOptions({ token: token, userAgent: navigator.userAgent }),
      )
      if (response.ok) {
        const data = await response.json()
        if (data.userName && data.userId) {
          localStorage.setItem(STROAGE_KEYS.token, data.token)
          setPlayerData(
            new PlayerDataWrapper(
              data.userId,
              data.userMail,
              data.userName,
              false,
              data.avatar,
              data.sessionId,
              data.options,
              null,
            ),
          )
          setOptions(data.options)
          setToken(cegoToken)
          navigate(ROUTES.lobby)
        }
      }
    }
  }

  const verifyToken = async (): Promise<Boolean> => {
    const cegoToken = localStorage.getItem(STROAGE_KEYS.token)
    if (cegoToken) {
      const response = await fetch(
        `${getBackendEndpoint()}/auth`,
        requestOptions({ token: cegoToken, userAgent: navigator.userAgent }),
      )
      if (response.ok) {
        const data = await response.json()
        if (data.userName && data.userId) {
          localStorage.setItem(STROAGE_KEYS.token, cegoToken)
          const newPlayerData = new PlayerDataWrapper(
            data.userId,
            data.userMail,
            data.userName,
            false,
            data.avatar,
            data.sessionId,
            data.options,
            null,
          )
          setOptions(data.options)
          if (!playerData) {
            setPlayerData(newPlayerData)
          }
          if (token !== cegoToken) {
            setToken(cegoToken)
          }
          return true
        }
      } else {
        localStorage.removeItem(STROAGE_KEYS.token)
      }
    }
    return false
  }

  const setOptions = (options: any) => {
    // set defaults
    localStorage.setItem(PlayerOptions.clickToPlayCard, 'true')
    localStorage.setItem(PlayerOptions.defaultTab, 'chat')
    localStorage.setItem(PlayerOptions.showTablePlayers, 'all')

    // set values when available
    options?.forEach((option) => {
      if (option.key === PlayerOptions.clickToPlayCard) {
        localStorage.setItem(PlayerOptions.clickToPlayCard, option.value)
      } else if (option.key === PlayerOptions.defaultTab) {
        localStorage.setItem(PlayerOptions.defaultTab, option.value)
      } else if (option.key === PlayerOptions.showTablePlayers) {
        localStorage.setItem(PlayerOptions.showTablePlayers, option.value)
      }
    })
  }

  const handleLogout = () => {
    localStorage.removeItem(STROAGE_KEYS.token)
    setToken(null)
    navigate(ROUTES.login)
  }

  const handleKick = () => {
    setToken(null)
    navigate(ROUTES.kicked)
  }

  const setAvatar = (iconPath) => {
    const data = playerData
    data.iconPath = iconPath
    setPlayerData(data)
  }

  const value = {
    token,
    playerData,
    verifyToken,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onKick: handleKick,
    setAvatar: setAvatar,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}
