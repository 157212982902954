import styled from 'styled-components'

export const LegacyPaperContainer = styled.div`
  width: 100%;
  transform: rotate(
    ${(props) => {
      return props.rotation
    }}deg
  );
`

export const LegacyPaperTop = styled.div`
  height: 110px;
  background-image: url(${(props) => {
    return props.isNews ? '/res/blatt_oben_gelb.png' : '/res/bg_blatt.jpg'
  }});
  background-position-y: -20px;
  background-repeat: no-repeat;
  padding: 10px;
  color: #3e2a16;
  font-size: x-large;
  text-align: ${(props) => {
    return props.center ? 'center' : 'inherited'
  }};
`

export const LegacyPaperContent = styled.div`
  background-color: ${(props) => {
    return props.isNews ? '#EBE2C0' : 'white'
  }};
  padding: 10px;
  margin-top: -1px;
  font-family: 'Verdana';
  font-size: small;
  line-height: 1.6;

  p {
    font-family: 'Verdana';
    font-size: small;
  }
`

export const LegacyPaperFooter = styled.div`
  width: 100%;
  height: 52px;
  margin-top: -1px;
  background-image: url(${(props) => {
    return props.isNews ? '/res/blatt_unten_gelb.png' : '/res/abrisskante.png'
  }});
`

export const LegacyPaperDate = styled.div`
  position: absolute;
  font-family: 'Verdana';
  font-size: x-small;
`

export const LegacyFitImg = styled.div`
  background-image: url(${(props) => {
    return props.src
  }});
  width: 100%;
  height: 200px;
`

export const LegacyBoldSection = styled.div`
  font-family: 'Verdana';
  font-size: small;
  font-weight: bold;
`

export const LegacyBoldInlineSection = styled.b`
  font-family: 'Verdana';
  font-size: small;
  font-weight: bold;
`
