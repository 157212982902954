/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES, STROAGE_KEYS } from './AppConfig'

export const UnauthorizedRoute: FunctionComponent<any> = ({ children }) => {
  const navigate = useNavigate()

  React.useEffect(() => {
    if (localStorage.getItem(STROAGE_KEYS.token)?.length > 0) {
      return navigate(ROUTES.home)
    }
  }, [])

  return children
}
