import styled from 'styled-components'

export const KnockAlertContainer = styled.div`
  border: 5px solid #6b380a;
  border-radius: 10px;
  background-color: #e0d19d;
  padding: 20px;
  right: 20px;
  top: 20px;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
`
