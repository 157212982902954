import { useEffect, useState } from 'react'
import { ROUTES } from '../../lib/AppConfig'
import { isNullOrEmpty } from '../../Util/IsNullOrEmpty'
import { StyledActivateForm } from '../Register/style'
import useForm from '../UseForm'
import {
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyFullPaperContainer,
  LegacyHalfPaperContainer,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySchindeln,
  LegacySiteContentContainer,
} from '../LegacyPages/legacyStyle'
import { Link } from 'react-router-dom'
import { ContentPaper } from '../LegacyPages/ContentPaper/ContentPaper'

export const PasswordChange = (props: { backendEndpoint: string }) => {
  const initialInputState = { password: '', passwordRepeat: '' }
  const [passwordRepeatError, setPasswordRepeatError] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const { handleInputChange, handleSubmit, inputs } = useForm(resetCallback, initialInputState)
  const [code, setCode] = useState('')

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    console.log(urlParams.get('code'))
    setCode(urlParams.get('code'))
  }, [code])

  const requestOptions = (jsonBody) => {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody),
    }
  }

  async function resetCallback() {
    const pwMatch = inputs.password === inputs.passwordRepeat
    setPasswordRepeatError(pwMatch === false)
    if (pwMatch) {
      const response = await fetch(
        `${props.backendEndpoint}/changePassword`,
        requestOptions({
          token: code,
          password: inputs.password,
        }),
      )
      if (response.ok) {
        setPasswordChanged(true)
      }
    }
  }

  return (
    <LegacyMainContainer>
      <LegacySchindeln />
      <LegacyContentContainer height="1650px">
        <LegacyNavContainer>
          <Link to={ROUTES.home}>
            <LegacyNavItem>Cego</LegacyNavItem>
          </Link>
          <Link to={ROUTES.lobby}>
            <LegacyNavItem selected>spielen</LegacyNavItem>
          </Link>
          <Link to={ROUTES.lernen}>
            <LegacyNavItem>lernen</LegacyNavItem>
          </Link>
          <Link to={ROUTES.wissen}>
            <LegacyNavItem>wissen</LegacyNavItem>
          </Link>
        </LegacyNavContainer>
        <LegacySiteContentContainer>
          <LegacyFullPaperContainer>
            <LegacyHalfPaperContainer>
              <ContentPaper
                title="Neues Passwort vergeben"
                children={
                  <>
                    {isNullOrEmpty(code) === false && (
                      <>
                        {passwordChanged === false && (
                          <>
                            <StyledActivateForm onSubmit={handleSubmit}>
                              <br />
                              <input
                                type="password"
                                name="password"
                                placeholder="Passwort"
                                onChange={handleInputChange}
                                required
                              ></input>
                              <input
                                type="password"
                                name="passwordRepeat"
                                placeholder="Passwort wiederholen"
                                onChange={handleInputChange}
                                required
                              ></input>
                              {passwordRepeatError && (
                                <>
                                  <p style={{ color: 'red', fontWeight: 'bold' }}>
                                    Die Passwörter müssen
                                    <br /> übereinstimmen!
                                  </p>
                                  <br />
                                </>
                              )}
                              <button className="button-wide" type="submit">
                                Passwort ändern
                              </button>
                            </StyledActivateForm>
                          </>
                        )}
                        {passwordChanged && (
                          <p>
                            Das Passwort wurde erfolgreich geändert! Klicke{' '}
                            <a href={ROUTES.home} style={{ textDecoration: 'none', color: 'blue' }}>
                              hier
                            </a>{' '}
                            um dich anzumelden.
                          </p>
                        )}
                      </>
                    )}
                  </>
                }
              ></ContentPaper>
            </LegacyHalfPaperContainer>
          </LegacyFullPaperContainer>
        </LegacySiteContentContainer>
        <LegacyFooterContainer>
          <Link to={ROUTES.impressum}>
            <LegacyFooterItem>Impressum</LegacyFooterItem>
          </Link>
        </LegacyFooterContainer>
      </LegacyContentContainer>
    </LegacyMainContainer>
  )
}
