/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import {
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyFullPaperContainer,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySchindeln,
  LegacySiteContentContainer,
} from '../legacyStyle'
import { ContentPaper } from '../ContentPaper/ContentPaper'
import { ROUTES } from '../../../lib/AppConfig'
import { LegacyBoldInlineSection, LegacyBoldSection } from '../ContentPaper/style'

export const Impressum = () => {
  return (
    <>
      <LegacyMainContainer>
        <LegacySchindeln />
        <LegacyContentContainer height="2340px">
          <LegacyNavContainer>
            <Link to={ROUTES.home}>
              <LegacyNavItem>Cego</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lobby}>
              <LegacyNavItem>spielen</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lernen}>
              <LegacyNavItem>lernen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.wissen}>
              <LegacyNavItem>wissen</LegacyNavItem>
            </Link>
          </LegacyNavContainer>
          <LegacySiteContentContainer>
            <LegacyFullPaperContainer>
              <ContentPaper
                title="Impressum"
                children={
                  <>
                    <p>Angaben gemäß § 5 TMG:</p>
                    <br />
                    <p>
                      Spiellabor der Hochschule Furtwangen University Vertreten durch: Prof. Dipl.-Ing. Jirka R.
                      Dell'Oro-Friedl
                      <br /> Robert-Gerwig-Platz 1<br /> 78120 Furtwangen
                      <br /> E-Mail: spiellabor[at]hs-furtwangen.de
                    </p>
                    <br />
                    <p>
                      Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: Prof. Dipl.-Ing. Jirka R. Dell'Oro-Friedl
                    </p>
                    <br />
                    <LegacyBoldSection>Fördergesellschaft der Hochschule Furtwangen e.V.</LegacyBoldSection>{' '}
                    <p>
                      IBAN: <LegacyBoldInlineSection>DE52 6649 2700 0120 2640 10</LegacyBoldInlineSection>
                    </p>{' '}
                    <p>
                      BIC: <LegacyBoldInlineSection>GENODE61KZT</LegacyBoldInlineSection>
                    </p>
                    <p>
                      Verwendungszweck: <LegacyBoldInlineSection>Spende Cego-Online</LegacyBoldInlineSection>
                    </p>
                    <br />
                    <p>
                      Quellenangaben für die verwendeten Bilder und Grafiken:
                      <br />
                      www.naturpark-suedschwarzwald.de
                    </p>
                    <br />
                    <p>
                      Quellenangabe für dieses Impressum: <br />
                      eRecht24, Portal zum Internetrecht von Rechtsanwalt Sören Siebert
                    </p>
                  </>
                }
              ></ContentPaper>
              <ContentPaper
                title="Haftung für die Inhalte"
                children={
                  <>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                    Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                    Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                    zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                    Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
                    Inhalte umgehend entfernen.
                  </>
                }
              ></ContentPaper>
              <ContentPaper
                title="Haftung für Links"
                children={
                  <>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                    haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte
                    der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                    verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                    inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                    umgehend entfernen.
                  </>
                }
              ></ContentPaper>
              <ContentPaper
                title="Urheberrecht"
                children={
                  <>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
                    außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
                    bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                    Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden
                    die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
                    Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                    umgehend entfernen.
                  </>
                }
              ></ContentPaper>
              <ContentPaper
                title="Datenschutz"
                children={
                  <>
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit
                    auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen)
                    erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne
                    Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die
                    Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
                    kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung
                    von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
                    nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich
                    widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
                    unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                  </>
                }
              ></ContentPaper>
            </LegacyFullPaperContainer>
          </LegacySiteContentContainer>
          <LegacyFooterContainer>
            <LegacyFooterItem>Impressum</LegacyFooterItem>
          </LegacyFooterContainer>
        </LegacyContentContainer>
      </LegacyMainContainer>
    </>
  )
}
