import {
  GameMode,
  Card as CardData,
  CegoClient,
  TableData,
  PlayerDataMap,
  PlayerData,
  WinData,
  CardColor,
  PlayerOptions,
  ChatData,
} from '@hs-furtwangen/socket-lib'
import { useEffect, useState } from 'react'
import { GameModeHelper } from '../Util/GameModeHelper'
import { CardPlayer, CardOpponent, CardTable } from './Card'
import { ModalDialog } from './Modal/ModalDialog'
import TableLobby from './TableLobby/TableLobby'
import buttonFullscreen from '../Assets/button_fullscreen.png'
import buttonLeave from '../Assets/button_leave.png'
import buttonSort from '../Assets/button_sort.png'
import buttonErrorReport from '../Assets/button_error.png'

import '../CSS/Table.css'
import { ModalBackground } from './Modal/style'
import { CardDeckDialogContent } from '../lib/CardDeck'
import { ErrorReportDialogContent } from '../lib/ErrorReport'
import { StyledTablePlayerPicture } from './Table/style'
import { CornerWindow, cornerWindowTabs, getTabFromString } from './CornerWindow/CornerWindow'
import { getBoolOption } from '../lib/OptionHelper'
import { KnockAlert } from './KnockAlert/KnockAlert'
import { isNullOrEmpty } from '../Util/IsNullOrEmpty'

// TODO: Cleanup functions that queue stuff
const gameEventQueue: (() => void)[] = []
function workGameEventQueue(time: number) {
  setTimeout(() => {
    let newTime: number
    let gameEvent = gameEventQueue.shift()
    if (gameEvent) {
      gameEvent()
      newTime = process.env.NODE_ENV === 'development' ? 100 : 600 // should be longer than the longest animation duration
    } else {
      newTime = 100
    }
    workGameEventQueue(newTime)
  }, time)
}

workGameEventQueue(100)

enum CHANGEMODE {
  none = 'none',
  keep = 'keep',
  push = 'push',
  gift = 'gift',
  swap = 'swap',
  giftWithSameColor = 'giftWithSameColor',
}

export default function Table(props: {
  cegoClient: CegoClient
  ownPlayerId: string
  lobbyPlayers: PlayerDataMap
  currentTable: TableData
  chatMessages: ChatData[]
  gameStarted: boolean
  readyPlayerIds: string[]
  isHosting: boolean
  showAlert: Boolean
  alertText: string
  setShowAlert: React.Dispatch<React.SetStateAction<Boolean>>
  setReadyPlayerIds: React.Dispatch<React.SetStateAction<string[]>>
  setGameStarted: React.Dispatch<React.SetStateAction<boolean>>
  leaveTable: () => void
}) {
  const [validGameModes, setValidGameModes] = useState<GameMode[]>([])
  const [playerHand, setPlayerHand] = useState<CardData[]>([])
  const [opponentHands, setOpponentHands] = useState<{ [playerId: string]: CardData[] }>({})
  const [validCards, setValidCards] = useState<CardData[]>([])
  const [tableCards, setTableCards] = useState<{ playerIndex: number; card: CardData }[]>([])
  const [roundWinnerId, setRoundWinnerId] = useState<string>(null)
  const [roundNumber, setRoundNumber] = useState<number>(0)
  const [winData, setWinData] = useState<WinData[]>([])
  const [lastChallengerId, setLastChallengerId] = useState<string>(null)
  const [firstPlayerId, setFirstPlayerId] = useState<string>(null)

  const [lastStick, setLastStick] = useState<CardData[]>([])
  const [showLastTrick, setShowLastTrick] = useState<Boolean>(false)

  const [changeMode, setChangeMode] = useState<CHANGEMODE>(CHANGEMODE.none)
  const [giftCards, setGiftCards] = useState<CardData[]>([])
  const [changeCards, setChangeCards] = useState<CardData[]>([])
  const [legerCards, setLegerCards] = useState<CardData[]>([])
  const [changeAmount, setChangeAmount] = useState(0)
  const [sortMode, setSortMode] = useState<number>(0)
  const [logEntries, setLogEntries] = useState<string[]>([])
  const [currentWinData, setCurrentWinData] = useState<[string, number][]>([])
  const [currentGameLogEntries, setCurrentGameLogEntries] = useState<string[]>([])
  const [tmpGameMode, setTmpGameMode] = useState<GameMode>(GameMode.None)
  const [currentGameMode, setCurrentGameMode] = useState<GameMode>(GameMode.None)
  const [selectedCornerTab, setSelectedCornerTab] = useState<number>(
    getTabFromString(localStorage.getItem(PlayerOptions.defaultTab)) ?? cornerWindowTabs.chat,
  )
  const [showErrorReportDialog, setShowErrorReportDialog] = useState<Boolean>(false)
  const [announcedGameModes, setAnnouncedGameModes] = useState<[string, GameMode][]>([])
  const [announcedChatMessages, setAnnouncedChatMessages] = useState<[string, GameMode][]>([])
  const [playerOnTurn, setPlayerOnTurn] = useState<string>(null)
  const [messageCache, setMessageCache] = useState<string>('')

  const [clickToPlayCard] = useState<boolean>(getBoolOption(PlayerOptions.clickToPlayCard))

  const [knockingPlayerId, setKnockingPlayerId] = useState<string>()

  const getOffsetPlayerIds = () => {
    const offsetPlayerIds = []
    const idsSortedBySeats = currentTable.playerIds.sort(
      (idA, idB) => currentTable.seatMap[idA] - currentTable.seatMap[idB],
    )
    const ownSeatIndex = currentTable.seatMap[ownPlayerId]
    let currentIdx = ownSeatIndex
    for (let i = 0; i < currentTable.playerIds.length; i++) {
      offsetPlayerIds.push(idsSortedBySeats[currentIdx++])
      if (currentIdx + 1 > currentTable.playerIds.length) {
        currentIdx = 0
      }
    }

    return offsetPlayerIds
  }

  const cegoClient = props.cegoClient
  const ownPlayerId = props.ownPlayerId
  const currentTable = props.currentTable
  const offsetPlayerIds = getOffsetPlayerIds()

  const handleTableChatMessage = (senderId, message) => {
    const announcedMessages = [...announcedChatMessages.filter((msg) => msg[0] !== senderId)]
    announcedMessages.push([senderId, message])
    setAnnouncedChatMessages(announcedMessages)

    const elementId = `chat-speech-bubble-player-${senderId}`
    document.getElementById(elementId).classList.remove('fade-out')
    document.getElementById(elementId).classList.remove('hidden')
    setTimeout(() => {
      document.getElementById(elementId).classList.add('fade-out')
    }, 5000)
  }

  useEffect(() => {
    const newAnnouncement = announcedGameModes[announcedGameModes.length - 1]
    if (newAnnouncement) {
      const elementId = `speech-bubble-player-${newAnnouncement[0]}`
      const classList = document.getElementById(elementId).classList
      classList?.remove('fade-out')
      classList?.remove('hidden')
      setTimeout(() => {
        classList?.add('fade-out')
      }, 2000)
    }
  }, [announcedGameModes])

  useEffect(() => {
    if (isNullOrEmpty(knockingPlayerId) === false) {
      setTimeout(() => {
        setKnockingPlayerId(null)
      }, 10000)
    }
  }, [knockingPlayerId])

  const addLogEntries = (entries: string[]) => {
    const date = new Date()
    const logs = [...logEntries]
    for (const entry of entries) {
      logs.push(`[ ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ] ${entry}`)
    }
    setLogEntries(logs)
    setCurrentGameLogEntries(logs)
  }

  const playerNameById = (id) => {
    return props.lobbyPlayers[id].name
  }

  useEffect(() => {
    cegoClient.onGameModeSelectionPrompt = (validModes: GameMode[]) => {
      setValidGameModes(validModes)
    }

    cegoClient.onPlayerSelectedGameMode = (gameMode: GameMode, playerId: string) => {
      addLogEntries([`${playerNameById(playerId)} wählt ${GameModeHelper.gameModeToString(gameMode)}`])
      const gameModes = [...announcedGameModes]
      gameModes.push([playerId, gameMode])
      setAnnouncedGameModes(gameModes)
      if (gameMode !== GameMode.Fort) {
        setTmpGameMode(gameMode)
      }
    }

    cegoClient.onPlayerHasTurn = (playerId: string) => {
      gameEventQueue.push(() => {
        setPlayerOnTurn(playerId)
      })
    }

    cegoClient.onCardsToInstantlyPlayPrompt = (
      amount: number,
      validCards: CardData[],
      _handCards,
      _instantPlayCards,
      sameColor: Boolean,
    ) => {
      gameEventQueue.push(() => {
        setChangeMode(sameColor ? CHANGEMODE.gift : CHANGEMODE.giftWithSameColor)
        setChangeAmount(amount)
        setValidCards(validCards)
      })
    }

    cegoClient.onCardsPushToBlindPrompt = (amount: number, validCards: CardData[]) => {
      gameEventQueue.push(() => {
        setChangeMode(CHANGEMODE.keep)
        setChangeAmount(amount)
        setValidCards(validCards)
      })
    }

    cegoClient.onCardsToPushAwayPrompt = (amount: number, validCards: CardData[]) => {
      gameEventQueue.push(() => {
        setChangeMode(CHANGEMODE.push)
        setChangeAmount(amount)
        setValidCards(validCards)
      })
    }

    cegoClient.onCardToChangeColorPrompt = (validCards: CardData[]) => {
      gameEventQueue.push(() => {
        setChangeMode(CHANGEMODE.swap)
        setChangeAmount(1)
        setValidCards(validCards)
      })
    }

    cegoClient.onCardsReceived = (cards: CardData[]) => {
      gameEventQueue.push(() => {
        setPlayerHand(getSortedCards(cards.concat(giftCards)))
        setOpponentHands((hands) => {
          //TODO: move this somewhere else?
          for (let playerId of offsetPlayerIds.slice(1)) {
            hands[playerId] = Array.from(Array(11)).map((_, index) => new CardData(null, index, null, null, null))
          }
          return { ...hands }
        })
      })
    }

    cegoClient.onCardSelectionPrompt = (validCards: CardData[]) => {
      gameEventQueue.push(() => {
        setValidCards(validCards)
        setChangeMode(CHANGEMODE.none)
        setChangeCards([])
        setChangeAmount(0)
      })
    }

    cegoClient.onCardPlayed = (playerId: string, card: CardData) => {
      const cardName = card.name.charAt(0).toUpperCase() + card.name.slice(1)
      addLogEntries([`${playerNameById(playerId)} spielt '${cardName}'`])
      gameEventQueue.push(() => {
        if (playerId === ownPlayerId) return
        let randomNumber = Math.random()
        setOpponentHands((hands) => {
          if (hands[playerId]) {
            let randomIndex = Math.floor(randomNumber * hands[playerId].length)
            hands[playerId][randomIndex] = card
          }
          return { ...hands }
        })
      })
    }

    cegoClient.onBidRoundStarted = (firstPlayerId: string) => {
      setFirstPlayerId(null)
      setCurrentGameMode(GameMode.None)
      setCurrentGameLogEntries([])
      setCurrentWinData([])
      setLastChallengerId(null)
    }

    cegoClient.onBidRoundFinished = (firstPlayerId: string, gameMode: GameMode) => {
      addLogEntries([
        `${playerNameById(firstPlayerId)} gewinnt das Bieten mit '${GameModeHelper.gameModeToString(gameMode)}'`,
        `${playerNameById(firstPlayerId)} beginnt das Spiel`,
      ])
      setFirstPlayerId(firstPlayerId)
      setLastChallengerId(firstPlayerId)
      setCurrentGameMode(gameMode)
      setTmpGameMode(GameMode.None)
    }

    cegoClient.onRoundFinished = (winnerId: string, pointsGained: number, cards: CardData[]) => {
      const points = cards.reduce((a, b) => a + b.points, 0)
      addLogEntries([`${playerNameById(winnerId)} gewinnt den Stich mit ${points} Punkten`])
      const data = [...currentWinData]
      data.push([winnerId, points])
      setCurrentWinData(data)
      gameEventQueue.push(() => {
        setRoundWinnerId(winnerId)
        if (roundNumber + 1 === 2) {
          setLegerCards([])
        }
        setRoundNumber(roundNumber + 1)
        setLastStick(cards)
      })
    }

    cegoClient.onGameFinished = (winData: WinData[]) => {
      gameEventQueue.push(() => {
        setPlayerOnTurn(null)
        setWinData(winData)
        setValidCards([])
        setLastStick([])
        setLegerCards([])
        setGiftCards([])
        setChangeCards([])
        setOpponentHands({})
        setTableCards([])
        setChangeMode(CHANGEMODE.none)
        setValidGameModes([])
        setPlayerHand([])
        setRoundWinnerId(null)
        setRoundNumber(0)
        setChangeAmount(0)
        setAnnouncedGameModes([])
        setShowErrorReportDialog(false)
        setSortMode(0)
        props.setReadyPlayerIds([])
        props.setGameStarted(false)
      })
    }

    cegoClient.onCardsPutToLeger = (cards: CardData[]) => {
      gameEventQueue.push(() => {
        setLegerCards((leger) => {
          const currentLeger = [...leger]
          cards.forEach((card) => {
            currentLeger.push(card)
          })
          return currentLeger
        })
      })
    }

    cegoClient.onPlayerKnocked = (playerId: string) => {
      gameEventQueue.push(() => {
        console.log(props.lobbyPlayers[playerId]?.name + ' knocked')
        setKnockingPlayerId(playerId)
      })
    }

    return () => {
      // TODO: add missing events
      cegoClient.onGameFinished = undefined
      cegoClient.onGameModeSelectionPrompt = undefined
      cegoClient.onCardSelectionPrompt = undefined
      cegoClient.onCardsReceived = undefined
      cegoClient.onCardPlayed = undefined
      cegoClient.onRoundFinished = undefined
      cegoClient.onCardsPutToLeger = undefined
      cegoClient.onPlayerKnocked = undefined
    }
  })

  useEffect(() => {
    document.addEventListener('selectcardtochange', onSelectCardToChange)
    document.addEventListener('cardpushed', onCardPushed)
    document.addEventListener('cardselected', onCardSelected)
    document.addEventListener('cardplayed', onCardPlayed)
    return () => {
      document.onfullscreenchange = undefined
      document.removeEventListener('selectcardtochange', onSelectCardToChange)
      document.removeEventListener('cardpushed', onCardPushed)
      document.removeEventListener('cardselected', onCardSelected)
      document.removeEventListener('cardplayed', onCardPlayed)
    }
  })

  function selectGameMode(gameMode: GameMode) {
    setPlayerOnTurn(null)
    cegoClient.notifyGameModeSelected(gameMode, currentTable.id, ownPlayerId)
    setValidGameModes([])
  }

  function selectCardsToKeep() {
    if (changeCards.length !== changeAmount) return
    cegoClient.notifyCardsToChangeSelected(changeCards, currentTable.id, ownPlayerId)
    setValidCards([])
    setChangeCards([])
    let cardsToPush = playerHand.filter((card) => !changeCards.includes(card) && !giftCards.includes(card))
    gameEventQueue.push(() => {
      for (let card of cardsToPush) {
        pushCard(card)
      }
    })
  }

  function selectCardsToPush() {
    cegoClient.notifyCardsToChangeSelected(changeCards, currentTable.id, ownPlayerId)
    setValidCards([])
    setChangeCards([])
    gameEventQueue.push(() => {
      for (let card of changeCards) {
        pushCard(card)
      }
    })
  }

  function selectCardsToGift() {
    cegoClient.notifyCardsToChangeSelected(changeCards, currentTable.id, ownPlayerId)
    setValidCards([])
    setChangeCards([])
    setGiftCards([...changeCards])
  }

  function selectCardToSwap() {
    cegoClient.notifyCardsToChangeSelected(changeCards, currentTable.id, ownPlayerId)
    setValidCards([])
    setChangeCards([])
    setChangeMode(CHANGEMODE.none)
    if (changeCards.length > 0) setGiftCards([...changeCards])
  }

  function onSelectCardToChange(event: CustomEvent<CardData>) {
    setPlayerOnTurn(null)
    let cardToChange = event.detail
    setChangeCards((cards) => {
      if (cards.includes(cardToChange)) {
        return cards.filter((card) => card !== cardToChange)
      } else {
        if (cards.length < changeAmount) return [...cards, cardToChange]
        else return cards
      }
    })
  }

  function getSortedCards(cards): CardData[] {
    let sortedCards = []
    cards
      .filter((card) => card.color === CardColor.Herz)
      .sort((a, b) => a.value - b.value)
      .forEach((card) => sortedCards.push(card))
    cards
      .filter((card) => card.color === CardColor.Kreuz)
      .sort((a, b) => a.value - b.value)
      .forEach((card) => sortedCards.push(card))
    cards
      .filter((card) => card.color === CardColor.Pik)
      .sort((a, b) => a.value - b.value)
      .forEach((card) => sortedCards.push(card))
    cards
      .filter((card) => card.color === CardColor.Karo)
      .sort((a, b) => a.value - b.value)
      .forEach((card) => sortedCards.push(card))
    cards
      .filter((card) => card.color === CardColor.Trumpf)
      .sort((a, b) => a.value - b.value)
      .forEach((card) => sortedCards.push(card))
    setSortMode(sortMode === 0 ? 1 : 0)
    return sortMode === 0 ? sortedCards : sortedCards.reverse()
  }

  function pushCard(card: CardData) {
    document.dispatchEvent(
      new CustomEvent<CardData>('pushcard', {
        detail: card,
      }),
    )
  }

  function onCardPushed(event: CustomEvent<CardData>) {
    setPlayerHand((hand) => {
      hand = hand.filter((handCard) => handCard !== event.detail)
      return hand
    })
  }

  function onCardSelected(event: CustomEvent<CardData>) {
    const cardData = event.detail
    if (
      giftCards.some(
        (card) => card.color === cardData.color && card.value === cardData.value && card.type === cardData.type,
      )
    ) {
      cardData.isGift = true
    }
    cegoClient.notifyCardSelected(event.detail, currentTable.id, ownPlayerId)
    document.dispatchEvent(
      new CustomEvent<CardData>('playcard', {
        detail: event.detail,
      }),
    )
    setValidCards([])
  }

  function onCardPlayed(event: CustomEvent<{ card: CardData; playerId: string }>) {
    let card = event.detail.card
    let playerId = event.detail.playerId
    if (playerId === ownPlayerId) {
      setPlayerHand((hand) => {
        hand = hand.filter((handCard) => handCard !== card)
        return hand
      })
      if (giftCards.length > 0 && giftCards.some((giftCard) => giftCard === card)) {
        setGiftCards((giftCards) => giftCards.filter((giftCard) => giftCard !== card))
      }
    } else {
      setOpponentHands((hands) => {
        hands[playerId] = hands[playerId].filter((handCard) => handCard !== card)
        return { ...hands }
      })
    }
    setTableCards((tableCards) => {
      return [
        ...tableCards,
        {
          playerIndex: offsetPlayerIds.findIndex((offsetPlayerId) => offsetPlayerId === playerId),
          card: card,
        },
      ]
    })
  }

  async function requestFullscreen() {
    if (document.fullscreenElement) await document.exitFullscreen()
    else await document.documentElement.requestFullscreen({ navigationUI: 'hide' })
  }

  if (!props.gameStarted) {
    return (
      <TableLobby
        cegoClient={cegoClient}
        ownPlayerId={ownPlayerId}
        lobbyPlayers={props.lobbyPlayers}
        currentTable={currentTable}
        readyPlayerIds={props.readyPlayerIds}
        leaveTable={props.leaveTable}
        isHosting={props.isHosting}
        chatMessages={props.chatMessages}
        winData={winData}
        logEntries={logEntries}
        playerId={ownPlayerId}
        tablePlayerIds={props.currentTable.playerIds}
        tableId={props.currentTable.id}
        gameMode={currentGameMode}
        currentTab={selectedCornerTab}
        setCurrentTab={setSelectedCornerTab}
        lastChallengerId={lastChallengerId}
        tmpGameMode={tmpGameMode}
        showAlert={props.showAlert}
        alertText={props.alertText}
        setShowAlert={props.setShowAlert}
        messageCache={messageCache}
        setMessageCache={setMessageCache}
        currentWinData={currentWinData}
        firstPlayerId={firstPlayerId}
      ></TableLobby>
    )
  }

  const getGameModeString = (playerData: PlayerData) => {
    const playerGameModes = announcedGameModes.filter((announcedGameMode) => announcedGameMode[0] === playerData.id)
    return GameModeHelper.gameModeToString(playerGameModes[playerGameModes.length - 1]?.[1]) ?? ''
  }

  let playerItems = offsetPlayerIds
    .map(
      (playerId): PlayerData =>
        props.lobbyPlayers[playerId]
          ? props.lobbyPlayers[playerId]
          : {
              id: playerId,
              mail: '',
              name: playerId,
              isAi: false,
              iconPath: '',
              sessionId: '',
              replacementId: '',
              options: [],
              tableId: null,
            },
    )
    .map((playerData, index) => (
      <div key={playerData.id} className={`table-player table-player-${index}`}>
        <i className={`table-player-${index}-background breadboard`}></i>
        <StyledTablePlayerPicture imgSrc={playerData.iconPath}></StyledTablePlayerPicture>
        {playerOnTurn === playerData.id && <i className="table-player-turn-circle"></i>}
        <i className={`table-player-name table-player-name-${index}`}>{playerData.name}</i>
        {announcedGameModes.find((announcedGameMode) => announcedGameMode[0] === playerData.id)?.[1] !==
          GameMode.None && (
          <i
            id={`speech-bubble-player-${playerData.id}`}
            className={`speech-bubble speech-bubble-${index} bubble-bottom-left-${index} hidden`}
          >
            {getGameModeString(playerData)}
          </i>
        )}
        {
          <i
            id={`chat-speech-bubble-player-${playerData.id}`}
            className={`speech-bubble speech-bubble-${index} bubble-bottom-left-${index} hidden`}
          >
            {announcedChatMessages.find((msg) => msg[0] === playerData.id)?.[1]}
          </i>
        }
      </div>
    ))

  return (
    <div
      className="table"
      onAnimationEnd={(event) => {
        if (event.animationName.includes('win')) {
          setTableCards([])
          setRoundWinnerId(null)
        }
      }}
    >
      <div>
        <div className="button-leave" onClick={props.leaveTable}>
          <img className="button-nav" alt="Tisch verlassen" title="Tisch verlassen" src={buttonLeave} />
        </div>
        {window.innerWidth > 800 && (
          <div className="button-fullscreen" onClick={requestFullscreen}>
            <img className="button-nav" src={buttonFullscreen} alt="Vollbild" title="Vollbild" />
          </div>
        )}
      </div>
      <div className="button-sort">
        <img
          className="button-nav"
          src={buttonSort}
          onClick={() => setPlayerHand(getSortedCards(playerHand))}
          alt="Sortieren"
          title="Sortieren"
          style={{ visibility: changeMode === CHANGEMODE.none ? 'visible' : 'hidden' }}
        />
      </div>
      <div className="button-error-report">
        <img
          className="button-nav"
          src={buttonErrorReport}
          onClick={() => {
            setShowErrorReportDialog(true)
          }}
          alt="Fehler melden"
          title="Fehler melden"
        />
      </div>
      {playerItems}
      <div className="cards-table">
        <CardsTable
          tableCards={tableCards}
          playerWon={roundWinnerId ? ownPlayerId === roundWinnerId : null}
        ></CardsTable>
      </div>
      <div className="mode-selection">
        <ModeList modes={validGameModes} selectGameMode={selectGameMode} />
      </div>
      {offsetPlayerIds.slice(1).map((playerId, index) => (
        <HandOpponent
          key={playerId}
          playerId={playerId}
          playerIndex={index + 1}
          cards={opponentHands[playerId]}
        ></HandOpponent>
      ))}
      <div className="card rueckseite card-stack-player" hidden={false} onClick={() => setShowLastTrick(true)}></div>
      <div className="card rueckseite card-stack-opponents" hidden={false} onClick={() => setShowLastTrick(true)}></div>
      <CornerWindow
        cegoClient={cegoClient}
        logEntries={logEntries}
        playerId={ownPlayerId}
        lobbyPlayers={props.lobbyPlayers}
        tablePlayerIds={props.currentTable.playerIds}
        tableId={props.currentTable.id}
        gameMode={currentGameMode}
        chatMessages={props.chatMessages}
        currentTab={selectedCornerTab}
        setCurrentTab={setSelectedCornerTab}
        firstPlayerId={firstPlayerId}
        tmpGameMode={tmpGameMode}
        onTableChatMessage={handleTableChatMessage}
        messageCache={messageCache}
        setMessageCache={setMessageCache}
        currentWinData={currentWinData}
      />
      <ModalDialog
        hasCancelButton={false}
        showModal={props.showAlert}
        setShowModal={props.setShowAlert}
        header={<>Benachrichtigung</>}
      >
        <p>{props.alertText}</p>
      </ModalDialog>
      <HandPlayer
        playerId={ownPlayerId}
        cards={playerHand}
        giftCards={giftCards}
        validCards={validCards}
        changeCards={changeCards}
        changeMode={changeMode}
        sortMode={sortMode}
        clickToPlay={clickToPlayCard}
      />

      <KnockAlert
        playerName={props.lobbyPlayers[knockingPlayerId]?.name}
        playerIconPath={props.lobbyPlayers[knockingPlayerId]?.iconPath}
      />

      <ModalBackground style={{ zIndex: 0 }} hidden={changeMode === CHANGEMODE.none}>
        <button
          className="button-wide card-change-mode-select-button"
          disabled={
            changeMode === CHANGEMODE.giftWithSameColor
              ? changeCards.length < 2
              : changeMode !== CHANGEMODE.swap && changeCards.length === 0
          }
          onClick={
            {
              [CHANGEMODE.keep]: selectCardsToKeep,
              [CHANGEMODE.push]: selectCardsToPush,
              [CHANGEMODE.gift]: selectCardsToGift,
              [CHANGEMODE.giftWithSameColor]: selectCardsToGift,
              [CHANGEMODE.swap]: selectCardToSwap,
            }[changeMode]
          }
        >
          Bestätigen
        </button>
        <div className="card-change-mode-prompt">
          {
            {
              [CHANGEMODE.keep]: `Wähle ${changeAmount} Karte${changeAmount > 1 ? 'n' : ''} zum Mitnehmen`,
              [CHANGEMODE.push]: `Wähle ${changeAmount} Karte${changeAmount > 1 ? 'n' : ''} zum Drücken`,
              [CHANGEMODE.gift]: `Wähle ${changeAmount} Karte${changeAmount > 1 ? 'n' : ''} zum Schenken`,
              [CHANGEMODE.giftWithSameColor]: `Wähle ${changeAmount} Karte${
                changeAmount > 1 ? 'n' : ''
              } mit unterschiedlicher Farbe zum Schenken`,
              [CHANGEMODE.swap]: `Wähle ${changeAmount} Karte${
                changeAmount > 1 ? 'n' : ''
              } zum Tauschen mit der Geschenkkarte`,
            }[changeMode]
          }
        </div>
      </ModalBackground>
      <ModalDialog
        showModal={showLastTrick}
        setShowModal={setShowLastTrick}
        hasCancelButton={false}
        header={'Stapel'}
        footer={''}
        wide
      >
        <CardDeckDialogContent leger={legerCards} lastStick={lastStick} />
      </ModalDialog>
      <ModalDialog
        hasCancelButton={false}
        showModal={showErrorReportDialog}
        setShowModal={setShowErrorReportDialog}
        header={<>Fehler melden</>}
      >
        <ErrorReportDialogContent
          cegoClient={props.cegoClient}
          playerId={props.ownPlayerId}
          tableId={currentTable.id}
          logEntries={currentGameLogEntries}
        />
      </ModalDialog>
    </div>
  )
}

function ModeList(props: { modes: GameMode[]; selectGameMode: (gameMode: GameMode) => void }) {
  let validModes = props.modes.map((mode) => (
    <button key={mode} className="beer-mat" onClick={() => props.selectGameMode(mode)}>
      {GameModeHelper.gameModeToString(mode)}
    </button>
  ))

  return <>{validModes}</>
}

function HandPlayer(props: {
  playerId: string
  cards: CardData[]
  validCards: CardData[]

  changeMode: CHANGEMODE
  giftCards: CardData[]
  changeCards: CardData[]
  sortMode: number
  clickToPlay: boolean
}) {
  const isValid = (card: CardData) => {
    const changeCards = props.changeCards
    if (props.changeMode === CHANGEMODE.giftWithSameColor) {
      if (changeCards.length > 0) {
        // Game mode = ZweiVerschiedene -> cards must have different colors
        return card.color !== changeCards[0].color
      }
    }
    return props.validCards.some((validCards) => card.name === validCards.name)
  }
  const [hasActiveCard, setHasActiveCard] = useState<boolean>(false)
  let isChangeMode = props.changeMode !== CHANGEMODE.none
  let cardsLength = props.cards?.length
  let giftCardIndexes: number[] = []
  for (let giftCard of props.giftCards) {
    giftCardIndexes.push(props.cards?.findIndex((card) => card.name === giftCard.name))
    cardsLength--
  }
  let rowLength = cardsLength >= 11 ? 6 : 5

  let cards = props.cards.map((card, cardIndex) => {
    for (let giftCardIndex of giftCardIndexes) {
      if (cardIndex > giftCardIndex) cardIndex--
    }
    let isFirstRow = cardIndex < rowLength

    return (
      <CardPlayer
        key={card.name}
        playerId={props.playerId}
        card={card}
        index={isChangeMode ? (isFirstRow ? cardIndex : cardIndex % rowLength) : cardIndex}
        handLength={isChangeMode ? (isFirstRow ? rowLength : cardsLength - rowLength) : cardsLength}
        isValid={isValid(card)}
        hasActiveCard={hasActiveCard}
        setHasActiveCard={setHasActiveCard}
        isChangeMode={isChangeMode}
        isGift={props.giftCards.some((giftCards) => card.name === giftCards.name)}
        isSelected={props.changeCards.includes(card)}
        changeModeRow={isFirstRow ? 0 : 1}
        clickToPlay={props.clickToPlay}
      ></CardPlayer>
    )
  })

  return <div className={'cards-player-0'}>{cards}</div>
}

function HandOpponent(props: { playerId: string; cards: CardData[]; playerIndex: number }) {
  let cards = props.cards?.map((card, index) => {
    return (
      <CardOpponent
        key={card.name ? card.name : card.value}
        playerId={props.playerId}
        card={card.name ? card : null}
        cardIndex={index}
        numberOfCards={props.cards.length}
        playerIndex={props.playerIndex}
      ></CardOpponent>
    )
  })

  return <div className={`cards-player-${props.playerIndex}`}>{cards}</div>
}

function CardsTable(props: { tableCards: { playerIndex: number; card: CardData }[]; playerWon: boolean }) {
  let cards = props.tableCards.map((indexAndCard) => {
    let card = indexAndCard.card
    return (
      <CardTable
        key={card.name}
        card={card}
        cardIndex={indexAndCard.playerIndex}
        playerWon={props.playerWon}
      ></CardTable>
    )
  })

  return <>{cards}</>
}
