import { GameMode } from '@hs-furtwangen/socket-lib'

export const between = (num: number, min: number, max: number): boolean => {
  return num >= min && num <= max
}

export const getPointsMultiplier = (points: number): [string, number] => {
  if (between(points, 0, 0)) {
    return ['Berg', 40]
  }
  if (between(points, 1, 9)) {
    return ['7-Fach', 35]
  }
  if (between(points, 10, 14)) {
    return ['6-Fach', 30]
  }
  if (between(points, 15, 19)) {
    return ['5-Fach', 25]
  }
  if (between(points, 20, 24)) {
    return ['4-Fach', 20]
  }
  if (between(points, 25, 29)) {
    return ['3-Fach', 15]
  }
  if (between(points, 30, 34)) {
    return ['2-Fach', 10]
  }
  if (between(points, 35, 39)) {
    return ['1-Fach', 5]
  }
  if (between(points, 40, 44)) {
    return ['1-Fach', 5]
  }
  if (between(points, 45, 49)) {
    return ['2-Fach', 10]
  }
  if (between(points, 50, 54)) {
    return ['3-Fach', 15]
  }
  if (between(points, 55, 59)) {
    return ['4-Fach', 20]
  }
  if (between(points, 60, 64)) {
    return ['5-Fach', 25]
  }
  if (between(points, 65, 69)) {
    return ['6-Fach', 30]
  }
  if (between(points, 70, 74)) {
    return ['7-Fach', 35]
  }
  if (between(points, 75, 79)) {
    return ['8-Fach', 40]
  }

  return ['-', 0]
}

export const gameModeIsSondergame = (gameMode: GameMode): boolean => {
  return gameMode === GameMode.Piccolo || gameMode === GameMode.Bettel || gameMode === GameMode.Ultimo
}
