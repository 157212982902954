/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent } from 'react'

export const LegacyRoute: FunctionComponent<any> = ({ children }) => {
  React.useEffect(() => {
    // if (localStorage.getItem(STROAGE_KEYS.token)?.length > 0) {
    //   return navigate(ROUTES.home)
    // }
  }, [])

  return (
    <>
      <meta name="viewport" content="height=device-height, initial-scale=0.45"></meta>
      {children}
    </>
  )
}
