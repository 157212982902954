/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import {
  LegacyButton,
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyHalfPaperContainer,
  LegacyHalfPaperStrip,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySmallPaperContainer,
  LegacySchindeln,
  LegacySiteContentContainer,
  LegacyLargePaperContainer,
} from '../legacyStyle'
import { ContentPaper } from '../ContentPaper/ContentPaper'
import { ROUTES } from '../../../lib/AppConfig'
import { LegacyBoldInlineSection } from '../ContentPaper/style'

export const Wissen = () => {
  return (
    <>
      <LegacyMainContainer>
        <LegacySchindeln />
        <LegacyContentContainer height="1700px">
          <LegacyNavContainer>
            <Link to={ROUTES.home}>
              <LegacyNavItem>Cego</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lobby}>
              <LegacyNavItem>spielen</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lernen}>
              <LegacyNavItem>lernen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.lernen}>
              <LegacyNavItem selected>wissen</LegacyNavItem>
            </Link>
          </LegacyNavContainer>
          <LegacySiteContentContainer>
            <LegacyLargePaperContainer>
              <ContentPaper
                title="Die Spielkarten"
                children={
                  <>
                    <p>
                      Cego wird mit Tarockkarten gespielt. Tarock entstand wahrscheinlich zwischen 1430 und 1440 im
                      heutigen Italien. Besonderes Kennzeichen der Tarockkarten ist neben den Zahlen- und Bildkarten mit
                      Bube, Reiter, Dame und König in den Farben Herz, Schaufel (Pik), Caro und Kreuz, die sogenannte
                      große Arcana, die aus 22 speziellen Karten besteht.
                    </p>
                    <br />
                    <p>
                      Diese Karten sind individuell mit meist urtümlichen Motiven verziert, woraus die Verwandtschaft
                      zum Tarot erkennbar wird. Dabei kommt der letzten Karte eine besondere Bedeutung zu. Häufig ist
                      hier ein Narr oder Musikant abgebildet.
                    </p>
                    <br />
                    <p>
                      In Österreich wurden die 78 Karten des Großtarocks auf 54 Karten zum Tapp-Tarock reduziert, in dem
                      bei den roten Karten die Zahlen fünf bis zehn, bei den schwarzen dagegen die eins bis sechs
                      herausgenommen wurden.
                    </p>
                  </>
                }
              ></ContentPaper>
              <LegacyHalfPaperContainer>
                <LegacyHalfPaperStrip>
                  <ContentPaper
                    title="Ausdrücke"
                    isSingle
                    children={
                      <>
                        <p>
                          <LegacyBoldInlineSection>Gstieß</LegacyBoldInlineSection>, auch Stieß oder Skis (von franz.:
                          'excuse'), Gigema (Geigenmann): die höchste Trumpfkarte und in der Regel nicht mit einer
                          Nummer versehen.
                        </p>
                        <br />
                        <p>
                          <LegacyBoldInlineSection>Truck</LegacyBoldInlineSection>: Bezeichnung für Trumpfkarten aus dem
                          Tarock.
                        </p>
                        <br />
                        <p>
                          <LegacyBoldInlineSection>Mond</LegacyBoldInlineSection>, auch Mund: die zweithöchste
                          Trumpfkarte (Nr. 21). Alte Tarockblätter bildeten hier die Weltkugel ab (span.: 'el mundo',
                          franz.: 'le monde').
                        </p>
                        <br />
                        <p>
                          <LegacyBoldInlineSection>Geiß</LegacyBoldInlineSection>, auch Bapperle, Pagat (von ital.:
                          Bagattello) oder kleiner Mann: der niedrigste Trumpf, mit einer 1 gekennzeichnet.
                        </p>
                        <br />
                        <p>
                          <LegacyBoldInlineSection>Legage</LegacyBoldInlineSection>: die Karten, welche man nach dem
                          Tausch mit den Blinden ablegt.
                        </p>
                        <br />
                        <p>
                          <LegacyBoldInlineSection>Blinde</LegacyBoldInlineSection>: die 10 Karten, welche zu
                          Spielbeginn in die Tischmitte gelegt werden. Sie geben dem Spiel den Namen.
                        </p>
                        <br />
                        <p>
                          <LegacyBoldInlineSection>Leere</LegacyBoldInlineSection>, auch Lusche: eine Karte ohne Bild.
                        </p>
                      </>
                    }
                  ></ContentPaper>
                </LegacyHalfPaperStrip>
                <LegacyHalfPaperStrip>
                  <ContentPaper
                    title="Verbreitung"
                    rotation={-2}
                    isSingle
                    children={
                      <>
                        Zurück in der Heimat verbreitete sich das Spiel der badischen Soldaten zunächst vordringlich
                        unter Beamten und wohl auch dem Klerus. Das erklärt, warum sich die Verbreitung als beliebtestes
                        Spiel 1932 so scharf auf das Gebiet Baden/Hohenzollern abbildete, welches noch heute das
                        Erzbistum Freiburg darstellt.
                      </>
                    }
                  ></ContentPaper>
                  <ContentPaper
                    title="Quellen"
                    rotation={2}
                    isSingle
                    children={
                      <>
                        <a href={'http://laberlaberlaber.de/cego/geschichte.htm'} target="_blank" rel="noreferrer">
                          <LegacyButton>Achim Labers Cego-Seite</LegacyButton>
                        </a>
                        <br />
                        <br />
                        <a href={'https://de.wikipedia.org/wiki/L%E2%80%99Hombre'} target="_blank" rel="noreferrer">
                          <LegacyButton>L'Hombre Wikipedia</LegacyButton>
                        </a>
                      </>
                    }
                  ></ContentPaper>
                </LegacyHalfPaperStrip>
              </LegacyHalfPaperContainer>
            </LegacyLargePaperContainer>
            <LegacySmallPaperContainer>
              <ContentPaper
                title="Vermutliche Herkunft"
                isSingle
                rotation={2}
                children={
                  <>
                    <br />
                    1805 fielen große Teile Vorderösterreichs an Baden. Die vormals Österreichischen Soldaten zogen dann
                    1808-1813 für das Großherzogtum Baden mit ihren Tarockkarten und Napoleons Truppen gegen Spanien in
                    den Krieg. Dort lernten Sie ein Spiel namens L'Hombre bzw. Varianten davon kennen. Dieses Spiel
                    kennt bereits das Reizen und das Spiel mit unbekannten Karten in der Tischmitte, den Blinden. Die
                    Neu-Badener adaptierten das Spiel für ihre Tarockkarten und entwickelten so ein Neues, welches sie
                    nach dem spanischen Wort für „blind” Cego bzw. Zigo nannten.
                  </>
                }
              ></ContentPaper>
              <ContentPaper
                title="Regionalität"
                isSingle
                children={
                  <>
                    <p>
                      Cego ist ein „gewachsenes” Spiel, die Regeln wurden lange nicht überregional und verbindlich
                      festgeschrieben. So kommt es, dass es in den Wirtshäusern benachbarter Dörfer bereits
                      unterschiedlich gespielt wird und man sich bei größeren Turnieren zunächst auf die Regeln einigen
                      muss.
                    </p>
                    <p>
                      Seit einigen Jahren wird die Cego-Schwarzwaldmeisterschaft ausgetragen, ein großes Turnier welches
                      über Monate an verschiedenen Spielorten ausgetragen wird. Hierfür wurden die verbindlichen Regeln
                      definiert, welche Cego-Online zugrunde liegen.
                    </p>
                  </>
                }
              ></ContentPaper>
            </LegacySmallPaperContainer>
          </LegacySiteContentContainer>
          <LegacyFooterContainer>
            <Link to={ROUTES.impressum}>
              <LegacyFooterItem>Impressum</LegacyFooterItem>
            </Link>
          </LegacyFooterContainer>
        </LegacyContentContainer>
      </LegacyMainContainer>
    </>
  )
}
