import { useEffect, useState } from 'react'
import { ROUTES } from '../../lib/AppConfig'
import { isNullOrEmpty } from '../../Util/IsNullOrEmpty'
import { StyledActivateForm } from '../Register/style'
import useForm from '../UseForm'
import { LegacyMainContainer, LegacySchindeln } from '../LegacyPages/legacyStyle'
import {
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyFullPaperContainer,
  LegacyHalfPaperContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySiteContentContainer,
} from '../LegacyPages/legacyStyle'
import { ContentPaper } from '../LegacyPages/ContentPaper/ContentPaper'
import { Link } from 'react-router-dom'

export const PasswordReset = (props: { backendEndpoint: string }) => {
  const initialInputState = { mail: '', password: '', passwordRepeat: '' }
  const [mailOk, setMailOk] = useState(false)
  const [userNotFound, setUserNotFound] = useState(false)
  const { handleInputChange, handleSubmit, inputs } = useForm(resetCallback, initialInputState)
  const [code, setCode] = useState('')

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    console.log(urlParams.get('code'))
    setCode(urlParams.get('code'))
  }, [code])

  const requestOptions = (jsonBody) => {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody),
    }
  }

  async function resetCallback() {
    const response = await fetch(
      `${props.backendEndpoint}/resetPassword`,
      requestOptions({
        mail: inputs.mail,
      }),
    )
    if (response.ok) {
      setMailOk(true)
      setUserNotFound(false)
    } else {
      setUserNotFound(true)
    }
  }

  return (
    <LegacyMainContainer>
      <LegacySchindeln />
      <LegacyContentContainer height="1650px">
        <LegacyNavContainer>
          <Link to={ROUTES.home}>
            <LegacyNavItem>Cego</LegacyNavItem>
          </Link>
          <Link to={ROUTES.lobby}>
            <LegacyNavItem selected>spielen</LegacyNavItem>
          </Link>
          <Link to={ROUTES.lernen}>
            <LegacyNavItem>lernen</LegacyNavItem>
          </Link>
          <Link to={ROUTES.wissen}>
            <LegacyNavItem>wissen</LegacyNavItem>
          </Link>
        </LegacyNavContainer>
        <LegacySiteContentContainer>
          <LegacyFullPaperContainer>
            <LegacyHalfPaperContainer>
              <ContentPaper
                title="Passwort zurücksetzen"
                children={
                  <>
                    {isNullOrEmpty(code) === false && (
                      <>
                        <h3>Neues Passwort vergeben</h3>
                        <StyledActivateForm onSubmit={handleSubmit}>
                          <br />
                          <input
                            type="password"
                            name="password"
                            placeholder="Passwort"
                            onChange={handleInputChange}
                            required
                          ></input>
                          <input
                            type="password"
                            name="passwordRepeat"
                            placeholder="Passwort wiederholen"
                            onChange={handleInputChange}
                            required
                          ></input>
                          <button className="button-wide" type="submit">
                            Reaktivieren
                          </button>
                        </StyledActivateForm>
                      </>
                    )}
                    {isNullOrEmpty(code) && mailOk === false && (
                      <>
                        <StyledActivateForm onSubmit={handleSubmit}>
                          <br />
                          Bitte E-Mail Adresse angeben:
                          <input
                            type="text"
                            name="mail"
                            placeholder="E-Mail"
                            onChange={handleInputChange}
                            required
                            style={{ marginTop: '15px' }}
                          ></input>
                          <button className="button-wide" type="submit">
                            Passwort zurücksetzen
                          </button>
                          {userNotFound && (
                            <>
                              <br />
                              <p>Fehler: Für die angegebene E-Mail Adresse existiert kein Benutzer.</p>
                            </>
                          )}
                        </StyledActivateForm>
                      </>
                    )}
                    {mailOk && (
                      <>
                        <br />
                        <p>An die angegebene E-Mail wurde ein Link zum zurücksetzen des Passworts gesendet!</p>
                        <br />
                        <p>
                          Um das Passwort zurückzusetzen muss der Link in der E-Mail aufgerufen werden. Im Anschluss
                          kann ein neues Passwort vergeben werden.
                        </p>
                      </>
                    )}
                  </>
                }
              ></ContentPaper>
            </LegacyHalfPaperContainer>
          </LegacyFullPaperContainer>
        </LegacySiteContentContainer>
        <LegacyFooterContainer>
          <Link to={ROUTES.impressum}>
            <LegacyFooterItem>Impressum</LegacyFooterItem>
          </Link>
        </LegacyFooterContainer>
      </LegacyContentContainer>
    </LegacyMainContainer>
  )
}
