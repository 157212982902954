import styled from 'styled-components'

export const StyledTableName = styled.h2`
  height: 5%;
  @media (max-width: 600px) {
    font-size: xx-large;
    width: 80%;
  }
`

export const StyledTableHeader = styled.h2``

export const StyledTableLobby = styled.div`
  height: 100%;
  width: 100%;
  background-color: tan;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const StyledWinnerAnnouncement = styled.div`
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  font-size: 19px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  span {
    color: red;
    font-size: 20px;
  }
`

export const StyledPointsCalculation = styled.div`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: italic;
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  span {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  b {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const StyledTablePlayers = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-height: 850px) {
    height: 10%;
  }
`

export const StyledTablePlayer = styled.div`
  min-width: 200px;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
  span {
    min-width: 200px;
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: #015bff;
  }

  @media (max-height: 850px) {
    font-size: 15px;
    min-width: auto;

    span {
      min-width: auto;
      font-size: 15px;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-weight: bold;
      color: #015bff;
    }
  }
`
export const StyledTablePointsItem = styled.div`
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`
