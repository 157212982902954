import styled from 'styled-components'

export const StyledCornerWindow = styled.div`
  transform: rotate(2deg);
  position: absolute;
  left: -10px;
  bottom: -8px;
  width: 24vw;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: white;
  background-image: url('static/media/pin.aa8c25c6ca2bd545b66b.png'), url('static/media/pin.aa8c25c6ca2bd545b66b.png'),
    url('static/media/paper_texture.91299819ee4228e3e91a.svg');
  background-size: 25px, 25px, auto;
  background-repeat: no-repeat;
  background-position: top 10px left 10px, top 10px right 10px, center;
  padding: 20px;

  @media (max-width: 1000px) {
    display: none;
  }
`

export const TabContainer = styled.div`
  height: 60px;
  background-color: white;
  border-radius: 10px;
  top: -50px;
  margin-left: -20px;
  position: absolute;
`

export const TabBox = styled.div`
  display: flex;
  flex-direction: row;
`

export const TabItemLeft = styled.div`
  padding: 16px;
  height: 50px;
  background-color: rgb(236, 236, 236);
  border-radius: 10px 0px 0px 0px;
  ${(props) =>
    props.tabSelected ? 'background-color: white;' : 'box-shadow: inset 17px 9px 15px -13px rgba(0, 0, 0, 0.24);'}
  &:hover {
    cursor: pointer;
  }
`

export const TabItemCenter = styled.div`
  padding: 16px;
  height: 50px;
  background-color: rgb(236, 236, 236);
  ${(props) =>
    props.tabSelected ? 'background-color: white;' : 'box-shadow: inset 17px 9px 15px -13px rgba(0, 0, 0, 0.24);'}
  &:hover {
    cursor: pointer;
  }
`

export const TabItemRight = styled.div`
  padding: 16px;
  height: 50px;
  background-color: rgb(236, 236, 236);
  border-radius: 0px 10px 0px 0px;
  ${(props) =>
    props.tabSelected ? 'background-color: white;' : 'box-shadow: inset 17px 9px 15px -13px rgba(0, 0, 0, 0.24);'}
  &:hover {
    cursor: pointer;
  }
`

export const TopSpace = styled.div`
  height: 40px;
`
