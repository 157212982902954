import styled from 'styled-components'

export const LegaceDatenschutzContent = styled.tr`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  p {
    font-size: small;
  }
  h2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    font-size: larger;
    font-weight: bold;
  }
  h3 {
    margin-top: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    font-size: medium;
    font-weight: bold;
  }
`
