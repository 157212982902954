import { GameMode } from '@hs-furtwangen/socket-lib'

export class GameModeHelper {
  static gameModeArrayToString(array: GameMode[]) {
    let output = '['
    for (let i = 0; i < array.length; i++) {
      output += this.gameModeToString(array[i]) + ','
    }
    return output + ']'
  }

  static gameModeToString(bid: GameMode): string {
    switch (bid) {
      case GameMode.Fort:
        return 'FORT'
      case GameMode.Solo:
        return 'SOLO'
      case GameMode.Cego:
        return 'CEGO'
      case GameMode.Gegensolo:
        return 'GEGENSOLO'
      case GameMode.Ultimo:
        return 'ULTIMO'
      case GameMode.Bettel:
        return 'BETTEL'
      case GameMode.Piccolo:
        return 'PICCOLO'
      case GameMode.Raeuber:
        return 'RAEUBER'
      case GameMode.Selber:
        return 'SELBER'
      case GameMode.Halbe:
        return 'HALBE'
      case GameMode.Eine:
        return 'EINE'
      case GameMode.EineLeere:
        return 'EINE LEERE'
      case GameMode.ZweiLeere:
        return 'ZWEI LEERE'
      case GameMode.ZweiVerschiedeneLeere:
        return 'ZWEI VERSCHIEDENE LEERE'
      case GameMode.KleinerMann:
        return 'KLEINER MANN'
      case GameMode.None:
        return 'None'
    }
  }
}
