/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import {
  LegacyButton,
  LegacyContentContainer,
  LegacyContentTable,
  LegacyContentTableCol,
  LegacyContentTableRow,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyHalfPaperContainer,
  LegacyHalfPaperStrip,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySmallPaperContainer,
  LegacyLargePaperContainer,
  LegacySchindeln,
  LegacySiteContentContainer,
} from '../legacyStyle'
import { ContentPaper } from '../ContentPaper/ContentPaper'
import { ROUTES } from '../../../lib/AppConfig'
import { LegacyBoldInlineSection, LegacyBoldSection } from '../ContentPaper/style'

export const Startpage = () => {
  return (
    <>
      <meta name="viewport" content="height=device-height, initial-scale=0.45"></meta>
      <LegacyMainContainer>
        <LegacySchindeln />
        <LegacyContentContainer height="1650px">
          <LegacyNavContainer>
            <LegacyNavItem selected>Cego</LegacyNavItem>

            <Link to={ROUTES.lobby}>
              <LegacyNavItem>spielen</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lernen}>
              <LegacyNavItem>lernen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.wissen}>
              <LegacyNavItem>wissen</LegacyNavItem>
            </Link>
          </LegacyNavContainer>
          <LegacySiteContentContainer>
            <LegacyLargePaperContainer>
              <ContentPaper
                title="Cego-Online"
                children={
                  <>
                    <LegacyContentTable>
                      <LegacyContentTableRow>
                        <LegacyContentTableCol>
                          Hier kannst Du endlich mit deinen Freunden weltweit oder auch gegen Computergegner Cego
                          spielen.
                        </LegacyContentTableCol>
                        <LegacyContentTableCol>
                          <Link to={ROUTES.lobby}>
                            <LegacyButton>Spielen</LegacyButton>
                          </Link>
                        </LegacyContentTableCol>
                      </LegacyContentTableRow>
                      <LegacyContentTableRow>
                        <LegacyContentTableCol>
                          Du hast noch kein Benutzerkonto? Kein Problem! Hier kannst du dir ein neues Konto erstellen:
                        </LegacyContentTableCol>
                        <LegacyContentTableCol>
                          <Link to={ROUTES.register}>
                            <LegacyButton>Registrieren</LegacyButton>
                          </Link>
                        </LegacyContentTableCol>
                      </LegacyContentTableRow>
                      <LegacyContentTableRow>
                        <LegacyContentTableCol>
                          Wenn Du Cego noch nicht kannst, bekommst Du es hier interaktiv beigebracht:
                        </LegacyContentTableCol>
                        <LegacyContentTableCol>
                          <Link to={ROUTES.eLearning}>
                            <LegacyButton>Kurs</LegacyButton>
                          </Link>
                        </LegacyContentTableCol>
                      </LegacyContentTableRow>
                      <LegacyContentTableRow>
                        <LegacyContentTableCol>
                          Willst Du erst einmal wissen was Cego überhaupt ist, kannst Du hier viel darüber erfahren:
                        </LegacyContentTableCol>
                        <LegacyContentTableCol>
                          <Link to={ROUTES.wissen}>
                            <LegacyButton>Wissen</LegacyButton>
                          </Link>
                        </LegacyContentTableCol>
                      </LegacyContentTableRow>
                    </LegacyContentTable>
                  </>
                }
              ></ContentPaper>
              <LegacyHalfPaperContainer>
                <LegacyHalfPaperStrip>
                  <ContentPaper
                    title="Realisation"
                    children={
                      <>
                        Cego-Online ist ein studentisches Projekt der Forschung und Lehre der Fakultät "Digitale Medien"
                        an der Hochschule Furtwangen University ohne kommerzielle Interessen
                        <br />
                        <br />
                        <img src="/res/HFU-klein.png" alt="HFU"></img>
                      </>
                    }
                    isSingle
                    rotation={-2}
                  ></ContentPaper>
                  <ContentPaper
                    title="Spendenkonto"
                    children={
                      <>
                        Über Spenden zur Weiterentwicklung von Cego-Online und dem Spiellabor der HFU würden wir uns
                        freuen: <br />
                        <br />
                        <LegacyBoldSection>Fördergesellschaft der Hochschule Furtwangen e.V.</LegacyBoldSection>
                        <br />
                        IBAN: <br />
                        DE52 6649 2700 0120 2640 10 <br />
                        <br />
                        BIC:
                        <br /> GENODE61KZT
                        <br />
                        <br /> Verwendungszweck:
                        <br /> <LegacyBoldSection>Spende Cego-Online</LegacyBoldSection>
                      </>
                    }
                    isSingle
                  ></ContentPaper>
                </LegacyHalfPaperStrip>
                <LegacyHalfPaperStrip>
                  <ContentPaper
                    title="Projektpartner"
                    children={
                      <>
                        Cego-Online entsteht im Auftrag und mit Unterstützung des Naturpark Südschwarzwald e.V.
                        <br />
                        <br />
                        <img src="/res/naturpark.png" width="100%" alt="Naturpark" />
                        <br />
                        <br />
                        Weitere Infos im Impressum.
                      </>
                    }
                    isSingle
                  ></ContentPaper>
                  <ContentPaper
                    title="Lizenzgeber"
                    children={
                      <>
                        Verwendung der Kartenbilder mit Genehmigung der Spielkartenfabrik Altenburg GmbH, © 2013 ASS
                        Altenburger Spielkarten
                        <br />
                        <br />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <img src="/res/Altenburger.png" alt="Altenburger" />
                        </div>
                      </>
                    }
                    isSingle
                  ></ContentPaper>
                </LegacyHalfPaperStrip>
              </LegacyHalfPaperContainer>
            </LegacyLargePaperContainer>
            <LegacySmallPaperContainer>
              <ContentPaper
                title="Online-Kurs"
                children={
                  <>
                    <p>Der interaktive Cego-Kurs ist wieder spielbar! Klicke dazu einfach hier:</p>
                    <br />
                    <Link to={ROUTES.eLearning}>
                      <LegacyButton>Zum Cego-Online Kurs</LegacyButton>
                    </Link>
                  </>
                }
                isSingle
                isNews
                date="07.08.2023 16:55"
                rotation={-1}
              ></ContentPaper>
              <ContentPaper
                title="Server-Umzug"
                children={
                  <>
                    <p>
                      Die Internetadresse der Beta-Version von Cego-Online{' '}
                      <LegacyBoldInlineSection>cego-reloaded.de</LegacyBoldInlineSection> wird demnächst auf{' '}
                      <LegacyBoldInlineSection>cego-online.de</LegacyBoldInlineSection> umgestellt.
                    </p>
                    <p>
                      Wenn es so weit ist, wird fürs Erste eine Weiterleitung eingerichtet. Mit dem Umzug des Servers
                      wird auch die alte Flash-Version von Cego-Online abgeschaltet.
                    </p>
                  </>
                }
                isSingle
                isNews
                date="06.08.2023 16:55"
                rotation={1}
              ></ContentPaper>
              <ContentPaper
                title="Open Beta"
                children={<>Die öffentliche Testphase für die Version 2.0 von Cego-Online startet.</>}
                isSingle
                isNews
                date="17.10.2022 15:25"
                rotation={-1}
              ></ContentPaper>
            </LegacySmallPaperContainer>
          </LegacySiteContentContainer>
          <LegacyFooterContainer>
            <Link to={ROUTES.impressum}>
              <LegacyFooterItem>Impressum</LegacyFooterItem>
            </Link>
          </LegacyFooterContainer>
        </LegacyContentContainer>
      </LegacyMainContainer>
    </>
  )
}
