import { LegacyPin, LegacyPinContainer } from '../legacyStyle'
import { LegacyPaperContainer, LegacyPaperContent, LegacyPaperDate, LegacyPaperFooter, LegacyPaperTop } from './style'

export const ContentPaper = (props: {
  title: string
  children?: JSX.Element
  date?: string
  rotation?: number
  isNews?: boolean
  isSingle?: boolean
  center?: boolean
}) => {
  return (
    <LegacyPaperContainer rotation={props.rotation}>
      <LegacyPaperTop isNews={props.isNews} center={props.center}>
        <LegacyPaperDate>{props.date}</LegacyPaperDate>
        <LegacyPinContainer single={props.isSingle}>
          <LegacyPin />
          {props.isSingle || <LegacyPin />}
        </LegacyPinContainer>
        {props.title}
      </LegacyPaperTop>
      <LegacyPaperContent isNews={props.isNews}>{props.children}</LegacyPaperContent>
      <LegacyPaperFooter isNews={props.isNews} />
    </LegacyPaperContainer>
  )
}
