import styled from 'styled-components'

export const StyledRegister = styled.div`
  height: 100%;
  width: 100%;
  background-color: tan;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto;
`

export const StyledRegisterContent = styled.div`
  padding: 20px 20px 0px 20px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 30px;
  }
`
export const StyledActivateForm = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  input {
    margin-bottom: 20px;
  }
`

export const StyledDsgvo = styled.label`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  a {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    color: #3131ff;
  }
`
