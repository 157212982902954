import React from 'react'
import { isNullOrEmpty } from '../../Util/IsNullOrEmpty'
import { TableAvatar } from '../TableList/style'
import { KnockAlertContainer } from './style'

export const KnockAlert = (props: { playerName: string; playerIconPath: string }) => {
  const [visible, setVisible] = React.useState(false)

  React.useEffect(() => {
    if (isNullOrEmpty(props.playerName) === false) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [props.playerName])

  return (
    <KnockAlertContainer visible={visible} onClick={() => setVisible(false)}>
      <TableAvatar width={50} height={50} src={props.playerIconPath} />
      {props.playerName} hat angeklopft!
    </KnockAlertContainer>
  )
}
