/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import {
  LegacyButton,
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyHalfPaperContainer,
  LegacyHalfPaperStrip,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySchindeln,
  LegacySiteContentContainer,
  LegacyFullPaperContainer,
} from '../legacyStyle'
import { ContentPaper } from '../ContentPaper/ContentPaper'
import { ROUTES } from '../../../lib/AppConfig'
import { LegacyBoldInlineSection } from '../ContentPaper/style'
import {
  LegacyLearningTable,
  LegacyLearningTableDataLeft,
  LegacyLearningTableDataRight,
  LegacyLearningTableRow,
} from './style'

export const Lernen = () => {
  return (
    <>
      <LegacyMainContainer>
        <LegacySchindeln />
        <LegacyContentContainer height="3100px">
          <LegacyNavContainer>
            <Link to={ROUTES.home}>
              <LegacyNavItem>Cego</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lobby}>
              <LegacyNavItem>spielen</LegacyNavItem>
            </Link>

            <Link to={ROUTES.lernen}>
              <LegacyNavItem selected>lernen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.wissen}>
              <LegacyNavItem>wissen</LegacyNavItem>
            </Link>
          </LegacyNavContainer>
          <LegacySiteContentContainer>
            <LegacyFullPaperContainer>
              <ContentPaper
                title="Cego und die Regeln"
                children={
                  <>
                    <p>
                      Wenn Du noch gar nicht Cego spielen kannst, machst Du am besten gleich unseren Online-Kurs.
                      Fidelius Waldvogel wird dir die Grundlagen erklären und mit dir auch einige interaktive Übungen
                      absolvieren.
                    </p>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                      <Link to={ROUTES.eLearning}>
                        <LegacyButton>Zum Online-Kurs</LegacyButton>
                      </Link>
                    </div>
                    <p>
                      <br />
                      Zum Online-Kurs Willst Du nur noch einmal schnell etwas nachschlagen, oder etwas ganz genau
                      wissen, dann schaust Du einfach die Regeln auf dieser Seite durch. Sie sind in kleine Abschnitte
                      unterteilt, so dass Du dich schnell zurecht finden solltest.
                    </p>
                  </>
                }
              ></ContentPaper>

              <LegacyHalfPaperContainer>
                <LegacyHalfPaperStrip style={{ width: '45%' }}>
                  <ContentPaper
                    title="Besonderheit bei Cego-Online"
                    children={
                      <>
                        <br />
                        Die zweite Bieterunde mit Piccolo oder Bettel ist eine regionale Variante, die dank ihrer
                        Stringenz für Cego-Online besonders geeignet ist, aber (noch) nicht in die offiziellen Regeln
                        der Schwarzwaldmeisterschaften integriert wurde.
                      </>
                    }
                  ></ContentPaper>
                  <ContentPaper
                    title="Stichfolge"
                    children={
                      <>
                        Der Gstieß ist der oberste Trumpf und sticht alle anderen Karten. Danach stechen die anderen
                        Trumpfe in absteigender Reihenfolge. Dann kommen die Bilder in der Folge König, Dame, Reiter,
                        Bube. Die Leeren können nur untereinander stechen. Dabei die Schwarzen von der 10 absteigend,
                        die Roten aber von der 1 aufsteigend. Eine rote 1 sticht also die anderen roten Leeren.
                      </>
                    }
                    isSingle
                    rotation={-2}
                  ></ContentPaper>
                  <ContentPaper
                    title="Stechen"
                    children={
                      <>
                        Das eigentliche Spiel ist denkbar einfach. Der Ansagende beginnt, er ist also vorne, und legt
                        eine Karte. Die anderen Spieler legen gegen den Uhrzeigersinn Karten dazu. Dabei müssen sie
                        Farbe bekennen, also zum Beispiel Herz legen, wenn von vorne Herz kam. Kann ein Spieler nicht
                        bekennen, muss er Trumpf spielen. Hat er keinen Trumpf, kann er eine beliebige Karte spielen.
                        Wer den höchsten Trumpf gespielt hat, oder die höchste Karte der vorne ausgespielten Farbe,
                        erhält die vier Karten und legt sie bei sich ab. Er ist als nächster in der Vorhand. Spielt die
                        Vorhand Trumpf, muss auch Trumpf bekannt werden und andere Farben stechen nicht.
                      </>
                    }
                    isSingle
                    rotation={2}
                  ></ContentPaper>
                </LegacyHalfPaperStrip>
                <LegacyFullPaperContainer>
                  <ContentPaper
                    title="Das Bieten"
                    children={
                      <>
                        Es gibt viele Möglichkeiten zu gewinnen oder zu verlieren. Entscheidend ist, welches Spiel
                        angesagt wird. In der Regel spielt der ansagende Spieler gegen die drei anderen. Nachdem die
                        Spieler ihre Karten aufgenommen haben, geben Sie reihum an, beginnend mit dem Spieler rechts vom
                        Geber, welches Spiel sie machen wollen, oder „fort” bzw. „weg” wenn Sie nicht ansagen möchten.
                        Zunächst wird gefragt ob jemand ein Solo oder ein Ultimo spielen möchte, wobei ein angesagtes
                        Solo von einem Gegensolo überstimmt werden kann. Gab es keine Ansage, wird nach Piccolo oder
                        Bettel gefragt. Hat bis hierher immer noch niemand zugeschlagen, sagt der Spieler rechts vom
                        Geber Cego an und das Steigern kann beginnen. Steigert niemand, hat der Spieler noch die Wahl,
                        statt des Cego einen Räuber zu spielen.
                      </>
                    }
                  ></ContentPaper>
                  <LegacyHalfPaperContainer>
                    <LegacyHalfPaperStrip>
                      <ContentPaper
                        title="Solo und Gegensolo"
                        children={
                          <>
                            <p>
                              <LegacyBoldInlineSection>Solo:</LegacyBoldInlineSection> der Ansagende spielt mit den
                              Karten auf seiner Hand und tauscht nicht mit den Blinden. Ansonsten ist der Spielablauf
                              wie bei Cego. Bei Sieg wird die Punktzahl verdoppelt, bei Verlust nicht. Das macht das
                              Solospiel besonders attraktiv.
                            </p>
                            <br />
                            <p>
                              <LegacyBoldInlineSection>Gegensolo:</LegacyBoldInlineSection> ist ein Solo angesagt, kann
                              ein folgender Spieler durch Ansage von Gegensolo doch noch einen normalen Cego mit Tausch
                              gegen die Blinden spielen. Die Punktzahl ist allerdings verdoppelt. Die anderen Spieler
                              können wie bei Cego weiter Steigern, die Punktfaktoren erhöhen sich dabei jeweils um eins.
                            </p>
                          </>
                        }
                      ></ContentPaper>
                    </LegacyHalfPaperStrip>
                    <LegacyHalfPaperStrip>
                      <ContentPaper
                        title="Die Sonderspiele"
                        children={
                          <>
                            <p>
                              Bei diesen Spielen wird nicht mit den Blinden getauscht, alle spielen mit den Handkarten,
                              welche sie zu Spielbeginn erhalten haben. Den Spielen sind feste Punktzahlen (in Klammern)
                              zugeordnet
                            </p>
                            <br />
                            <p>
                              <LegacyBoldInlineSection>Bettel</LegacyBoldInlineSection> (30): der Ansagende darf keinen
                              Stich machen.
                            </p>{' '}
                            <p>
                              <LegacyBoldInlineSection>Piccolo</LegacyBoldInlineSection> (40): der Ansagende muss genau
                              einen Stich machen.
                            </p>{' '}
                            <p>
                              <LegacyBoldInlineSection>Ultimo</LegacyBoldInlineSection> (80): der Ansagende muss den
                              letzten Stich mit der Geiß machen.
                            </p>
                            <p>
                              <LegacyBoldInlineSection>Räuber</LegacyBoldInlineSection> (40 x2 bei Verlust): jeder gegen
                              jeden. Es verliert, wer den höchsten Zählwert hat. Verliert der Ansager wird die Punktzahl
                              verdoppelt, also Vorsicht!
                            </p>
                          </>
                        }
                      ></ContentPaper>
                    </LegacyHalfPaperStrip>
                  </LegacyHalfPaperContainer>
                  <ContentPaper
                    title="Cego und die Steigerungen"
                    children={
                      <>
                        <p>
                          Wird Cego oder eine der Steigerungsformen gespielt, gewinnt der Ansagende, wenn er dabei einen
                          höheren Zählwert als 39 erreicht. Dabei spielt er allerdings nicht mit den Karten, welche er
                          zu Spielbeginn bekommt, sondern mit den Blinden von der Tischmitte. Je nach Steigerung kann er
                          aber von seinen Handkarten dabei einige mitnehmen. Entsprechend der Steigerung werden die
                          Punkte schließlich mit einem Faktor (in Klammern) belegt.
                        </p>
                        <br />
                        <LegacyLearningTable>
                          <LegacyLearningTableRow>
                            <LegacyLearningTableDataLeft>
                              <LegacyBoldInlineSection>Cego</LegacyBoldInlineSection> <br />
                              (x1)
                            </LegacyLearningTableDataLeft>
                            <LegacyLearningTableDataRight>
                              Der Ansagende legt zwei seiner Handkarten mit zu den Blinden und legt die anderen ab. Dann
                              nimmt er die Blinden und legt eine weitere Karte zu seiner Legage (Drücken).
                            </LegacyLearningTableDataRight>
                          </LegacyLearningTableRow>
                          <LegacyLearningTableRow>
                            <LegacyLearningTableDataLeft>
                              <LegacyBoldInlineSection>Halbe</LegacyBoldInlineSection> <br />
                              (x2)
                            </LegacyLearningTableDataLeft>
                            <LegacyLearningTableDataRight>
                              Wie Cego, vor dem Tausch legt der Ansagende aber eine Karte aus, nimmt nur eine zu den
                              Blinden mit und drückt eine. Danach kann er noch die ausgespielte Karte gegen eine andere
                              gleicher Farbe austauschen. Die zuerst gezeigte Karte kann aber nie stechen, sie ist ein
                              Geschenk an die Gegner!
                            </LegacyLearningTableDataRight>
                          </LegacyLearningTableRow>
                          <LegacyLearningTableRow>
                            <LegacyLearningTableDataLeft>
                              <LegacyBoldInlineSection>Eine</LegacyBoldInlineSection> <br />
                              (x3)
                            </LegacyLearningTableDataLeft>
                            <LegacyLearningTableDataRight>
                              Der Ansagende kann nur eine Karte mitnehmen und keine drücken.
                            </LegacyLearningTableDataRight>
                          </LegacyLearningTableRow>
                          <LegacyLearningTableRow>
                            <LegacyLearningTableDataLeft>
                              <LegacyBoldInlineSection>Eine Leere</LegacyBoldInlineSection> <br />
                              (x4)
                            </LegacyLearningTableDataLeft>
                            <LegacyLearningTableDataRight>
                              Der Ansagende spielt eine Karte aus, legt die anderen Karten ab und spielt mit den Blinden
                              weiter ohne zu drücken. Die ausgelegte Karte sticht nie, ist also ein Geschenk an die
                              Gegner.
                            </LegacyLearningTableDataRight>
                          </LegacyLearningTableRow>
                          <LegacyLearningTableRow>
                            <LegacyLearningTableDataLeft>
                              <LegacyBoldInlineSection>Zwei Leere</LegacyBoldInlineSection> <br />
                              (x5)
                            </LegacyLearningTableDataLeft>
                            <LegacyLearningTableDataRight>
                              Der Ansagende spielt zwei Karten aus, legt die anderen Karten ab und spielt mit den
                              Blinden weiter, muss aber den kleinsten Trumpf in die Legage drücken. Die ausgelegten
                              Karten stechen nicht und sind Geschenke. Auch beim zweiten Stich ist der Ansagende in der
                              Vorhand und spielt die zweite offene Karte.
                            </LegacyLearningTableDataRight>
                          </LegacyLearningTableRow>
                          <LegacyLearningTableRow>
                            <LegacyLearningTableDataLeft>
                              <LegacyBoldInlineSection>Zwei Verschiedene</LegacyBoldInlineSection> <br />
                              (x6)
                            </LegacyLearningTableDataLeft>
                            <LegacyLearningTableDataRight>
                              wie „Zwei Leere”, die beiden Karten müssen aber von unterschiedlicher Farbe sein und der
                              größte Trumpf wird gedrückt.
                            </LegacyLearningTableDataRight>
                          </LegacyLearningTableRow>
                          <LegacyLearningTableRow>
                            <LegacyLearningTableDataLeft>
                              <LegacyBoldInlineSection>Kleiner Mann</LegacyBoldInlineSection> <br />
                              (x7)
                            </LegacyLearningTableDataLeft>
                            <LegacyLearningTableDataRight>
                              wie „Eine Leere”, die angespielte Karte ist aber die Geiß. Sie kann nicht getauscht
                              werden.
                            </LegacyLearningTableDataRight>
                          </LegacyLearningTableRow>
                        </LegacyLearningTable>
                      </>
                    }
                  ></ContentPaper>
                </LegacyFullPaperContainer>
              </LegacyHalfPaperContainer>
              <div style={{ marginTop: '480px' }}>
                <ContentPaper
                  title="Punktewertung"
                  children={
                    <>
                      <p>
                        Außer bei den Sonderspielen werden die Punkte ermittelt, wenn alle Karten ausgespielt sind.
                        Dabei legen die drei Gegenspieler ihre Karten zusammen. Der Einzelspieler legt seine gewonnen
                        Karten zu seiner Legage, beim Solo zu den Blinden, und zählt alle zusammen.
                      </p>
                      <br /> <p>Die Karten haben folgende Zählwerte:</p>
                      <p>
                        Gstieß, Mund und Geiß sowie die Könige: 4½ , Dame 3½, Reiter 2½, Bube 1½. Alle anderen Karten,
                        die Trümpfe wie die Leeren, zählen jeweils nur ½. Mit jeweils einer höherwertigen Karte bilden
                        diese also Paare von ganzzahligem Wert, die leicht addiert werden können.
                      </p>
                      <br />
                      <p>
                        Beim Räuber gibt es zwar eine festgelegte Punktzahl, die Wertung muss trotzdem durchgeführt
                        werden, und zwar für jeden Spieler, um den Verlierer zu ermitteln.
                      </p>
                    </>
                  }
                ></ContentPaper>
              </div>
            </LegacyFullPaperContainer>
          </LegacySiteContentContainer>
          <LegacyFooterContainer>
            <Link to={ROUTES.impressum}>
              <LegacyFooterItem>Impressum</LegacyFooterItem>
            </Link>
          </LegacyFooterContainer>
        </LegacyContentContainer>
      </LegacyMainContainer>
    </>
  )
}
