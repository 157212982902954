import { GameMode, PlayerDataMap, WinData } from '@hs-furtwangen/socket-lib'
import { GameModeHelper } from '../Util/GameModeHelper'
import { gameModeIsSondergame, getPointsMultiplier } from '../Util/CalculationHelper'

const getCegoCalculation = (
  gameMode: GameMode,
  winData: WinData,
  lastChallengerId: string,
  tablePlayerIds: string[],
) => {
  let multiplier = 1
  if (gameMode === GameMode.Raeuber) {
    const challengerLost = winData.points[lastChallengerId] < 0
    multiplier = 4
    let jungfrauen: string[] = []

    tablePlayerIds.forEach((id) => {
      if (winData.cardValues[id] === 0) {
        jungfrauen.push(id)
      }
    })
    let baseLoss = 10 * multiplier * 3
    let realLoss = challengerLost ? baseLoss * 2 : baseLoss
    return (
      <>
        <p>
          10 x {multiplier} (Multiplikator {GameModeHelper.gameModeToString(gameMode)}) = <b>{10 * multiplier}</b> x 3
          Spieler = <b>{baseLoss}</b> Punkte Abzug für den Verlierer
        </p>
        {challengerLost && (
          <>
            <br />
            <p>
              Ansager hat verloren. Punkte werden verdoppelt! <b>{baseLoss}</b> x 2 =<b>{` ${realLoss}`}</b> Punkte
              Abzug für den Verlierer
            </p>
          </>
        )}
        {jungfrauen.length === 1 && (
          <>
            <br />
            <p>
              Ein Spieler ist Jungfrau! Der / die Verlierer erhalten zusätzlich <b>120</b> Punkte Abzug!
            </p>
          </>
        )}
        {jungfrauen.length === 2 && (
          <>
            <br />
            <p>
              Zwei Spieler sind Jungfrauen! Der / die Verlierer erhalten zusätzlich <b>240</b> Punkte Abzug!
            </p>
          </>
        )}
      </>
    )
  } else {
    switch (gameMode) {
      case GameMode.Cego:
        multiplier = 1
        break
      case GameMode.Solo:
      case GameMode.Gegensolo:
        multiplier = 2
        break
      case GameMode.Bettel:
        multiplier = 3
        break
      case GameMode.Piccolo:
        multiplier = 4
        break
      case GameMode.Halbe:
        multiplier = 2
        break
      case GameMode.Eine:
        multiplier = 3
        break
      case GameMode.EineLeere:
        multiplier = 4
        break
      case GameMode.ZweiLeere:
        multiplier = 5
        break
      case GameMode.ZweiVerschiedeneLeere:
        multiplier = 6
        break
      case GameMode.KleinerMann:
        multiplier = 7
        break
      case GameMode.Ultimo:
        multiplier = 8
        break
    }
    const points = getPointsMultiplier(winData.cardValues[tablePlayerIds[0]])[1]
    const roundedPoints = Math.round(points / 10) * 10
    const needsToBeRounded = points !== roundedPoints ? ' (gerundet)' : ''
    const hadGegensoloText = winData.hadGegensolo && gameMode !== GameMode.Gegensolo ? ' + GEGENSOLO' : ''
    multiplier = gameMode !== GameMode.Gegensolo && winData.hadGegensolo ? multiplier + 1 : multiplier
    if (gameMode === GameMode.Solo || gameMode === GameMode.Gegensolo) {
      return (
        <>
          <b>{points}</b> x {1} (Multiplikator {GameModeHelper.gameModeToString(GameMode.Cego)}) ={' '}
          <b>
            {roundedPoints}
            {needsToBeRounded}
          </b>{' '}
          x 3 Spieler = <b>{3 * roundedPoints}</b> Punkte * {multiplier} (Multiplikator{' '}
          {GameModeHelper.gameModeToString(gameMode)}) = <b>{3 * roundedPoints * 2} Punkte</b> bei Gewinn,{' '}
          <b>-{roundedPoints * 3}</b> bei Verlust
        </>
      )
    } else if (gameModeIsSondergame(gameMode) === false) {
      return (
        <>
          <b>{points}</b> x {multiplier} (Multiplikator {GameModeHelper.gameModeToString(gameMode)}
          {hadGegensoloText}) = <b>{roundedPoints * multiplier}</b>
          {needsToBeRounded} x 3 Spieler = <b>{roundedPoints * multiplier * 3} Punkte</b>
        </>
      )
    }
  }
}

export const getPointsCalculation = (
  winData: WinData,
  gameMode: GameMode,
  lastChallengerId: string,
  tablePlayerIds: string[],
) => {
  try {
    let calculation = null

    calculation = getCegoCalculation(gameMode, winData, lastChallengerId, tablePlayerIds)

    return winData.matchNumber >= 0 ? (
      <>
        <span>{calculation}</span>
      </>
    ) : (
      <></>
    )
  } catch (err) {
    console.log(err)
    return <></>
  }
}

export const getWinnerAnnouncement = (
  winData: WinData,
  gameMode: string,
  lobbyPlayers: PlayerDataMap,
  tablePlayerIds: string[],
) => {
  let winners: string[] = []
  let losers: string[] = []
  let result: [string, number][] = []
  tablePlayerIds.forEach((playerId) => {
    result.push([playerId, winData?.points[playerId]])
  })
  if (winData.matchNumber === -1) {
    // game got cancelled
    return <>Ein Spieler hat den Tisch verlassen, die Partie wurde beendet.</>
  } else if (winData.matchNumber === -2) {
    // game got cancelled
    return <>Das Spiel wurde aufgrund eines Fehlers unerwartet beendet.</>
  }
  result.forEach((info) => {
    if (info[1] > 0) {
      winners.push(lobbyPlayers[info[0]]?.name)
    } else {
      losers.push(lobbyPlayers[info[0]]?.name)
    }
  })
  if (winners?.length === 1) {
    return (
      <>
        {winners[0]} gewinnt <span>{gameMode}</span>
      </>
    )
  } else if (losers?.length === 1) {
    return (
      <>
        {losers[0]} verliert <span>{gameMode}</span>
      </>
    )
  } else if (losers?.length === 2) {
    return (
      <>
        {losers[0]} und {losers[1]} verlieren <span>{gameMode}</span>
      </>
    )
  } else {
    return <></>
  }
}
