import styled from 'styled-components'

export const StyledLogo = styled.h1`
  width: 230px;
  @media (max-width: 470px) {
    width: 100px;
  }
`

export const StyledVersion = styled.button`
  text-decoration: none;
  margin-left: 10px;
  color: var(--secondary-color);
  font-size: 19px;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 470px) {
    display: none;
  }
`

export const CreateTableForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CreateTableFormRow = styled.div`
  width: 100%;
  padding: 7px;

  p {
    margin-bottom: 3px;
  }
`

export const LobbyContent = styled.div`
  padding: 10px;
  height: calc(100% - var(--header-footer-height) * 2);
  background-image: url(${(props) => props.background});
`
