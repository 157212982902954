import React from 'react'
import { ROUTES } from '../../lib/AppConfig'
import {
  StyledStaticContent,
  StyledStaticHeader,
  StyledStaticHeading,
  StyledStaticMessage,
  StyledStaticPage,
  StyledStaticWindow,
} from './style'

export const StaticPage = (props: { heading: string; message: any }) => {
  return (
    <>
      <StyledStaticHeader>
        <a href={ROUTES.home}>CEGO</a>
      </StyledStaticHeader>
      <StyledStaticPage>
        <StyledStaticWindow>
          <StyledStaticContent>
            <StyledStaticHeading>{props.heading}</StyledStaticHeading>
            <StyledStaticMessage>{props.message}</StyledStaticMessage>
          </StyledStaticContent>
        </StyledStaticWindow>
      </StyledStaticPage>
    </>
  )
}
