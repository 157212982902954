/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../lib/AppConfig'
import useForm from '../UseForm'
import { StyledActivateForm, StyledRegisterContent } from './style'
import {
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyFullPaperContainer,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySchindeln,
  LegacySiteContentContainer,
} from '../LegacyPages/legacyStyle'
import { ContentPaper } from '../LegacyPages/ContentPaper/ContentPaper'

export const Activate = (props: { backendEndpoint: string }) => {
  const initialInputState = { password: '', passwordRepeat: '' }
  const [passwordRepeatError, setPasswordRepeatError] = useState(false)
  const { handleInputChange, handleSubmit, inputs } = useForm(activateCallback, initialInputState)
  const navigate = useNavigate()

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (!urlParams.get('code')) {
      navigate(ROUTES.home)
    } else {
      const register = urlParams.get('register')
      if (register === 'true') {
        fetch(
          `${props.backendEndpoint}/activate`,
          requestOptions({
            password: inputs.password,
            passwordRepeat: inputs.passwordRepeat,
            code: urlParams.get('code'),
          }),
        ).then((response) => {
          if (response.ok) {
            navigate(ROUTES.activated)
          }
        })
      }
    }
  }, [])

  const requestOptions = (jsonBody) => {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody),
    }
  }

  async function activateCallback() {
    const pwMatch = inputs.password === inputs.passwordRepeat
    setPasswordRepeatError(pwMatch === false)
    if (pwMatch) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const response = await fetch(
        `${props.backendEndpoint}/activate`,
        requestOptions({
          password: inputs.password,
          passwordRepeat: inputs.passwordRepeat,
          code: urlParams.get('code'),
        }),
      )
      if (response.ok) {
        navigate(ROUTES.activated)
      }
    }
  }

  return (
    <LegacyMainContainer>
      <LegacySchindeln />
      <LegacyContentContainer height="1650px">
        <LegacyNavContainer>
          <LegacyNavItem>Cego</LegacyNavItem>

          <Link to={ROUTES.lobby}>
            <LegacyNavItem selected>spielen</LegacyNavItem>
          </Link>

          <Link to={ROUTES.lernen}>
            <LegacyNavItem>lernen</LegacyNavItem>
          </Link>
          <Link to={ROUTES.wissen}>
            <LegacyNavItem>wissen</LegacyNavItem>
          </Link>
        </LegacyNavContainer>
        <LegacySiteContentContainer>
          <LegacyFullPaperContainer>
            <ContentPaper
              title="Benutzerkonto aktivieren"
              children={
                <>
                  <StyledRegisterContent>
                    <p style={{ textAlign: 'center' }}>
                      Vergebe hier das neue Passwort mit dem du dich in Zukunft anmelden kannst:
                    </p>
                    <StyledActivateForm onSubmit={handleSubmit}>
                      <br />
                      <input
                        type="password"
                        name="password"
                        placeholder="Passwort"
                        onChange={handleInputChange}
                        required
                      ></input>
                      <input
                        type="password"
                        name="passwordRepeat"
                        placeholder="Passwort wiederholen"
                        onChange={handleInputChange}
                        required
                      ></input>
                      {passwordRepeatError && (
                        <>
                          <p style={{ color: 'red', fontWeight: 'bold' }}>
                            Die Passwörter müssen
                            <br /> übereinstimmen!
                          </p>
                          <br />
                        </>
                      )}
                      <button className="button-wide" type="submit">
                        Reaktivieren
                      </button>
                    </StyledActivateForm>
                  </StyledRegisterContent>
                </>
              }
            ></ContentPaper>
          </LegacyFullPaperContainer>
        </LegacySiteContentContainer>
        <LegacyFooterContainer>
          <Link to={ROUTES.impressum}>
            <LegacyFooterItem>Impressum</LegacyFooterItem>
          </Link>
        </LegacyFooterContainer>
      </LegacyContentContainer>
    </LegacyMainContainer>
  )
}
