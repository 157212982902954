import React from 'react'
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { StaticPage } from './Components/StaticPage/StaticPage'
import Lobby from './Components/Lobby/Lobby'
import { CegoClient } from '@hs-furtwangen/socket-lib'
import { StyledStaticLink } from './Components/StaticPage/style'
import { Login } from './Components/Login/Login'
import { getBackendEndpoint, ROUTES } from './lib/AppConfig'

import { ProtectedRoute } from './lib/ProtectedRoute'
import { AuthProvider } from './lib/AuthProvider'
import { Register } from './Components/Register/Register'
import { Activate } from './Components/Register/Activate'
import { UnauthorizedRoute } from './lib/UnauthorizedRoute'
import { PasswordReset } from './Components/Login/PasswordReset'
import { PasswordChange } from './Components/Login/PasswordChange'
import { Startpage } from './Components/LegacyPages/StartPage/Startpage'
import { Wissen } from './Components/LegacyPages/Wissen/Wissen'
import { Lernen } from './Components/LegacyPages/Lernen/Lernen'
import { Impressum } from './Components/LegacyPages/Impressum/Impressum'
import { LegacyRoute } from './lib/LegacyRoute'
import { Learning } from './Components/LegacyPages/Learning'
import { Datenschutz } from './Components/LegacyPages/Datenschutz/Datenschutz'
import { AccountDeletion } from './Components/Deletion/AccountDeletion'

export const AuthContext = React.createContext(null)
ProtectedRoute.whyDidYouRender = true
export const App = (props: { cegoClient: CegoClient }) => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route
              element={
                <LegacyRoute>
                  <Startpage />
                </LegacyRoute>
              }
              index
            />
            <Route
              path={ROUTES.eLearning}
              element={
                <LegacyRoute>
                  <Learning />
                </LegacyRoute>
              }
            />
            <Route
              path={ROUTES.wissen}
              element={
                <LegacyRoute>
                  <Wissen />
                </LegacyRoute>
              }
            />
            <Route
              path={ROUTES.lernen}
              element={
                <LegacyRoute>
                  <Lernen />
                </LegacyRoute>
              }
            />
            <Route
              path={ROUTES.datenschutz}
              element={
                <LegacyRoute>
                  <Datenschutz />
                </LegacyRoute>
              }
            />
            <Route
              path={ROUTES.impressum}
              element={
                <LegacyRoute>
                  <Impressum />
                </LegacyRoute>
              }
            />
            <Route
              path={ROUTES.lobby}
              element={
                <ProtectedRoute>
                  <Lobby cegoClient={props.cegoClient} backendEndpoint={getBackendEndpoint()} />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.login}
              element={
                <UnauthorizedRoute>
                  <Login />
                </UnauthorizedRoute>
              }
            />
            <Route
              path={ROUTES.register}
              element={
                <UnauthorizedRoute>
                  <Register backendEndpoint={getBackendEndpoint()} />
                </UnauthorizedRoute>
              }
            />
            <Route
              path={ROUTES.activate}
              element={
                <UnauthorizedRoute>
                  <Activate backendEndpoint={getBackendEndpoint()} />
                </UnauthorizedRoute>
              }
            />
            <Route
              path={ROUTES.passwordReset}
              element={
                <UnauthorizedRoute>
                  <PasswordReset backendEndpoint={getBackendEndpoint()} />
                </UnauthorizedRoute>
              }
            />
            <Route
              path={ROUTES.deleteAccount}
              element={
                <UnauthorizedRoute>
                  <AccountDeletion backendEndpoint={getBackendEndpoint()} />
                </UnauthorizedRoute>
              }
            />
            <Route
              path={ROUTES.passwordChange}
              element={
                <UnauthorizedRoute>
                  <PasswordChange backendEndpoint={getBackendEndpoint()} />
                </UnauthorizedRoute>
              }
            />
            <Route
              path={ROUTES.error}
              element={
                <StaticPage
                  heading={'Keine Verbindung'}
                  message={
                    <>
                      <p>
                        Es konnte keine Verbindung zum CEGO Server hergestellt werden. Bitte versuche es später noch
                        einmal.
                      </p>
                      <br />
                      <p>
                        Klicke <StyledStaticLink to={ROUTES.lobby}>hier</StyledStaticLink> um die Seite neu zu laden.
                      </p>
                    </>
                  }
                />
              }
            />
            <Route
              path={ROUTES.kicked}
              element={
                <StaticPage
                  heading={'Sitzung beendet'}
                  message={
                    <>
                      <p>Deine Sitzung wurde beendet.</p>
                      <br />
                      <p>
                        Klicke <StyledStaticLink to={ROUTES.login}>hier</StyledStaticLink> um dich neu anzumelden.
                      </p>
                    </>
                  }
                />
              }
            />
            <Route
              path={ROUTES.activated}
              element={
                <StaticPage
                  heading={'Account aktiviert'}
                  message={
                    <>
                      <p>Dein Account wurde erfolgreich aktiviert!</p>
                      <br />
                      <p>
                        Klicke <StyledStaticLink to={ROUTES.login}>hier</StyledStaticLink> um dich im Stüble anzumelden.
                      </p>
                    </>
                  }
                />
              }
            />
            <Route
              path="*"
              element={
                <StaticPage
                  heading={'404 - Nichts gefunden'}
                  message={
                    <p>
                      Auf dieser Seite ist nichts. Klicke <StyledStaticLink to={ROUTES.lobby}>hier</StyledStaticLink> um
                      zurück ins Stüble zu gelangen.
                    </p>
                  }
                />
              }
            />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  )
}
