import styled from 'styled-components'

export const LegacyLearningTable = styled.table`
  width: 100%;
  border-spacing: 0 15px;
`

export const LegacyLearningTableRow = styled.tr``

export const LegacyLearningTableDataLeft = styled.td`
  vertical-align: top;
  width: 20%;
  font-family: 'Verdana';
  font-size: small;
`
export const LegacyLearningTableDataRight = styled.td`
  vertical-align: top;
  width: 80%;
  font-family: 'Verdana';
  font-size: small;
`
