import styled from 'styled-components'

export const StyledOptionContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  height: 20vh;
  font-size: small;
  padding: 3%;
`

export const StyledOption = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
`

export const StyledCheckbox = styled.input`
  width: 10%;
  height: 100%;
  display: ${(props) => {
    return props.isVisible ? 'inherit' : 'none'
  }};
  cursor: pointer;
`

export const StyledSelect = styled.select`
  width: 200px;
`

export const StyledOptionLabel = styled.label`
  width: 90%;
  cursor: pointer;
  font-size: 18px;
`
