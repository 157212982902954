import { PlayerData, PlayerDataMap, PlayerOptions, TableDataMap } from '@hs-furtwangen/socket-lib'
import '../CSS/PlayerList.css'
import { isNullOrEmpty } from '../Util/IsNullOrEmpty'
import { getStringOption } from '../lib/OptionHelper'
import React from 'react'

const showPlayerOption = () => {
  return getStringOption(PlayerOptions.showTablePlayers) === 'all' ? true : false
}

export default function PlayerList(props: { players: PlayerDataMap; tables: TableDataMap }) {
  const [showAllPlayers, setShowAllPlayers] = React.useState<boolean>(showPlayerOption())

  React.useEffect(() => {
    const handleOptionChange = () => {
      setShowAllPlayers(showPlayerOption())
    }

    window.addEventListener('options', handleOptionChange)
    return () => window.removeEventListener('options', handleOptionChange)
  }, [])

  const getPlayerListEntry = (player: PlayerData) => {
    if (player.isAi === false) {
      return (
        <div key={player.id} className="player-list-entry">
          <img className="player-list-avatar" src={player.iconPath} alt="Avatar" />
          <div style={{ display: 'flex', alignItems: 'center' }} className="player-list-user">
            <p className="player-list-name">{player.name}</p>
            <p style={{ display: 'none' }} className="player-list-level">
              Level {player.id}
            </p>
          </div>
          <p
            className="player-state"
            style={{ color: isNullOrEmpty(props.tables[player.tableId]?.name) ? 'green' : 'black' }}
          >
            {isNullOrEmpty(props.tables[player.tableId]?.name) ? 'im Stüble' : `${props.tables[player.tableId]?.name}`}
          </p>
        </div>
      )
    }
  }

  const getListItems = () => {
    console.log('get list item')
    const playerList = Object.values(props.players)
    const lobbyPlayers = playerList.filter((player) => isNullOrEmpty(props.tables[player.tableId]?.name))
    const tablePlayers = playerList.filter((player) => isNullOrEmpty(props.tables[player.tableId]?.name) === false)
    return (
      <>
        {lobbyPlayers.map((player: PlayerData) => getPlayerListEntry(player))}
        {showAllPlayers && tablePlayers.map((player: PlayerData) => getPlayerListEntry(player))}
      </>
    )
  }

  return (
    <div className="player-list">
      <h2 className="component-heading">Online</h2>
      <div className="player-list-content">{getListItems()}</div>
    </div>
  )
}
