import { GameMode, PlayerDataMap } from '@hs-furtwangen/socket-lib'
import { StyledPointsInGame, StyledPointsTable, StyledPointsTableData, StyledPointsTableRow } from './style'
import React from 'react'
import { StyledLogHeader, StyledLogHeaderGameMode } from '../Log/style'
import { GameModeHelper } from '../../Util/GameModeHelper'

function getWindowWidth() {
  const { innerWidth: width } = window
  return {
    width,
  }
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowWidth())

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowWidth())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const PointsInGame = (props: {
  lobbyPlayers: PlayerDataMap
  tablePlayerIds: string[]
  currentWinData: [string, number][]
  ownPlayerId: string
  gameMode: GameMode
  tmpGameMode: GameMode
  challengerId: string
  firstPlayerName?: string
}) => {
  const { width } = useWindowDimensions()

  const getTablePlayers = () => {
    const slicer = width < 1510 ? 4 : width < 1800 ? 6 : 10
    return (
      <thead>
        <tr>
          <th></th>
          {props.tablePlayerIds.map((id, idx) => {
            const player = props.lobbyPlayers[id]
            return (
              <th key={`player-${idx}`} style={{ color: player.id === props.ownPlayerId ? '#015bff' : 'black' }}>
                {player.name.replace(' (PC)', '').slice(0, slicer)}
                {player.name.replace(' (PC)', '').length > slicer ? '..' : ''}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }

  const getPointsTableRows = () => {
    if (props.currentWinData) {
      let roundInfos = []
      for (let i = 0; i < 11; i++) {
        const winData = props.currentWinData[i]
        if (winData) {
          const teamWon = props.gameMode !== GameMode.Raeuber && winData[0] !== props.challengerId
          roundInfos.push(
            <StyledPointsTableRow key={`point-row-${i}`}>
              <StyledPointsTableData>{i + 1}.</StyledPointsTableData>
              {props.tablePlayerIds.map((id, idx) => {
                const isTeamMember = id !== props.challengerId
                return (
                  <StyledPointsTableData key={`point-data-${i}-${idx}`}>
                    {props.gameMode === GameMode.Raeuber && (winData[0] === id ? winData[1] : '-')}
                    {props.gameMode !== GameMode.Raeuber && isTeamMember && (teamWon ? winData[1] : '-')}
                    {props.gameMode !== GameMode.Raeuber && !isTeamMember && (!teamWon ? winData[1] : '-')}
                  </StyledPointsTableData>
                )
              })}
            </StyledPointsTableRow>,
          )
        } else {
          roundInfos.push(
            <StyledPointsTableRow key={`point-row-${i}`}>
              <StyledPointsTableData>{i + 1}.</StyledPointsTableData>
              {props.tablePlayerIds.map((id, idx) => {
                return <StyledPointsTableData key={`empty-data-${i}-${idx}`}></StyledPointsTableData>
              })}
            </StyledPointsTableRow>,
          )
        }
      }
      return (
        <tbody>
          {roundInfos}
          <StyledPointsTableRow>
            <td></td>
            <StyledPointsTableData colSpan="4">
              <hr style={{ marginBottom: '2px' }} />
              <hr />
            </StyledPointsTableData>
          </StyledPointsTableRow>
          <StyledPointsTableRow>
            <td></td>
            {props.tablePlayerIds?.map((id, idx) => {
              return (
                <StyledPointsTableData key={`sum-points-${idx}`} style={{ fontWeight: 'bold' }}>
                  {(id === props.challengerId || props.gameMode === GameMode.Raeuber) &&
                    props.currentWinData?.filter((data) => data[0] === id).reduce((a, b) => a + b[1], 0)}
                  {props.gameMode !== GameMode.Raeuber &&
                    id !== props.challengerId &&
                    props.currentWinData
                      ?.filter((data) => data[0] !== props.challengerId)
                      .reduce((a, b) => a + b[1], 0)}
                </StyledPointsTableData>
              )
            })}
          </StyledPointsTableRow>
        </tbody>
      )
    }
  }

  return (
    <>
      {props.firstPlayerName && (
        <StyledLogHeader>
          {`${props.firstPlayerName} spielt: `}
          <StyledLogHeaderGameMode>{GameModeHelper.gameModeToString(props.gameMode)}</StyledLogHeaderGameMode>
        </StyledLogHeader>
      )}
      <StyledPointsInGame>
        <StyledPointsTable>
          {getTablePlayers()}
          {getPointsTableRows()}
        </StyledPointsTable>
      </StyledPointsInGame>
    </>
  )
}
