import { ModalErrorReportContent } from '../Components/Modal/style'
import useForm from '../Components/UseForm'
import { CegoClient } from '@hs-furtwangen/socket-lib'
import { useState } from 'react'

export const ErrorReportDialogContent = (props: {
  cegoClient: CegoClient
  playerId: string
  tableId?: string
  logEntries?: string[]
}) => {
  const onReport = () => {
    console.log(inputs.reportText)
    props.cegoClient.sendErrorReport(inputs.reportText, props.playerId, props.tableId, props.logEntries)
    setShowConfirmMessage(true)
  }

  const initialInputState = { reportText: '' }
  const { handleInputChange, handleSubmit, inputs } = useForm(onReport, initialInputState)

  const [showConfirmMessage, setShowConfirmMessage] = useState(false)

  return showConfirmMessage ? (
    <ModalErrorReportContent>
      <p>Die Fehlermeldung wurde erfolgreich versendet...</p>
      <br />
      <p>Vielen Dank für dein Feedback!</p>
    </ModalErrorReportContent>
  ) : (
    <ModalErrorReportContent>
      <p>Hier kannst du gefundene Fehler melden.</p>
      <br />
      <p>
        Wenn du von einem Tisch aus einen Fehler meldest, wird automatisch der bisherige Spielverlauf mitgesendet, so
        wie du ihn auch im Logbuch verfolgen kannst.
      </p>
      <br />
      <p>Fehlerbeschreibung:</p>
      <form className="report-form" onSubmit={handleSubmit}>
        <textarea name="reportText" onChange={handleInputChange} required></textarea>
        <button className="button-wide" disabled={inputs.reportText?.length === 0}>
          Absenden
        </button>
      </form>
    </ModalErrorReportContent>
  )
}
