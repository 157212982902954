/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from './AppConfig'
import { useAuth } from './AuthProvider'

export const ProtectedRoute: FunctionComponent<any> = ({ children }) => {
  const { token, verifyToken } = useAuth()
  const navigate = useNavigate()
  const routeContentRef = useRef(null)
  React.useEffect(() => {
    verifyToken().then((res) => {
      if (res) {
        routeContentRef.current = children
      } else {
        navigate(ROUTES.login)
      }
    })
  }, [])

  if (token) {
    return (
      <>
        <meta name="viewport" content="height=device-height, initial-scale=1"></meta>
        {children}
      </>
    )
  }

  return routeContentRef.current ? routeContentRef.current : <>Loading</>
}
