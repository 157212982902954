import React from 'react'
import { ROUTES } from '../../lib/AppConfig'
import useForm from '../UseForm'
import { StyledActivateForm, StyledDsgvo, StyledRegisterContent } from './style'
import {
  LegacyContentContainer,
  LegacyFooterContainer,
  LegacyFooterItem,
  LegacyFullPaperContainer,
  LegacyHalfPaperContainer,
  LegacyHalfPaperStrip,
  LegacyMainContainer,
  LegacyNavContainer,
  LegacyNavItem,
  LegacySchindeln,
  LegacySiteContentContainer,
} from '../LegacyPages/legacyStyle'
import { Link } from 'react-router-dom'
import { ContentPaper } from '../LegacyPages/ContentPaper/ContentPaper'
import { LegacyBoldInlineSection } from '../LegacyPages/ContentPaper/style'

export const Register = (props: { backendEndpoint: string }) => {
  const [isRegistrationLoading, setIsRegistrationLoading] = React.useState(false)
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = React.useState(false)
  const [registrationError, setRegistrationError] = React.useState<string>(null)
  const requestOptions = (jsonBody) => {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody),
    }
  }

  const initialInputState = { accountName: '', password: '', mail: '', passwordRepeat: '' }
  const {
    handleInputChange: handleRegistereChange,
    handleSubmit: handleRegisterSubmit,
    inputs: registerInputs,
  } = useForm(registerCallback, initialInputState)

  async function registerCallback() {
    setRegistrationError('')
    setIsRegistrationLoading(true)
    if (registerInputs.password !== registerInputs.passwordRepeat) {
      setRegistrationError('Die Passwörter stimmen nicht überein!')
    } else {
      const hasAccountResponse = await fetch(
        `${props.backendEndpoint}/userExists`,
        requestOptions({ searchString: registerInputs.accountName }),
      )
      if (hasAccountResponse.status === 404) {
        const hasAccountResponse = await fetch(
          `${props.backendEndpoint}/userExists`,
          requestOptions({ searchString: registerInputs.mail }),
        )
        if (hasAccountResponse.status === 404) {
          const registerResponse = await fetch(
            `${props.backendEndpoint}/registerAccount`,
            requestOptions({
              accountName: registerInputs.accountName,
              mail: registerInputs.mail,
              password: registerInputs.password,
            }),
          )
          if (registerResponse.ok) {
            setIsRegistrationSuccessful(true)
          } else {
            setRegistrationError('Registrierung fehlgeschlagen: unbekannter Fehler')
          }
        } else {
          setRegistrationError('Diese E-Mail Adresse leider bereits verwendet!')
        }
      } else {
        setRegistrationError('Dieser Benutzername wird leider bereits verwendet!')
      }
    }
    setIsRegistrationLoading(false)
  }

  return (
    <>
      <LegacyMainContainer>
        <LegacySchindeln />
        <LegacyContentContainer height="1650px">
          <LegacyNavContainer>
            <Link to={ROUTES.home}>
              <LegacyNavItem>Cego</LegacyNavItem>
            </Link>
            <Link to={ROUTES.lobby}>
              <LegacyNavItem selected>spielen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.lernen}>
              <LegacyNavItem>lernen</LegacyNavItem>
            </Link>
            <Link to={ROUTES.wissen}>
              <LegacyNavItem>wissen</LegacyNavItem>
            </Link>
          </LegacyNavContainer>
          <LegacySiteContentContainer>
            <LegacyFullPaperContainer>
              <LegacyHalfPaperContainer>
                <ContentPaper
                  title="Registrieren"
                  children={
                    <>
                      <p style={{ marginTop: '10px' }}>
                        Um Cego-Online spielen zu können, musst du dir zuerst ein Benutzerkonto anlegen. Folge dazu dem
                        Formular unter <LegacyBoldInlineSection>Neues Benutzerkonto anlegen</LegacyBoldInlineSection>
                      </p>
                      <br />
                      <p>
                        Nachdem du dich registriert hast, wird eine Mail an die von dir angegebene E-Mail Adresse
                        gesendet. Mit dieser Mail kannst du dann dein Benutzerkonto aktivieren.
                      </p>
                      <br />
                    </>
                  }
                ></ContentPaper>
                <LegacyHalfPaperStrip>
                  <ContentPaper
                    title="Neues Benutzerkonto anlegen"
                    children={
                      <>
                        <>
                          <StyledRegisterContent>
                            {!isRegistrationLoading && !isRegistrationSuccessful && (
                              <>
                                <p>Hier kannst du dir einen komplett neuen Account erstellen.</p>

                                <StyledActivateForm onSubmit={handleRegisterSubmit}>
                                  <br />
                                  <input
                                    type="text"
                                    name="accountName"
                                    placeholder="Benutzername"
                                    onChange={handleRegistereChange}
                                    required
                                  ></input>
                                  <input
                                    type="text"
                                    name="mail"
                                    placeholder="E-Mail"
                                    onChange={handleRegistereChange}
                                    required
                                  ></input>
                                  <input
                                    type="password"
                                    name="password"
                                    placeholder="Passwort"
                                    onChange={handleRegistereChange}
                                    required
                                  ></input>
                                  <input
                                    type="password"
                                    name="passwordRepeat"
                                    placeholder="Passwort wiederholen"
                                    onChange={handleRegistereChange}
                                    required
                                  ></input>
                                  <StyledDsgvo>
                                    Ich akzeptiere die{' '}
                                    <a href={ROUTES.datenschutz} target="_blank" rel="noreferrer">
                                      Datenschutzbedingungen
                                    </a>{' '}
                                    <input type="checkbox" required />
                                  </StyledDsgvo>
                                  {registrationError && (
                                    <>
                                      <p style={{ color: 'red', fontWeight: 'bold' }}>{registrationError}</p>
                                      <br />
                                    </>
                                  )}
                                  <button className="button-wide" type="submit">
                                    Registrieren
                                  </button>
                                </StyledActivateForm>
                              </>
                            )}
                            {isRegistrationLoading && (
                              <>
                                <br />
                                <p>
                                  <i>Warten auf Server ...</i>
                                </p>
                              </>
                            )}
                            {!isRegistrationLoading && isRegistrationSuccessful && (
                              <>
                                <p style={{ color: 'green' }}>
                                  Eine E-Mail wurde an die von dir angegebene Adresse verschickt!
                                </p>
                                <br />
                                <p>
                                  Öffne nun dein E-Mail Postfach und klicke auf den Link um dein Benutzerkonto zu
                                  aktivieren. Schau auch mal im Spam-Ordner nach, solltest du keine Mail erhalten haben.
                                </p>
                              </>
                            )}
                          </StyledRegisterContent>
                        </>
                      </>
                    }
                  ></ContentPaper>
                </LegacyHalfPaperStrip>
              </LegacyHalfPaperContainer>
            </LegacyFullPaperContainer>
          </LegacySiteContentContainer>
          <LegacyFooterContainer>
            <Link to={ROUTES.impressum}>
              <LegacyFooterItem>Impressum</LegacyFooterItem>
            </Link>
          </LegacyFooterContainer>
        </LegacyContentContainer>
      </LegacyMainContainer>
    </>
  )
}
