import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledStaticPage = styled.div`
  background-color: tan;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - var(--header-footer-height));
  flex-direction: column;
`

export const StyledStaticHeader = styled.header`
  a {
    font-size: 2em;
    color: #ebe2c0;
    text-decoration: none;
  }
  background-color: rgb(84 72 56);
  padding: 10px;
`

export const StyledStaticWindow = styled.div`
  color: black;
  background-color: white;
  height: 30%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(static/media/pin.aa8c25c6ca2bd545b66b.png), url(static/media/pin.aa8c25c6ca2bd545b66b.png),
    url(static/media/paper_texture.91299819ee4228e3e91a.svg);
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
  background-size: 25px, 25px, auto;
  background-repeat: no-repeat;
  background-position: top 10px left 10px, top 10px right 10px, center;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    height: 50%;
  }
`

export const StyledStaticContent = styled.div`
  height: 100%;
  padding: 10px;
`
export const StyledStaticHeading = styled.div`
  height: 10%;
  font-size: 3em;
  text-align: center;
  color: #6b380a;
  margin-left: 10%;
  margin-right: 10%;
`

export const StyledStaticMessage = styled.div`
  font-size: 1.3em;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #8a5423;
  padding: 40px;
  p {
    font-size: 1em;
    color: #8a5423;
  }
`

export const StyledStaticLink = styled(Link)`
  font-size: 1em;
  color: #3131ff;
  text-decoration: none;
`
