import styled from 'styled-components'

export const StyledTablePlayerPicture = styled.i`
  position: absolute;
  width: 71%;
  height: 71%;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-color: black;
  background-image: url('${(props) => props.imgSrc}');
`
