import styled from 'styled-components'

export const StyledPointsInGame = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  height: 360px;
  font-size: small;
  padding: 3%;
  td {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
  }
`

export const StyledPointsTable = styled.table`
  width: 100%;
`

export const StyledPointsTableRow = styled.tr`
  text-align: center;
`

export const StyledPointsTableData = styled.td`
  padding-bottom: 4px;
`
