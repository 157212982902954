import { ModalVersioningContent } from '../Components/Modal/style'

export const getCurrentVersion = (versions) => versions[versions.length - 1].version
export const getBrowserVersion = () => localStorage.getItem('cego-version')
export const setBrowserVersion = (version) => localStorage.setItem('cego-version', version)

export const VersioningDialogContent = (props: {
  showModal: Boolean
  versions: any[]
  lastBrowserVersion: string
  showAll: Boolean
}) => {
  const reversedVersions = [...props.versions].reverse()

  const startIndex = props.versions.findIndex((version) => version.version === props.lastBrowserVersion) + 1
  const currentVersion = props.versions[props.versions.length - 1].version

  const getVersionDate = (version: any) => {
    const date = new Date(version.date)
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}`
  }

  let features: string[] = []
  let changes: string[] = []
  let fixes: string[] = []
  let dev: string[] = []
  for (let i = startIndex; i < props.versions.length; i++) {
    props.versions[i].features.forEach((item) => features.push(item))
    props.versions[i].changes.forEach((item) => changes.push(item))
    props.versions[i].fixes.forEach((item) => fixes.push(item))
    props.versions[i].development.forEach((item) => dev.push(item))
  }

  return (
    <>
      {props.showAll ? (
        <ModalVersioningContent>
          {reversedVersions.map((version) => {
            if (
              version.features?.length > 0 ||
              version.changes?.length > 0 ||
              version.fixes?.length > 0 ||
              version.dev?.length > 0
            ) {
              return (
                <>
                  <span style={{ color: 'black' }}>Version {version.version}</span>
                  <br />
                  <span style={{ fontSize: '1.4rem', fontWeight: 'normal' }}>{`${getVersionDate(version)} `}</span>
                  <br />
                  {version.features.length > 0 && (
                    <>
                      <p>Meilensteine</p>
                      <ul>
                        {version.features?.map((feature, idx) => {
                          return <li key={`feature-${idx}`}>{feature}</li>
                        })}
                      </ul>
                    </>
                  )}
                  {version.changes?.length > 0 && (
                    <>
                      <p>Änderungen</p>
                      <ul>
                        {version.changes.map((change, idx) => {
                          return <li key={`change-${idx}`}>{change}</li>
                        })}
                      </ul>
                    </>
                  )}
                  {version.fixes?.length > 0 && (
                    <>
                      <p>Behobene Fehler</p>
                      <ul>
                        {version.fixes.map((fix, idx) => {
                          return <li key={`fix-${idx}`}>{fix}</li>
                        })}
                      </ul>
                    </>
                  )}
                  <hr style={{ marginTop: '20px', marginBottom: '20px' }} />
                </>
              )
            }
            return <></>
          })}
        </ModalVersioningContent>
      ) : (
        <ModalVersioningContent>
          <span>Version {currentVersion}</span>
          <hr style={{ marginTop: '20px' }} />
          {features.length > 0 && (
            <>
              <p>Meilensteine</p>
              <ul>
                {features.map((feature, idx) => {
                  return <li key={`feature-${idx}`}>{feature}</li>
                })}
              </ul>
            </>
          )}
          {changes.length > 0 && (
            <>
              <p>Änderungen</p>
              <ul>
                {changes.map((change, idx) => {
                  return <li key={`change-${idx}`}>{change}</li>
                })}
              </ul>
            </>
          )}
          {fixes.length > 0 && (
            <>
              <p>Behobene Fehler</p>
              <ul>
                {fixes.map((fix, idx) => {
                  return <li key={`fix-${idx}`}>{fix}</li>
                })}
              </ul>
            </>
          )}
          {dev.length > 0 && (
            <>
              <p>Entwicklung</p>
              <ul>
                {dev.map((d, idx) => {
                  return <li key={`dev-${idx}`}>{d}</li>
                })}
              </ul>
            </>
          )}
        </ModalVersioningContent>
      )}
    </>
  )
}
