/* eslint-disable react-hooks/exhaustive-deps */

import {
  CegoClient,
  ChatData,
  MessageStatus,
  PlayerData,
  PlayerDataMap,
  PlayerSeatMap,
  TableData,
  TableDataMap,
} from '@hs-furtwangen/socket-lib'
import { useEffect, useState } from 'react'
import { useAuth } from '../../lib/AuthProvider'
import { ErrorReportDialogContent } from '../../lib/ErrorReport'
import { VersioningDialogContent } from '../../lib/Versioning'
import Chat from '../Chat'
import { ModalDialog } from '../Modal/ModalDialog'
import PlayerList from '../PlayerList'
import PlayerProfile from '../PlayerProfile'
import { Options } from '../Options/Options'
import Table from '../Table'
import TableList from '../TableList/TableList'
import lobbyBackground from '../../Assets/lobby_background.jpg'
import buttonError from '../../Assets/button_error.png'
import '../../CSS/Lobby.css'
import '../../CSS/Icons.css'
import { CreateTableForm, CreateTableFormRow, StyledLogo, StyledVersion } from './style'
import { STROAGE_KEYS } from '../../lib/AppConfig'

const isDesktopMediaQuery = window.matchMedia('(min-width: 1000px)')
const isPortraitMediaQuery = window.matchMedia('(orientation: portrait)')

export default function Lobby(props: { cegoClient: CegoClient; backendEndpoint: string }) {
  const [isDesktop, setIsDesktop] = useState(isDesktopMediaQuery.matches)
  const [isPortrait, setIsPortrait] = useState(isPortraitMediaQuery.matches)

  const [isInLobby, setIsInLobby] = useState(false)
  const [tables, setTables] = useState<TableDataMap>({})
  const [players, setPlayers] = useState<PlayerDataMap>({})

  const [currentTableId, setCurrentTableId] = useState<string>(null)
  const [gameStarted, setGameStarted] = useState(false)
  const [isHosting, setIsHosting] = useState(false)
  const [readyPlayerIds, setReadyPlayerIds] = useState<string[]>([])

  const [activeMobileComponentIndex, setActiveMobileComponentIndex] = useState(1)
  const [searchText, setSearchText] = useState('')

  const [versions, setVersions] = useState(null)
  const [lastBrowserVersion] = useState<string>(localStorage.getItem(STROAGE_KEYS.version))

  const [newTableName, setNewTableName] = useState<string>()
  const [newTablePassword, setNewTablePassowrd] = useState<string>('')
  const [joinTablePassword, setJoinTablePassword] = useState<string>('')

  const [tableIdToJoin, setTableIdToJoin] = useState<string>('')

  const [chatMessages, setChatMessages] = useState<ChatData[]>([])

  const [showVersionDialog, setShowVersionDialog] = useState<Boolean>()
  const [showFullVersionDialog, setShowFullVersionDialog] = useState<Boolean>()
  const [showErrorReportDialog, setShowErrorReportDialog] = useState<Boolean>(false)
  const [showTableClosedDialog, setShowTableClosedDialog] = useState<Boolean>(false)
  const [showKickedDialog, setShowKickedDialog] = useState<Boolean>(false)
  const [showAlertDialog, setShowAlertDialog] = useState<Boolean>(false)
  const [showCreateTableDialog, setShowCreateTableDialog] = useState<Boolean>(false)
  const [showJoinTableDialog, setShowJoinTableDialog] = useState<Boolean>(false)
  const [showErrorDialog, setShowErrorDialog] = useState<Boolean>(false)
  const [showGreetingDialog, setShowGreetingDialog] = useState<Boolean>(
    localStorage.getItem(STROAGE_KEYS.greet) === 'true',
  )
  const [alertText, setAlertText] = useState<string>('')

  const [error, setError] = useState<string>('')

  const { token, playerData, onLogout, onKick } = useAuth()

  const onWindowFocus = () => {
    // reconnect when browser tab was inactive
    if (playerData) {
      props.cegoClient.ping(playerData?.sessionId, (res) => {
        if (res.status !== MessageStatus.OK) {
          joinLobby()
        }
      })
    }
  }

  const onAvatarChanged = (newAvatar: string) => {
    props.cegoClient.playerChangeAvatar(playerData.id, newAvatar, () => {})
  }

  useEffect(() => {
    if (showGreetingDialog === false) {
      localStorage.removeItem(STROAGE_KEYS.greet)
    }
  }, [showGreetingDialog])

  useEffect(() => {
    if (playerData) {
      props.cegoClient.authToken = token
      setNewTableName(`${playerData?.name}'s Tisch`)
      joinLobby()
    } else {
      onLogout()
    }
  }, [playerData])

  useEffect(() => {
    fetch('versions.json').then((response) => {
      response.json().then((versions) => {
        setVersions(versions)
        const currentVersion = versions[versions.length - 1].version
        if (currentVersion !== lastBrowserVersion && lastBrowserVersion !== null) {
          setShowVersionDialog(true)
        }
        localStorage.setItem('cego-version', currentVersion)
      })
    })
  }, [lastBrowserVersion])

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus)

    isDesktopMediaQuery.onchange = (event) => setIsDesktop(event.matches)
    isPortraitMediaQuery.onchange = (event) => setIsPortrait(event.matches)

    props.cegoClient.onPlayerChangedAvatar = (playerId, fileName) => {
      setPlayers((players) => {
        players[playerId] = {
          id: players[playerId].id,
          name: players[playerId].name,
          mail: players[playerId].mail,
          isAi: players[playerId].isAi,
          sessionId: players[playerId].sessionId,
          replacementId: players[playerId].replacementId,
          iconPath: fileName,
          options: players[playerId].options,
          tableId: players[playerId].tableId,
        }
        return { ...players }
      })
    }

    props.cegoClient.onChatMessageReceived = (
      senderId: string,
      senderName: string,
      date: Date,
      message: string,
      tableId?: string,
    ) => {
      const newMessage = new ChatData(senderId, senderName, players[senderId].iconPath, date, message, tableId)
      setChatMessages([...chatMessages, newMessage])
    }

    // other players mail is irrelevant @ the moment
    props.cegoClient.onPlayerJoinedLobby = (playerId, playerName, playerIsAi, playerIconPath, replacementId) => {
      if (playerIsAi === false) console.log(`${playerName} joined the lobby`)
      setPlayers((players) => {
        players[playerId] = {
          id: playerId,
          name: playerName,
          mail: '',
          isAi: playerIsAi,
          iconPath: playerIconPath,
          sessionId: '',
          replacementId: replacementId,
          options: [],
          tableId: null,
        }
        return { ...players }
      })
    }

    props.cegoClient.onPlayerLeftLobby = (playerId) => {
      if (players[playerId]?.isAi === false) console.log(`Player #${playerId} left the lobby`)
      setPlayers((players) => {
        if (players[playerId]) delete players[playerId]
        return { ...players }
      })
    }

    props.cegoClient.onPlayerKicked = (playerId, sessionId, reason) => {
      if (playerData.sessionId === sessionId) {
        leaveLobby()
        onKick()
      }
    }

    props.cegoClient.onPlayerReceivedAlert = (message) => {
      setAlertText(message)
      setShowAlertDialog(message !== '')
    }

    props.cegoClient.onPlayerJoinedTable = (playerId, tableId, seatIndex) => {
      if (players[playerId]?.isAi === false) {
        console.log(`Player ${players[playerId].name} joined table ${tables[tableId].name}`)
      }
      setPlayers((players) => {
        let player: PlayerData = players[playerId]
        if (player) {
          player.tableId = tableId
        }
        return { ...players }
      })
      setTables((tables) => {
        let table: TableData = tables[tableId]
        if (table && !table.playerIds.includes(playerId)) {
          table.playerIds = [...table.playerIds, playerId]
          table.seatMap[playerId] = seatIndex
        }
        return { ...tables }
      })
    }

    props.cegoClient.onPlayerLeftTable = (playerId, tableId) => {
      setPlayers((players) => {
        let player: PlayerData = players[playerId]
        if (player) {
          player.tableId = null
        }
        return { ...players }
      })
      setTables((tables) => {
        let table: TableData = tables[tableId]
        if (table) {
          table.playerIds = table.playerIds.filter((id) => id !== playerId)
          table.seatMap[playerId] = undefined
        }
        return { ...tables }
      })
    }

    props.cegoClient.onTableCreated = (playerId, tableId, tableName, hasPassword) => {
      const seatMap: PlayerSeatMap = {}
      seatMap[playerId] = 0
      setPlayers((players) => {
        let player: PlayerData = players[playerId]
        if (player) {
          player.tableId = tableId
        }
        return { ...players }
      })
      setTables((tables) => {
        tables[tableId] = {
          id: tableId,
          hasPassword: hasPassword,
          name: tableName,
          creatorId: playerId,
          playerIds: [playerId],
          seatMap: seatMap,
          gameStarted: false,
        }
        return { ...tables }
      })

      console.log(`Player #${playerId} created new table: ${tableName}`)
    }

    props.cegoClient.onTableClosed = (tableId) => {
      if (tableId === currentTable?.id) {
        props.cegoClient.playerGetLobbyChat(playerData.id, (res) => {
          const chatData: ChatData[] = res?.data
          if (chatData) {
            setChatMessages(chatData)
          }
        })
      }
      setTables((tables) => {
        if (tableId === currentTable?.id && currentTable?.creatorId !== playerData.id) {
          setShowTableClosedDialog(true)
          setReadyPlayerIds([])
          setGameStarted(false)
          setCurrentTableId(null)
          setIsHosting(false)
        }
        if (tables[tableId]) delete tables[tableId]

        return { ...tables }
      })
    }

    props.cegoClient.onPlayerKickedFromTable = (playerId: string, tableId: string) => {
      setTables((tables) => {
        if (tableId === currentTable?.id && currentTable?.creatorId !== playerData.id) {
          setShowKickedDialog(true)
          const tables: Array<any> = JSON.parse(localStorage.getItem(STROAGE_KEYS.kickedTables))
          if (tables === null || (tables && tables.every((item) => item !== tableId))) {
            localStorage.setItem(STROAGE_KEYS.kickedTables, JSON.stringify([...(tables ?? []), tableId]))
          }
        }
        if (tables[tableId]) delete tables[tableId]
        return { ...tables }
      })
    }

    props.cegoClient.onGameStarted = (tableId) => {
      console.log(`Game on table #${tableId} started`)
      if (tableId === currentTableId) setGameStarted(true)
      setTables((tables) => {
        if (tables[tableId]) {
          tables[tableId].gameStarted = true
        }
        return { ...tables }
      })
    }

    props.cegoClient.onTableGameStarted = (tableId, challengerId, gameMode) => {
      setTables((tables) => {
        if (tables[tableId]) {
          tables[tableId].challengerId = challengerId
          tables[tableId].gameMode = gameMode
        }

        return { ...tables }
      })
    }

    props.cegoClient.onTableGameFinished = (tableId: string) => {
      console.log(`Game on table #${tableId} finished`)
      setTables((tables) => {
        if (tables[tableId]) {
          tables[tableId].gameStarted = false
          tables[tableId].challengerId = null
          tables[tableId].gameMode = null
        }
        return { ...tables }
      })
    }

    props.cegoClient.onPlayerIsReady = (playerId, isReady) => {
      console.log(`Player #${playerId} is ${isReady ? 'ready' : 'not ready'}`)
      setReadyPlayerIds((readyPlayerIds) => {
        return isReady ? [...readyPlayerIds, playerId] : readyPlayerIds.filter((id) => id !== playerId)
      })
    }

    return () => {
      isDesktopMediaQuery.onchange = undefined
      isPortraitMediaQuery.onchange = undefined
      props.cegoClient.onPlayerJoinedLobby = undefined
      props.cegoClient.onPlayerLeftLobby = undefined
      props.cegoClient.onPlayerJoinedTable = undefined
      props.cegoClient.onPlayerLeftTable = undefined
      props.cegoClient.onTableCreated = undefined
      props.cegoClient.onTableClosed = undefined
      props.cegoClient.onGameStarted = undefined
      props.cegoClient.onPlayerIsReady = undefined
      props.cegoClient.onChatMessageReceived = undefined
      window.removeEventListener('focus', onWindowFocus)
    }
  })

  function joinLobby() {
    if (props.cegoClient.isConnected() === false) {
      props.cegoClient.reconnect()
    }
    props.cegoClient.playerJoinLobby(
      playerData.name,
      playerData.mail,
      playerData.id,
      playerData.iconPath,
      playerData.sessionId,
      (response) => {
        if (response.status === MessageStatus.OK) {
          let responseData: { playerMap: PlayerDataMap; tableMap: TableDataMap; lobbyChat: ChatData[] } = response.data
          setChatMessages(responseData.lobbyChat)
          setPlayers(responseData.playerMap)
          setTables(responseData.tableMap)
          setIsInLobby(true)
        } else {
          onLogout()
        }
      },
    )
  }

  function leaveLobby() {
    props.cegoClient.playerLeaveLobby(playerData.id, () => {})
  }

  function createTable(tableName: string, password: string) {
    props.cegoClient.playerCreateTable(playerData.id, tableName, password, (response) => {
      if (response.status === MessageStatus.OK) {
        setIsHosting(true)
        setCurrentTableId(response.data.tableId)
      } else {
        onLogout()
      }
    })
  }

  function joinTable(tableId: string, password: string) {
    props.cegoClient.playerJoinTable(playerData.id, tableId, joinTablePassword, (response) => {
      if (response.status === MessageStatus.OK) {
        if (response.data.reason === 'pw') {
          setError('Das angegebene Passwort ist falsch!')
          setShowErrorDialog(true)
          return
        }
        let responseData: { tableId: string; readyPlayerIds: string[] } = response.data
        let table = tables[tableId]
        setReadyPlayerIds(responseData.readyPlayerIds)
        if (table) setGameStarted(table.gameStarted)
        setCurrentTableId(responseData.tableId)
        setIsHosting(false)
      } else {
        onLogout()
      }
    })
  }

  function leaveTable() {
    props.cegoClient.playerLeaveTable(playerData.id, currentTableId, (response) => {
      if (response.status === MessageStatus.OK) {
        setReadyPlayerIds([])
        setGameStarted(false)
        setCurrentTableId(null)
        setIsHosting(false)
      } else {
        onLogout()
      }
    })
  }

  let currentTable = tables[currentTableId]
  if (currentTable) {
    return (
      <Table
        key={currentTableId}
        cegoClient={props.cegoClient}
        ownPlayerId={playerData.id}
        lobbyPlayers={players}
        currentTable={currentTable}
        gameStarted={gameStarted}
        chatMessages={chatMessages}
        setGameStarted={setGameStarted}
        readyPlayerIds={readyPlayerIds}
        setReadyPlayerIds={setReadyPlayerIds}
        leaveTable={leaveTable}
        isHosting={isHosting}
        showAlert={showAlertDialog}
        alertText={alertText}
        setShowAlert={setShowAlertDialog}
      ></Table>
    )
  }

  let components = isInLobby
    ? [
        <PlayerProfile key={0} player={players[playerData.id]} onAvatarChanged={onAvatarChanged}></PlayerProfile>,
        <TableList
          key={1}
          tables={tables}
          players={players}
          playerId={playerData.id}
          isPortrait={isPortrait}
          startJoiningTable={(tableId) => {
            if (tables[tableId]?.hasPassword) {
              setTableIdToJoin(tableId)
              setShowJoinTableDialog(true)
            } else {
              joinTable(tableId, '')
            }
          }}
          setShowCreateTableModal={setShowCreateTableDialog}
          searchText={searchText}
          cegoClient={props.cegoClient}
        ></TableList>,
        <PlayerList key={2} players={players} tables={tables}></PlayerList>,
        <Options key={3} cegoClient={props.cegoClient} playerId={playerData.id} options={playerData.options}></Options>,
        <Chat
          key={4}
          cegoClient={props.cegoClient}
          playerId={playerData.id}
          playerName={players[playerData.id].name}
          chatMessages={chatMessages}
          displayHeading={true}
          lobbyPlayers={players}
          messageCache=""
          setMessageCache={() => {}}
        ></Chat>,
      ]
    : []

  return (
    <>
      {isInLobby && (
        <div className="lobby">
          <header className="header">
            <div>
              <StyledLogo>
                CEGO
                <StyledVersion onClick={() => setShowFullVersionDialog(true)}>
                  {lastBrowserVersion} ( Beta )
                </StyledVersion>
              </StyledLogo>
            </div>
            {isPortrait ? (
              <div></div>
            ) : (
              <input
                className="search"
                placeholder="Tisch oder Spieler suchen..."
                type="search"
                name=""
                id=""
                onChange={(event) => setSearchText(event.target.value)}
              />
            )}
            <div>
              {isPortrait ? (
                <button>
                  <i className="icon icon-search"></i>
                </button>
              ) : null}
              <button>
                <i className="icon icon-settings" style={{ display: 'none' }}></i>
              </button>
              <button
                title="Fehler melden"
                onClick={() => {
                  setShowErrorReportDialog(true)
                }}
              >
                <i className="icon-img icon-error"></i>
              </button>
              <button
                title="Verlassen"
                onClick={() => {
                  onLogout()
                  leaveLobby()
                }}
              >
                <i className="icon-img button-icon-leave"></i>
              </button>
              <button>
                <i className="icon icon-notification" style={{ display: 'none' }}></i>
              </button>
              {isDesktop ? (
                <></>
              ) : (
                <button onClick={() => setActiveMobileComponentIndex(0)}>
                  <i className="icon icon-profile"></i>
                </button>
              )}
            </div>
          </header>
          <main className="content" style={{ backgroundImage: `url(${lobbyBackground})` }}>
            {isDesktop ? components : components[activeMobileComponentIndex]}
          </main>
          <ModalDialog
            hasCancelButton={false}
            showModal={showVersionDialog}
            setShowModal={setShowVersionDialog}
            header={<>Neue Cego-Online Version!</>}
          >
            <VersioningDialogContent
              showModal={showVersionDialog}
              versions={versions}
              lastBrowserVersion={lastBrowserVersion}
              showAll={false}
            />
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showFullVersionDialog}
            setShowModal={setShowFullVersionDialog}
            header={<>Änderungshistorie</>}
          >
            <VersioningDialogContent
              showModal={showFullVersionDialog}
              versions={versions}
              lastBrowserVersion={lastBrowserVersion}
              showAll={true}
            />
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showErrorReportDialog}
            setShowModal={setShowErrorReportDialog}
            header={<>Fehler melden</>}
          >
            <ErrorReportDialogContent cegoClient={props.cegoClient} playerId={playerData.id} />
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showAlertDialog}
            setShowModal={setShowAlertDialog}
            header={<>Benachrichtigung</>}
          >
            <p>{alertText}</p>
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showTableClosedDialog}
            setShowModal={setShowTableClosedDialog}
            header={<>Tisch geschlossen</>}
          >
            <p>Der Ersteller hat den Tisch verlassen und damit die Partie beendet.</p>
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showKickedDialog}
            setShowModal={setShowKickedDialog}
            header={<>Tisch beendet</>}
          >
            <p>Du wurdest vom Tisch entfernt und kannst nicht mehr beitreten.</p>
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showCreateTableDialog}
            setShowModal={setShowCreateTableDialog}
            header={<>Tisch erstellen</>}
          >
            <CreateTableForm>
              <CreateTableFormRow>
                <p>Tischname: </p>
                <input
                  maxLength={30}
                  style={{ width: '100%', height: '30px' }}
                  placeholder={`${playerData?.name}'s Tisch`}
                  onChange={(e) => setNewTableName(e.target.value)}
                  value={newTableName}
                ></input>
              </CreateTableFormRow>
              <CreateTableFormRow>
                <p>Passwort: </p>
                <input
                  type="password"
                  placeholder="kein Passwort"
                  style={{ width: '100%', height: '30px' }}
                  value={newTablePassword}
                  onChange={(e) => setNewTablePassowrd(e.target.value)}
                />
              </CreateTableFormRow>
              <CreateTableFormRow style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className="button-wide"
                  style={{ width: '100%' }}
                  onClick={() => {
                    setShowCreateTableDialog(false)
                    createTable(newTableName, newTablePassword)
                    setNewTablePassowrd('')
                  }}
                >
                  Tisch erstellen
                </button>
              </CreateTableFormRow>
            </CreateTableForm>
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showErrorDialog}
            setShowModal={setShowErrorDialog}
            header={<>Fehler</>}
          >
            <p>{error}</p>
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showJoinTableDialog}
            setShowModal={setShowJoinTableDialog}
            header={<>Tisch beitreten</>}
          >
            <CreateTableForm>
              <CreateTableFormRow>
                <p>Passwort: </p>
                <input
                  type="password"
                  style={{ width: '100%', height: '30px' }}
                  value={joinTablePassword}
                  onChange={(e) => setJoinTablePassword(e.target.value)}
                />
              </CreateTableFormRow>
              <CreateTableFormRow style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className="button-wide"
                  style={{ width: '100%' }}
                  onClick={() => {
                    setShowJoinTableDialog(false)
                    joinTable(tableIdToJoin, joinTablePassword)
                    setJoinTablePassword('')
                  }}
                >
                  Beitreten
                </button>
              </CreateTableFormRow>
            </CreateTableForm>
          </ModalDialog>
          <ModalDialog
            hasCancelButton={false}
            showModal={showGreetingDialog}
            setShowModal={setShowGreetingDialog}
            header={<>Cego BETA</>}
          >
            <p>Herzlich Willkommen zu der offiziellen Testphase von Cego-Online!</p>
            <br />
            <p>
              Bevor es mit Cego-Online richtig los geht, wird in dieser Phase des Projektes das Spiel nochmal auf Herz
              und Nieren überprüft.{' '}
            </p>
            <br />
            <p>
              Daher kann es sein, dass noch einige Fehler und Stolpersteine auftreten können, die wir gerne mit eurer
              Hilfe beseitigen möchten.
            </p>
            <br />
            <p>
              Mit dem <img src={buttonError} alt="Fehler melden" title="Fehler melden" width="20px" /> Button, kannst du
              uns gefundene Fehler melden. Diese werden gesammelt, damit wir sie nach und nach beheben können.
            </p>
            <br />
            <p>Vielen Dank für deine Teilnahme und Mithilfe, wir wünschen dir nun viel Spaß mit Cego-Online!</p>
          </ModalDialog>
          {isDesktop ? (
            <></>
          ) : (
            <nav className="navbar">
              <button onClick={() => setActiveMobileComponentIndex(3)}>
                <i className="icon icon-ranking"></i>
              </button>
              <button onClick={() => setActiveMobileComponentIndex(2)}>
                <i className="icon icon-players"></i>
              </button>
              <button onClick={() => setActiveMobileComponentIndex(1)}>
                <i className="icon icon-table"></i>
              </button>
              <button onClick={() => setActiveMobileComponentIndex(4)}>
                <i className="icon icon-chat"></i>
              </button>
            </nav>
          )}
        </div>
      )}
      {!isInLobby && <p>Loading...</p>}
    </>
  )
}
