import styled from 'styled-components'

export const StyledLog = styled.div`
  height: 360px;
  overflow-y: scroll;
`

export const StyledLogEntry = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(179, 179, 179);
`
export const StyledLogHeader = styled.div`
  font-size: 25px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: baseline;
`
export const StyledLogHeaderGameMode = styled.div`
  font-size: 25px;
  margin-left: 10px;
  color: red;
`
