import { PlayerData } from '@hs-furtwangen/socket-lib'
import React from 'react'
import '../CSS/PlayerProfile.css'
import { getBackendEndpoint } from '../lib/AppConfig'
import { ModalDialog } from './Modal/ModalDialog'

export default function PlayerProfile(props: { player: PlayerData; onAvatarChanged: (iconPath: string) => void }) {
  let player = props.player
  const [showImageSizeError, setShowImageSizeError] = React.useState<Boolean>(false)

  const requestOptions = (file: any) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('userName', player.name)
    console.log(file)
    return {
      method: 'POST',
      body: formData,
    }
  }

  const uploadAvatar = async (file) => {
    console.log('upload avatar')
    console.log(file)
    const response = await fetch(`${getBackendEndpoint()}/uploadAvatar`, requestOptions(file))
    if (response.ok) {
      const data = await response.json()
      console.log(data.fileName)
      props.onAvatarChanged(data.fileName)
    } else {
      setShowImageSizeError(true)
    }
  }

  return (
    <div className="player-profile">
      <ModalDialog
        hasCancelButton={false}
        showModal={showImageSizeError}
        setShowModal={setShowImageSizeError}
        header={<>Fehler</>}
      >
        <p style={{ fontFamily: 'Arial' }}>
          Das ausgewählte Bild überschreitet die maximal zulässige Grösse von 1MB. Bitte wählen Sie ein kleineres Bild.
        </p>
      </ModalDialog>
      <h2 className="component-heading">Profil</h2>
      <div className="player-profile-content">
        <div>
          <img className="player-profile-avatar" src={props.player.iconPath} alt="Avatar" />
          <label htmlFor="file-upload" className="avatar-file-button" title="Profilbild ändern"></label>
          <input
            id="file-upload"
            className="avatar-file-input"
            type="file"
            accept="file_extension|image/png,image/jpeg,image/gif"
            onChange={(arg) => uploadAvatar(arg.target.files[0])}
          />
        </div>

        <h3 className="player-profile-name">{player ? `${player.name}` : null}</h3>

        <div className="player-profile-level" style={{ display: 'none' }}>
          <div className="player-profile-level-badge">
            <h2>5</h2>
          </div>
          <h4>Level</h4>
          <div className="player-profile-levelbar"></div>
          <div className="player-profile-levelbar-level">{player ? ` ${player.id}` : null} / 600</div>
        </div>
      </div>
    </div>
  )
}
