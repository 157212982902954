import styled from 'styled-components'

export const StyledLogin = styled.div`
  height: 100%;
  width: 100%;
  background-color: tan;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const StyledLoginContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: 30px;
  }
  @media (min-width: 1400px) {
    width: 20%;
    margin-left: 40%;
    margin-top: 5%;
    padding: 10px;
  }
`

export const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`

export const StyledRegisterHint = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  a {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    color: #3131ff;
  }
`

export const StyledLoginProblemHint = styled.p`
  margin-top: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  a {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    font-size: small;
    text-decoration: none;
    color: #3131ff;
  }
`

export const StyledLoginLabel = styled.label`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  text-align: center;
`

export const StyledLoginError = styled.p`
  color: red;
  font-weight: bold;
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
`

export const StyledPasswordReset = styled.p`
  a {
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-decoration: none;
    color: #3131ff;
    font-size: small;
  }
  cursor: pointer;
`
